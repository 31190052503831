// eslint-disable-next-line import/extensions
import { constantsModule } from './constants';

constantsModule.constant('timeZones', {
    // For mapping between the Treasury time zones and moment.js time zones
    'Eastern Standard Time': 'America/New_York',
    'US Eastern Standard Time': 'America/Indiana/Indianapolis',
    'Central Standard Time': 'America/Chicago',
    'Mountain Standard Time': 'America/Denver',
    'US Mountain Standard Time': 'America/Phoenix',
    'Alaskan Standard Time': 'America/Anchorage',
    'Hawaiian Standard Time': 'Pacific/Honolulu',
    'Atlantic Standard Time': 'Atlantic/Bermuda',
    'Pacific Standard Time': 'America/Los_Angeles',
});