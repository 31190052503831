import { AlarmClock } from '@treasury/alarm-clock';
import compareDates from '@treasury/omega/helpers/compare-dates.js';

WireListController.$inject = [
    '$scope',
    '$state',
    '$uibModal',
    'searchFilterService',
    'entitlementsService',
    'companyAccountsService',
    'currenciesService',
    'wireService',
    'toaster',
    'downloadPageId',
    'securityService',
    '$filter',
    'wireCompaniesService',
    '$q',
    'dashboardService',
    'wireStatusTypes',
    'wireIsoService',
];

export default function WireListController(
    $scope,
    $state,
    $uibModal,
    searchFilterService,
    entitlementsService,
    companyAccountsService,
    currenciesService,
    wireService,
    toaster,
    downloadPageId,
    securityService,
    $filter,
    wireCompaniesService,
    $q,
    dashboardService,
    wireStatusTypes,
    wireIsoService
) {
    $scope.downloadPageId = downloadPageId.WireList;
    $scope.allItemsAreSelected = {};
    $scope.wireCompanies = [];
    $scope.companyAccounts = [];
    $scope.currencies = null;
    $scope.dateOptions = null;
    $scope.filterObject = {};
    $scope.wireAmountOptions = null;
    $scope.wires = null;
    $scope.isWireSelected = false;
    $scope.isWireProductActive = false;

    $scope.batchUpdate = batchUpdate;
    $scope.createWire = createWire;
    $scope.getFrequencySummary = getFrequencySummary;
    $scope.frequencyTypeToFriendly = frequencyTypeToFriendly;
    $scope.goToRecurringWireDetail = goToRecurringWireDetail;
    $scope.goToWireDetail = goToWireDetail;
    $scope.goToWireFile = goToWireFile;
    $scope.hasAccessPermissions = hasAccessPermissions;
    $scope.hasPermissionToCreate = hasPermissionToCreate;
    $scope.isWireSftpEnabled = isWireSftpEnabled;
    $scope.print = print;
    $scope.remove = remove;
    $scope.resetSearch = resetSearch;
    $scope.search = search;
    $scope.selectOrDeselectAllWires = selectOrDeselectAllWires;
    $scope.wireSelected = wireSelected;
    $scope.canSelectWire = canSelectWire;

    $scope.filteredWires = {};
    $scope.filterSearch = filterSearch;
    $scope.disableCreateNewWire = disableCreateNewWire;
    $scope.disableFilter = disableFilter;
    $scope.searchObj = { text: null };
    $scope.goToRecurringWireList = goToRecurringWireList;
    $scope.goToWireFileActivity = goToWireFileActivity;
    $scope.canCancel = canCancel;
    $scope.resetEffectiveDates = resetEffectiveDates;
    $scope.resetCreatedDates = resetCreatedDates;
    $scope.statusOptions = wireStatusTypes.filter(s => s.canSearch);
    $scope.disableDownload = false;
    $scope.wireIsoLabels = wireIsoService.legacyLabels;
    wireIsoService.getLabels().then(labels => {
        $scope.wireIsoLabels = labels;
    });

    function disableCreateNewWire() {
        return !$scope.isWireProductActive || !hasPermissionToCreate();
    }

    function canCancel(wire) {
        return (
            isStatusCancelable(wire.status) &&
            hasAccessPermissions(wire, 'Delete') &&
            wire.destinationCurrency === 'USD' &&
            wire.isDliWire !== true
        );
    }

    function isStatusCancelable(status) {
        return $scope.statusOptions.some(
            statusOption => statusOption.canCancel && statusOption.name === status
        );
    }

    function setApprovalAttributes() {
        $scope.wires.map(wire => {
            wire.approvalAttributes = {
                approvalEntity: 'wire',
                approvalCount: wire.completedApprovalCount,
                amount: wire.amount,
                createdBy: wire.createdBy,
                updatedBy: wire.updatedBy,
                productId: wire.id,
            };
            return wire;
        });
    }

    function filterSearch(searchText) {
        $scope.searchObj.text = searchText || '';
        $scope.filteredWires = $filter('filter')($scope.wires, filterRows);
    }

    function filterRows(row) {
        if (!$scope.searchObj.text || $scope.searchObj.text.trim() === '') {
            return true;
        }

        const searchText = $scope.searchObj.text.toLowerCase();

        return (
            (!!row.transactionId && row.transactionId.toLowerCase().indexOf(searchText) !== -1) ||
            (!!row.confirmationNumber &&
                row.confirmationNumber.toLowerCase().indexOf(searchText) !== -1) ||
            (!!row.debitAccount &&
                !!row.debitAccount.number &&
                row.debitAccount.number.toLowerCase().indexOf(searchText) !== -1) ||
            (!!row.beneficiary &&
                !!row.beneficiary.name &&
                row.beneficiary.name.toLowerCase().indexOf(searchText) !== -1) ||
            (!!row.wireCompany &&
                !!row.wireCompany.name &&
                row.wireCompany.name.toLowerCase().indexOf(searchText) !== -1) ||
            (!!row.destinationCurrency &&
                row.destinationCurrency.toLowerCase().indexOf(searchText) !== -1) ||
            (!!row.createdDate &&
                $filter('date')(row.createdDate, 'MM/dd/yyyy').indexOf(searchText) !== -1) ||
            (!!row.frequency &&
                !!row.frequency.valueDate &&
                $filter('date')(row.frequency.valueDate, 'MM/dd/yyyy').indexOf(searchText) !==
                    -1) ||
            (!!row.amount && $filter('currency')(row.amount, '$').indexOf(searchText) !== -1) ||
            (!!row.creditAmount &&
                $filter('currency')(row.creditAmount, '$').indexOf(searchText) !== -1) ||
            (!!row.status && row.status.toLowerCase().indexOf(searchText) !== -1) ||
            (!!row.parentFrequency &&
                $scope
                    .frequencyTypeToFriendly(row.parentFrequency.type)
                    .toLowerCase()
                    .indexOf(searchText) !== -1) ||
            (row.isInternational && searchText === 'international') ||
            (!row.isInternational && searchText === 'domestic')
        );
    }

    function disableFilter() {
        return $scope.wires ? $scope.wires.length <= 0 : true;
    }

    function goToRecurringWireList() {
        $state.go('payables.wire.recurring-wire-list');
    }

    function goToWireFileActivity() {
        $state.go('payables.wire.wire-file-activity');
    }

    function init() {
        $scope.dateOptions = ['Specific Date', 'Date Range'];
        $scope.wireAmountOptions = ['Specific Amount', 'Amount Range'];
        $scope.currentDate = moment().format('MM/DD/YYYY');
        $scope.wireFileName = $scope.wireFileName ?? window.history.state?.fileName;
        $scope.wireFileId = $scope.wireFileId ?? window.history.state?.fileId;

        resetSearch();
        searchFilterService.restoreFilter($scope.filterObject);

        $q.all([
            loadWireCompanies(),
            loadCompanyAccounts(),
            loadCurrencies(),
            loadProductStatus(),
        ]).then(() => {
            search(true);
        });
    }

    function resetSearch() {
        $scope.filterObject = { wireType: 'Both' };
        $scope.filterObject.wireCompanies = [];
        $scope.filterObject.statuses = [];
        $scope.filterObject.debitAccounts = [];
        $scope.filterObject.wireFileId = $scope.wireFileId;

        angular.forEach($scope.wireCompanies, wireCompany => {
            wireCompany.isChecked = true;
            $scope.filterObject.wireCompanies.push(wireCompany);
        });
        angular.forEach($scope.statusOptions, statusOption => {
            statusOption.isChecked = true;
            $scope.filterObject.statuses.push(statusOption);
        });
        angular.forEach($scope.companyAccounts, companyAccount => {
            companyAccount.isChecked = true;
            $scope.filterObject.debitAccounts.push(companyAccount);
        });

        $scope.filterObject.wireAmountType = $scope.wireAmountOptions[0];
        $scope.filterObject.createdDateType = $scope.dateOptions[0];
        $scope.filterObject.valueDateType = $scope.dateOptions[1];
        $scope.filterObject.valueDateStart = moment().format('MM/DD/YYYY');
        $scope.filterObject.valueDateEnd = moment().add('d', 7).format('MM/DD/YYYY');

        // Ignore dates and show all wires for the file
        if ($scope.filterObject.wireFileId) {
            $scope.resetCreatedDates();
            $scope.resetEffectiveDates();
        }
    }

    function search(initialLoad) {
        if (initialLoad) {
            searchFilterService.restoreFilter($scope.filterObject);
        } else {
            searchFilterService.saveFilter($scope.filterObject, ['payables/wire/wire-details']);
        }
        wireService.search($scope.filterObject, 'One Time').then(response => {
            if (response.length === 0) {
                $scope.sidebarContainerController.setCollapsed(false);
            } else {
                $scope.sidebarContainerController.setCollapsed(true);
            }
            $scope.wires = response;
            setApprovalAttributes();
            $scope.searchObj.text = null;
            $scope.filterSearch($scope.searchObj.text);
            $scope.allItemsAreSelected.value = false;
            $scope.isWireSelected = false;
            if ($scope.wires.length === 0) {
                $scope.disableDownload = true;
            } else {
                $scope.disableDownload = false;
            }
        });
    }

    function goToWireDetail(wire) {
        $state.go('payables.wire.wire-details', { id: wire.id, type: 'wireList' });
    }

    function goToWireFile(wire) {
        const wireFileName = wire ? wire.fileName : $scope.wireFileName;
        $state.go('payables.wire.wire-file-activity', {
            wireFileName,
        });
    }

    function goToRecurringWireDetail(wire) {
        $state.go('payables.wire.wire-details', { id: wire.id, type: 'recurringWireList' });
    }

    function createWire() {
        $state.go('payables.wire.create-wire');
    }

    function getFrequencySummary(frequency) {
        return wireService.summarizeFrequency(frequency);
    }

    function frequencyTypeToFriendly(frequencyType) {
        return wireService.frequencyTypeToFriendly(frequencyType);
    }

    function hasEntitlement(entitlement) {
        return entitlementsService.hasEntitlement(entitlement);
    }

    function hasPermissionToCreate() {
        const domestic = entitlementsService.hasEntitlement('Wire, Domestic, Create Wire');
        const international = entitlementsService.hasEntitlement(
            'Wire, International, Create Wire'
        );

        return domestic || international;
    }

    function isWireSftpEnabled() {
        return hasEntitlement('Feature.Wire.SFTP.DataTransmissions');
    }

    function print() {
        $('#wireTable').printThis();
    }

    function hasAccessPermissions(wire, permissionType) {
        let result = false;
        angular.forEach(wire.permissions, permission => {
            if (permission.permission === permissionType) {
                result = true;
            }
        });
        return result;
    }

    function selectOrDeselectAllWires() {
        angular.forEach($scope.wires, wire => {
            if (canSelectWire(wire)) {
                wire.isSelected = $scope.allItemsAreSelected.value;
                $scope.isWireSelected = $scope.allItemsAreSelected.value;
            }
        });
    }

    function wireSelected() {
        let allSelected = true;

        angular.forEach($scope.wires, wire => {
            if (canSelectWire(wire)) {
                if (!wire.isSelected) {
                    allSelected = false;
                }
            }
        });

        const wiresSelected = $filter('filter')($scope.wires, wire => wire.isSelected === true);
        $scope.isWireSelected = wiresSelected.length > 0;

        if (allSelected) {
            $scope.allItemsAreSelected.value = true;
        } else {
            $scope.allItemsAreSelected.value = false;
        }
    }

    function canSelectWire(wire) {
        const effectiveDate = new Date(wire.frequency.nextPaymentDate);
        const today = new Date();
        const comparedDates = compareDates(effectiveDate, today);
        if (comparedDates === -1) {
            return false;
        }
        if (comparedDates === 0 && AlarmClock.getInstance().isAfter('wireCutoff')) {
            return false;
        }
        return (
            wire.status === 'Pending Approval' &&
            !wire.isDli &&
            hasAccessPermissions(wire, 'Approve')
        );
    }

    function batchUpdate(type) {
        let batchPayload = {
            lookups: [],
        };
        angular.forEach($scope.wires, wire => {
            if (
                wire.isSelected !== null &&
                wire.isSelected !== undefined &&
                wire.isSelected === true
            ) {
                batchPayload.lookups.push({ key: wire.id });
            }
        });
        const modalInstance = $uibModal.open({
            template: require('../views/changeStatusDialogView.html'),
            size: 'md',
            controller: 'ChangeWireStatusDialogController',
            backdrop: 'static',
            resolve: {
                type() {
                    return type;
                },
                paymentType() {
                    return 'Wire';
                },
            },
        });
        modalInstance.result.then(comments => {
            batchPayload = {
                lookups: [],
            };
            angular.forEach($scope.wires, wire => {
                if (
                    wire.isSelected !== null &&
                    wire.isSelected !== undefined &&
                    wire.isSelected === true
                ) {
                    batchPayload.lookups.push({ key: wire.id, value: comments });
                }
            });
            let actionType;
            if (type === 'Approve') {
                actionType = 'Approve WIRE Payment';
            } else if (type === 'Reject') {
                actionType = 'Reject WIRE Payment';
            }

            securityService
                .verifyUser(actionType, batchPayload, () =>
                    wireService.batchApproveOrReject(batchPayload, type)
                )
                .then(
                    response => {
                        if (updateWires(response)) {
                            if (type === 'Approve') {
                                toaster.approve('Wire');
                            } else if (type === 'Reject') {
                                toaster.reject('Wire');
                            }
                        }
                    },
                    () => {
                        search();
                    }
                );
        });
    }

    function updateWires(response) {
        let noErrors = true;
        if (!!response && !!response.wires && response.wires.length > 0) {
            $scope.wires = $scope.wires.map(wire => {
                const updatedWire = response.wires.filter(item => {
                    if (item.errorSummary) {
                        noErrors = false;
                    }
                    if (item.id === wire.id) {
                        // This is needed in case the updated wires is displayed in a sorted table directive
                        // and one needs to maintain the same order after the update
                        if (wire.sequence) {
                            item.sequence = wire.sequence;
                        }
                        return item;
                    }
                });
                return !!updatedWire && updatedWire.length === 1 ? updatedWire[0] : wire;
            });
            setApprovalAttributes();
            // Redo the filter
            filterSearch($scope.searchObj.text);
            return noErrors;
        }
    }

    function remove(wire) {
        const modalInstance = $uibModal.open({
            template: require('../views/removeWireDialogView.html'),
            size: 'lg',
            controller: 'RemoveWireController',
            backdrop: 'static',
            resolve: {
                type() {
                    return 'wireList';
                },
            },
        });

        modalInstance.result.then(comments => {
            const cancelWireModel = {
                wireId: wire.id,
                comments,
            };
            securityService
                .verifyUser('Delete Wire Payment', cancelWireModel, () =>
                    wireService.remove(cancelWireModel)
                )
                .then(response => {
                    search();
                });
        });
    }

    // private

    function loadWireCompanies() {
        return wireCompaniesService.getAll().then(response => {
            let wireCompanies = response;

            wireCompanies.forEach(wireCompany => {
                wireCompany.isChecked = true;

                if (wireCompany.isDliWireCompany) {
                    wireCompany.name += ' - International';
                }
            });

            wireCompanies = $filter('orderBy')(wireCompanies, 'name');

            $scope.wireCompanies = wireCompanies;
            $scope.filterObject.wireCompanies = wireCompanies;
        });
    }

    function loadCompanyAccounts() {
        return companyAccountsService
            .getCompanyAccountsWithProductFeature('', 'WireTransfer')
            .then(response => {
                if (
                    response.length > 0 &&
                    response[0] !== null &&
                    typeof response[0] !== 'undefined'
                ) {
                    $scope.accountDisplayField = response[0].showAccountNickName
                        ? 'name'
                        : 'number';
                }
                $scope.companyAccounts = response;
                $scope.filterObject.debitAccounts = [];
                angular.forEach($scope.companyAccounts, companyAccount => {
                    companyAccount.isChecked = true;
                    $scope.filterObject.debitAccounts.push(companyAccount);
                });
            });
    }

    function loadCurrencies() {
        return currenciesService.getAll().then(response => {
            $scope.currencies = response;
        });
    }

    function resetEffectiveDates() {
        $scope.filterObject.valueDate = null;
        $scope.filterObject.valueDateStart = null;
        $scope.filterObject.valueDateEnd = null;
    }

    function resetCreatedDates() {
        $scope.filterObject.createdDateStart = null;
        $scope.filterObject.createdDateEnd = null;
        $scope.filterObject.createdDate = null;
    }

    function loadProductStatus() {
        dashboardService.getProductStatus().then(response => {
            const wireProduct = $filter('filter')(response, {
                type: 'WireTransferProductFeature',
            })[0];
            $scope.isWireProductActive = wireProduct && wireProduct.status === 'Active';
        });
    }

    init();
}
