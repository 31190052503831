PaymentsPendingApprovalWidgetController.$inject = [
    '$scope',
    'toaster',
    'dashboardService',
    'internalTransfersService',
    'paymentPendingApprovalProductsService',
    'modalService',
    '$state',
    'spinnerService',
    'securityService',
    'achPaymentsService',
    'wireIsoService',
];

// eslint-disable-next-line @treasury/filename-match-export
export default function PaymentsPendingApprovalWidgetController(
    $scope,
    toaster,
    dashboardService,
    internalTransfersService,
    paymentPendingApprovalProductsService,
    modalService,
    $state,
    spinnerService,
    securityService,
    achPaymentsService,
    wireIsoService
) {
    $scope.confirm = confirm;
    $scope.entitlement = '';
    $scope.cancel = cancel;
    $scope.reload = reload;
    $scope.goToWireList = goToWireList;
    $scope.reset = reset;
    $scope.goToPaymentDetail = goToPaymentDetail;
    $scope.review = review;
    $scope.goToTransfer = goToTransfer;
    $scope.goToLoanPayment = goToLoanPayment;
    $scope.loadCompletionCallback = null;
    $scope.selectOrDeselectTransfer = selectOrDeselectTransfer;
    $scope.selectOrDeselectLoanPayment = selectOrDeselectLoanPayment;
    $scope.selectOrDeselectWire = selectOrDeselectWire;
    $scope.selectOrDeselectAch = selectOrDeselectAch;
    $scope.selectTransfer = selectTransfer;
    $scope.selectLoanPayment = selectLoanPayment;
    $scope.selectWire = selectWire;
    $scope.selectAch = selectAch;
    $scope.selectAll = {
        transfer: false,
        loanpayment: false,
        wire: false,
        ach: false,
    };
    $scope.wireIsoLabels = wireIsoService.legacyLabels;

    init();

    function init() {
        spinnerService.configureGroup(
            'paymentsPendingApprovalWidget',
            [
                '/dashboard/paymentPendingApproval',
                '/dashboard/batch',
                '/internalTransfers',
                '/dashboard/WidgetProducts',
            ],
            [
                'paymentsPendingApprovalWidget.html',
                'pendingPaymentsFooter.html',
                'paymentPendingApprovalOptions.html',
            ]
        );

        $scope.widget.leftControlsTemplate =
            'app/dashboard/widgets/views/paymentPendingApprovalOptions.html';
        $scope.widget.footerTemplate = 'app/dashboard/widgets/views/pendingPaymentsFooter.html';

        $scope.widget.leftControlsController = {
            loadAllProducts,
            loadProduct,
            isTabSelected,
        };

        $scope.widget.footerController = {
            getForm,
            isReviewing,
            isOnConfirmation,
            review,
            reset,
            reload,
            confirm,
            cancel,
        };

        wireIsoService.getLabels().then(labels => {
            $scope.wireIsoLabels = labels;
        });
    }

    function setApprovalAttributesTransfer() {
        $scope.pendingApprovalTransferList.map(pmt => {
            pmt.approvalAttributes = {
                approvalEntity: 'internalTransfer',
                approvalCount: pmt.completedApprovalCount,
                amount: pmt.amount,
                createdBy: pmt.createdBy,
                updatedBy: pmt.updatedBy,
                productId: pmt.id,
            };
            return pmt;
        });
    }

    function setApprovalAttributesLoanPayment() {
        $scope.pendingApprovalLoanPaymentList.forEach(pmt => {
            pmt.approvalAttributes = {
                approvalEntity: 'loanpayment',
                approvalCount: pmt.completedApprovalCount,
                amount: pmt.amount,
                createdBy: pmt.createdBy,
                updatedBy: pmt.updatedBy,
                productId: pmt.id,
            };
            return pmt;
        });
    }

    function setApprovalAttributesWire() {
        $scope.pendingApprovalWireList.map(pmt => {
            pmt.approvalAttributes = {
                approvalEntity: 'wire',
                approvalCount: pmt.completedApprovalCount,
                amount: pmt.amount,
                createdBy: pmt.createdBy,
                updatedBy: pmt.updatedBy,
                productId: pmt.id,
            };
            return pmt;
        });
    }

    function setApprovalAttributesAch() {
        $scope.pendingApprovalAchList.map(pmt => {
            pmt.approvalAttributes = {
                approvalEntity: 'achPayment',
                approvalCount: pmt.completedApprovalCount,
                amount: pmt.amount,
                createdBy: pmt.createdBy,
                updatedBy: pmt.updatedBy,
                productId: pmt.id,
            };
            return pmt;
        });
    }

    // footer
    function isReviewing() {
        return $scope.isReviewing;
    }

    function isOnConfirmation() {
        return $scope.isConfirmation;
    }

    function getForm() {
        return $scope.form;
    }

    function reload() {
        $scope.loadCompletionCallback = function () {
            $scope.$parent.flipWidget($scope.widget, false);
            $scope.isConfirmation = false;
            $scope.isReviewing = false;
            $scope.widget.leftControlsController.isReviewing = false;
            $scope.widget.leftControlsController.isConfirmation = false;
        };
        $scope.form.$setPristine();
        loadTableData($scope.widget.leftControlsController.activeProduct);
    }

    function review(type, decision) {
        $scope.list = [];
        if (type === 'transfer') {
            $scope.pendingApprovalTransferList.forEach(item => {
                addToList(item, decision);
            });
        }
        if (type === 'loanpayment') {
            $scope.pendingApprovalLoanPaymentList.forEach(item => {
                addToList(item, decision);
            });
        }
        if (type === 'ach') {
            $scope.pendingApprovalAchList.forEach(item => {
                addToList(item, decision);
            });
        }
        if (type === 'wire') {
            $scope.pendingApprovalWireList.forEach(item => {
                addToList(item, decision);
            });
        }

        $scope.successMessage = '';
        $scope.errorMessage = '';
        $scope.$parent.flipWidget($scope.widget, true);
        $scope.isReviewing = true;
        $scope.widget.leftControlsController.isReviewing = true;
    }

    function addToList(item, decision) {
        if (item.isChecked) {
            item.actionTaken = decision;
            $scope.list.push(item);
        }
    }

    function cancel() {
        $scope.$parent.flipWidget($scope.widget, false);
        $scope.isReviewing = false;
        $scope.widget.leftControlsController.isReviewing = false;
    }

    function isTabSelected(product) {
        return $scope.widget.leftControlsController.activeProduct == product;
    }

    function confirm(productType) {
        const batch = [];
        $scope.list.forEach(item => {
            batch.push({ key: item.id, value: item.actionTaken });
        });
        $scope.isConfirmation = true;
        updateSpinner(spinnerService.startGroupRequest('paymentsPendingApprovalWidget'));

        let actionType;
        switch (productType) {
            case 'transfer':
                actionType = 'Approve Transfer';
                break;
            case 'loanpayment':
                actionType = 'Approve Loan Payment';
                break;
            case 'ach':
                actionType = 'Approve ACH Payment';
                break;
            case 'wire':
                actionType = 'Approve WIRE Payment';
                break;
        }

        const batchModel = {
            batch,
        };
        securityService
            .verifyUser(actionType, batchModel, () =>
                dashboardService.postBatch(productType, batchModel)
            )
            .then(
                response => {
                    updateSpinner(spinnerService.stopGroupRequest('paymentsPendingApprovalWidget'));
                    if (response.successMessage) {
                        $scope.successMessage = response.successMessage;
                    }
                    if (response.errorMessage) {
                        $scope.errorMessage = response.errorMessage;
                    }
                    if (response && !response.successMessage && !response.errorMessage) {
                        $scope.successMessage = 'Changes saved successfully';
                    }
                    switch (productType) {
                        case 'transfer':
                            updateResponse(response.internalTransfers);
                            setApprovalAttributesTransfer();
                            $scope.selectAll.transfer = false;
                            break;
                        case 'loanpayment':
                            updateResponse(response.internalTransfers);
                            setApprovalAttributesLoanPayment();
                            $scope.selectAll.loanpayment = false;
                            break;
                        case 'ach':
                            updateResponse(response.achPayments);
                            setApprovalAttributesAch();
                            $scope.selectAll.ach = false;
                            break;
                        case 'wire':
                            updateResponse(response.wires);
                            setApprovalAttributesWire();
                            $scope.selectAll.wire = false;
                            break;
                    }
                },
                error => {
                    updateSpinner(spinnerService.stopGroupRequest('paymentsPendingApprovalWidget'));
                    loadTableData(productType);
                    $scope.isConfirmation = false;
                    cancel();
                    reset();
                }
            );
    }

    function updateResponse(responseTransactions) {
        $scope.list.forEach(item => {
            responseTransactions.forEach(responseItem => {
                if (item.id === responseItem.id) {
                    item.successMessage = responseItem.successMessage;
                    item.errorMessage = responseItem.errorMessage;
                    item.completedApprovalCount = responseItem.completedApprovalCount;
                    item.status = responseItem.status;
                }
            });
        });
    }

    function goToWireList(wire) {
        $state.go('payables.wire.wire-details', { id: wire.id, type: 'wireList' });
    }

    $scope.$watch(
        '$parent.dashboardEntitlements',
        newValue => {
            if (newValue !== null && typeof newValue === 'object') {
                $scope.summarySection = newValue;
                $scope.summarySection.forEach(item => {
                    if (item.widget === 'paymentsPendingApproval') {
                        $scope.entitlement = item.permission;
                    }
                });
            }
        },
        true
    );

    function loadProduct(product) {
        if ($scope.form.$dirty) {
            const modalOptions = {
                closeButtonText: 'Continue Editing',
                actionButtonText: 'OK',
                headerText: 'Cancel',
                bodyText: 'Are you sure you want to cancel the changes?',
                submit(result) {
                    reset();
                    $scope.form.$setPristine();
                    $scope.widget.leftControlsController.activeProduct = product;
                    $modalInstance1.dismiss();
                },
            };
            var $modalInstance1 = modalService.showModal({}, modalOptions);
        } else {
            $scope.form.$setPristine();
            $scope.widget.leftControlsController.activeProduct = product;
        }
    }

    function selectOrDeselectTransfer() {
        $scope.pendingApprovalTransferList.forEach(item => {
            item.isChecked = $scope.selectAll.transfer;
        });
        if (!$scope.selectAll.transfer) {
            $scope.form.$setPristine();
        }
    }

    function selectOrDeselectLoanPayment() {
        $scope.pendingApprovalLoanPaymentList.forEach(item => {
            item.isChecked = $scope.selectAll.loanpayment;
        });
        if (!$scope.selectAll.loanpayment) {
            $scope.form.$setPristine();
        }
    }

    function selectOrDeselectWire() {
        $scope.pendingApprovalWireList.forEach(item => {
            item.isChecked = $scope.selectAll.wire;
        });
        if (!$scope.selectAll.wire) {
            $scope.form.$setPristine();
        }
    }

    function selectOrDeselectAch() {
        $scope.pendingApprovalAchList.forEach(item => {
            item.isChecked = $scope.selectAll.ach;
        });
        if (!$scope.selectAll.ach) {
            $scope.form.$setPristine();
        }
    }

    function selectTransfer() {
        let allSelected = true;
        let noneSelected = true;
        $scope.pendingApprovalTransferList.forEach(item => {
            if (!item.isChecked) {
                allSelected = false;
            } else {
                noneSelected = false;
            }
        });
        $scope.selectAll.transfer = allSelected;
        if (noneSelected) {
            $scope.form.$setPristine();
        }
    }

    function selectLoanPayment() {
        let allSelected = true;
        let noneSelected = true;
        $scope.pendingApprovalLoanPaymentList.forEach(item => {
            if (!item.isChecked) {
                allSelected = false;
            } else {
                noneSelected = false;
            }
        });
        $scope.selectAll.loanpayment = allSelected;
        if (noneSelected) {
            $scope.form.$setPristine();
        }
    }

    function selectWire() {
        let allSelected = true;
        let noneSelected = true;
        $scope.pendingApprovalWireList.forEach(item => {
            if (!item.isChecked) {
                allSelected = false;
            } else {
                noneSelected = false;
            }
        });
        $scope.selectAll.wire = allSelected;
        if (noneSelected) {
            $scope.form.$setPristine();
        }
    }

    function selectAch() {
        let allSelected = true;
        let noneSelected = true;
        $scope.pendingApprovalAchList.forEach(item => {
            if (!item.isChecked) {
                allSelected = false;
            } else {
                noneSelected = false;
            }
        });
        $scope.selectAll.ach = allSelected;
        if (noneSelected) {
            $scope.form.$setPristine();
        }
    }

    function loadAllProducts() {
        loadTableData('transfer');
        loadTableData('loanpayment');
        loadTableData('wire');
        loadTableData('ach');
        $scope.widget.leftControlsController.activeProduct = 'transfer';
    }

    function goToTransfer(item) {
        const stateName =
            item.frequency === 'OneTime'
                ? 'payables.transferList'
                : 'payables.recurringTransferList';
        $state.go(stateName, {
            transactionId: item.transactionId,
            transferDate: item.transferDate,
        });
    }

    function goToLoanPayment(item) {
        $state.go('payables.loan-payment-list', {
            transactionId: item.transactionId,
            transferDate: item.transferDate,
        });
    }

    function goToPaymentDetail(payment) {
        if (achPaymentsService.isChildSupportPayment(payment)) {
            $state.go('payables.ach.child-support-details', { id: payment.id });
        } else if (achPaymentsService.isInternationalAchPayment(payment)) {
            $state.go('payables.ach.payments.international-ach-payment-details', {
                id: payment.id,
            });
        } else if (achPaymentsService.isTaxPayment(payment)) {
            $state.go('payables.ach.taxPayment-detail', { id: payment.id });
        } else {
            $state.go('payables.ach.payment-detail', {
                id: payment.id,
                type: 'view',
                list: 'payment-list',
            });
        }
    }

    function reset() {
        $scope.pendingApprovalTransferList.forEach(item => {
            item.isChecked = false;
        });
        $scope.pendingApprovalLoanPaymentList.forEach(item => {
            item.isChecked = false;
        });
        $scope.pendingApprovalWireList.forEach(item => {
            item.isChecked = false;
        });
        $scope.pendingApprovalAchList.forEach(item => {
            item.isChecked = false;
        });
        $scope.selectAll = {
            transfer: false,
            loanpayment: false,
            wire: false,
            ach: false,
        };
        $scope.form.$setPristine();
    }

    function loadTableData(productType) {
        updateSpinner(spinnerService.startGroupRequest('paymentsPendingApprovalWidget'));
        dashboardService.getPaymentsPendingApproval(productType).then(
            response => {
                updateSpinner(spinnerService.stopGroupRequest('paymentsPendingApprovalWidget'));
                if (productType === 'transfer') {
                    response = response.map(transfer => {
                        transfer.status =
                            transfer.status === 'PendingApproval'
                                ? 'Pending Approval'
                                : transfer.status;
                        return transfer;
                    });
                    $scope.pendingApprovalTransferList = response;
                    setApprovalAttributesTransfer();
                    $scope.widget.leftControlsController.transferCount =
                        $scope.pendingApprovalTransferList.length;
                }
                if (productType === 'loanpayment') {
                    response = response.map(loanpayment => {
                        loanpayment.status =
                            loanpayment.status === 'PendingApproval'
                                ? 'Pending Approval'
                                : loanpayment.status;
                        return loanpayment;
                    });
                    $scope.pendingApprovalLoanPaymentList = response;
                    setApprovalAttributesLoanPayment();
                    $scope.widget.leftControlsController.loanPaymentCount =
                        $scope.pendingApprovalLoanPaymentList.length;
                }
                if (productType === 'wire') {
                    $scope.pendingApprovalWireList = response;
                    setApprovalAttributesWire();
                    $scope.widget.leftControlsController.wireCount =
                        $scope.pendingApprovalWireList.length;
                }
                if (productType === 'ach') {
                    $scope.pendingApprovalAchList = response;
                    setApprovalAttributesAch();
                    $scope.widget.leftControlsController.achCount =
                        $scope.pendingApprovalAchList.length;
                }
            },
            error => {
                updateSpinner(spinnerService.stopGroupRequest('paymentsPendingApprovalWidget'));
            }
        );
    }

    function updateSpinner(numPendingRequests) {
        const isLoading = numPendingRequests > 0;
        if ($scope.widget.isLoading && !isLoading && $scope.loadCompletionCallback) {
            $scope.loadCompletionCallback();
            $scope.loadCompletionCallback = null;
        }
        $scope.widget.isLoading = isLoading;
    }
}
