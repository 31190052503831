import { Injectable } from '@jack-henry/frontend-utils/di';
import { CountriesClient } from '@treasury/api/channel';

@Injectable()
export class CountriesService {
    constructor(private readonly countriesClient: CountriesClient) {}

    public async getAll() {
        return (await this.countriesClient.countriesGet()).data;
    }
}
