/* eslint-disable no-negated-condition */
/* eslint-disable no-nested-ternary */
/* eslint-disable @treasury/no-branch-in-template-literal */
/* eslint-disable @treasury/consistent-custom-element-name */
/* eslint-disable @treasury/filename-match-export */
/* eslint-disable sonarjs/no-nested-template-literals */
/* cspell:ignore initial Checkexception sublinks */
import { DiContainer } from '@jack-henry/frontend-utils/di';
import { deepEquals } from '@jack-henry/frontend-utils/functions';
import { Recordset } from '@treasury/FDL';
import { AlarmClock } from '@treasury/alarm-clock';
import { AnalyticsEvent, AnalyticsService } from '@treasury/core/analytics';
import { CheckException } from '@treasury/domain/arp';
import { PositivePayExceptionRequests } from '@treasury/domain/channel/requests/positive-pay/positive-pay-exception-requests.js';
import EntitlementsService from '@treasury/domain/channel/services/entitlements/entitlements-service';
import CheckExceptionsServices from '@treasury/domain/channel/services/positive-pay/check-exceptions-services';
import {
    ACCEPTED_ATTACHMENT_EXTENSIONS,
    CHECK_EXCEPTION_CUTOFF,
    CHECK_EXCEPTION_START,
    CORRECTION_REQUEST_OPTION,
    EXCEPTION_REASONS,
    RETURN_REASON_OPTION,
} from '@treasury/domain/channel/types/arp/constants.js';
import { ListeningElementMixin } from '@treasury/omega/components';
import { openDialog } from '@treasury/omega/components/dialog';
import '@treasury/omega/components/omega-file-upload.js';
import '@treasury/omega/components/omega-filter-bar.js';
import '@treasury/omega/components/omega-radio-group.js';
import '@treasury/omega/components/omega-table.js';
import '@treasury/omega/layouts/omega-report';
import { amountRange, dateRangePickerNoDefault, string } from '@treasury/policy/primitives';
import { delay } from '@treasury/utils';
import { LitElement, css, html, nothing } from 'lit';
import '../../components/blocking-loader.js';
import checkExceptionFields from './data/check-exception-fields.js';
import { setColumns } from './data/table-columns.js';
import { createCheckExceptionDetail } from './parts/check-exception-detail.js';

class CheckExceptionsV2 extends ListeningElementMixin(LitElement) {
    static get properties() {
        return {
            institution: String,
            loading: Boolean,
            loadingFilters: Boolean,
            actions: Array,
            filters: Array,
            exceptions: Object,
            recordset: Object,
            updatedRecordset: Object,
            arpExceptionAccounts: Array,
            returnReasons: Array,
            options: Array,
            localFilters: Array,
            isSavingExceptions: Boolean,
            isFilteringDecisionedItems: { state: true, type: Boolean },
            isAfterCutoff: { state: true, type: Boolean },
            alert: Object,
            changedRecords: Array,
            commentSaveButtonDisabled: Boolean,
            columns: Array,
            loadingImages: Boolean,
            _hasAttachmentEntitlement: { state: true, type: Boolean },
            exceptionStatus: String,
            typeToFilterFn: Object,
            exceptionStatusFilterFn: Object,
            reviewAll: Boolean,
        };
    }

    constructor() {
        super();
        this.alert = {
            title: '',
            message: '',
            visible: false,
            code: '',
        };
        this.loadingFilters = true;
        this.isSavingExceptions = false;
        this.isFilteringDecisionedItems = false;
        this.isAfterCutoff = this.fiClock.isAfter(CHECK_EXCEPTION_CUTOFF);
        this.itemLabel = { singular: 'check exception' };
        this.exceptionStatus = this.isAfterCutoff ? 'decisionedToday' : 'toDecision';
        this.reviewAll = false;
        this.typeToFilterFn = () => true;
    }

    get fiClock() {
        return AlarmClock.getInstance();
    }

    connectedCallback() {
        super.connectedCallback();
        this.listenTo(this.fiClock, CHECK_EXCEPTION_START, () => this.requestUpdate());
        this.listenTo(this.fiClock, CHECK_EXCEPTION_CUTOFF, async () => {
            this.recordset.requestHardUpdate();
            await delay(100);
            this.recordset.allRecords.forEach(record => {
                record.setField('isPastCutOffTime', true);
            });
            this.isAfterCutoff = true;
            this.exceptionStatus = 'decisionedToday';
            await this.filterDecisionedItems();
        });
        this.listenTo(document, 'correctionRequestError', e => {
            const error = e.detail;
            this.alert = {
                title: '',
                code: error.code,
                message: error.message,
                visible: true,
                type: 'error',
            };
        });
        this.listenTo(document, 'correctionRequestSuccess', () => {
            this.alert = {
                title: '',
                message: 'Correction Request saved successfully!',
                visible: true,
                code: '',
                type: 'success',
            };
        });
    }

    async firstUpdated() {
        await this.makeRequests();
        this._hasAttachmentEntitlement = await EntitlementsService.instance.hasEntitlement(
            'Feature.PositivePay.CheckException.Attachments'
        );
        this.columns = setColumns(false, this.hasReturnReason, false, true);
        this.actions = {
            openCommentDialog: async record => {
                this.commentSaveButtonDisabled = !record.getField('comment');
                this.listenTo(record, 'change', ({ detail }) => {
                    const doneButton = document.querySelector(
                        'omega-dialog omega-button:not([type="icon"])'
                    );
                    if (
                        ((detail.field === 'commentToSave' && record.getField('commentToSave')) ||
                            (detail.field === 'fileToUpload' && record.getField('fileToUpload'))) &&
                        doneButton
                    ) {
                        doneButton.disabled = false;
                    }
                });
                const saveResult = await openDialog({
                    title: 'Comment',
                    content: this.renderCommentDialog(record),
                    actions: resolve => html`
                        <omega-button
                            type="primary"
                            .loading=${this.isSavingExceptions}
                            @click=${() => resolve('confirm')}
                            .disabled=${this.commentSaveButtonDisabled}
                        >
                            Done
                        </omega-button>
                    `,
                });

                if (saveResult === 'confirm') {
                    record.setField('comment', record.getField('commentToSave'));
                    this.saveAttachmentAndComment.bind(this)(record);
                } else if (saveResult === null) {
                    record.setField('fileToUpload', null);
                    // Delay to match omega dialog close animation so field doesn't appear empty before dialog disappears
                    setTimeout(() => record.setField('commentToSave', null), 500);
                }
            },
            acceptDefaultDecision: record => {
                this.alert = {
                    ...this.alert,
                    visible: true,
                    title: 'Accept Decision',
                    message: 'Are you sure you want to decision this check exception?',
                    posture: 'assertive',
                    type: 'warning',
                    actions: html`<div>
                        <omega-button
                            primary
                            @click=${() => {
                                this.processDefaultDecision(record);
                            }}
                            >Decision</omega-button
                        ><omega-button
                            @click=${() => {
                                this.alert = { ...this.alert, visible: false };
                            }}
                            >Cancel</omega-button
                        >
                    </div>`,
                    onClose: () => {
                        this.alert = { ...this.alert, visible: false };
                    },
                };
            },
        };
        this.fields = checkExceptionFields(
            this.returnReasons,
            this.isAfterCutoff,
            this.hasReturnReason
        );
        this.recordset = new Recordset(this.fields, CheckExceptionsServices.searchCheckExceptions);
        await this.filterDecisionedItems();
        this.updatedRecordset = new Recordset(this.fields, []);

        const fieldNames = Object.keys(this.fields);

        this.listenTo(this.recordset, 'updated', () => {
            if (this.reviewAll) {
                const validRecordsToDecision = this.recordset.allRecords.filter(
                    record => record.getField('protected') === 'N'
                );
                this.updatedRecordset.setData(validRecordsToDecision.map(record => record.values));

                return;
            }
            const updatedData = [];
            const records = this.recordset.allRecords.filter(this.exceptionStatusFilterFn);
            records.forEach(record => {
                if (
                    record.getField('decisionChoice') === 'Pay' &&
                    record.getField('returnReason') !== null
                ) {
                    record.setField('returnReason', null);
                }
                // eslint-disable-next-line no-use-before-define
                if (isRecordChanged(fieldNames, record)) {
                    updatedData.push({ ...record.values, step: 1 });
                }
            });
            this.updatedRecordset.setData(updatedData);
            this.requestUpdate();
        });
        this.filters = [
            {
                field: 'account',
                fieldType: string.with
                    .options({
                        data: this.arpExceptionAccounts,
                        text: record => record.accountDisplayLabel,
                        value: record => record.accountUniqueId,
                    })
                    .with.filtering()
                    .and.multipleValues()
                    .thatHas.label('Account')
                    .as.tag('omega-select'),
                value: this.arpExceptionAccounts.map(account => account.accountUniqueId),
            },
            {
                field: 'checkNumber',
                fieldType: string.with
                    .defaultValue('')
                    .thatHas.label('Check Number')
                    .as.tag('omega-input'),
            },
            {
                field: 'paidAmount',
                fieldType: amountRange.with
                    .schema('range')
                    .thatHas.label('Paid Amount')
                    .as.tag('omega-range'),
                value: ['specific', 0],
            },
            {
                field: 'issuedAmount',
                fieldType: amountRange.with
                    .schema('range')
                    .thatHas.label('Issued Amount')
                    .as.tag('omega-range'),
                value: ['specific', 0],
            },
            {
                field: 'postedDate',
                fieldType: dateRangePickerNoDefault.and
                    .label('Posted Date')
                    .as.tag('omega-datepicker'),
            },
            {
                field: 'issuedDate',
                fieldType: dateRangePickerNoDefault.thatHas
                    .label('Issued Date')
                    .as.tag('omega-datepicker'),
            },
            {
                field: 'issuedPayee',
                fieldType: string.thatHas.label('Issued Payee').as.tag('omega-input'),
            },
            {
                field: 'exceptionReasons',
                fieldType: string.with
                    .label('Exception Reason')
                    .with.options({
                        data: EXCEPTION_REASONS.map(reason => ({
                            text: reason,
                            value: reason === 'No Reason' ? '' : reason,
                        })),
                    })
                    .with.filtering()
                    .and.multipleValues()
                    .as.tag('omega-select'),
            },
        ];
    }

    async makeRequests() {
        try {
            this.arpExceptionAccounts =
                await PositivePayExceptionRequests.getArpExceptionAccounts();
            if (this.arpExceptionAccounts && this.arpExceptionAccounts.length === 0) {
                // eslint-disable-next-line no-throw-literal
                throw { code: null, message: 'No ARP exception accounts found', type: 'warning' };
            }
            this.options = await PositivePayExceptionRequests.getPositivePayCompanyConfiguration();
            this.hasRequestCorrection =
                this.hasOption(CORRECTION_REQUEST_OPTION) &&
                EntitlementsService.instance.hasEntitlement('Feature.PositivePay.ReturnReason');
            this.hasReturnReason =
                this.hasOption(RETURN_REASON_OPTION) &&
                (await EntitlementsService.instance.hasEntitlement(
                    'Feature.PositivePay.ReturnReason'
                ));
            if (this.hasReturnReason) {
                this.returnReasons = await PositivePayExceptionRequests.getReturnReasons();
                this.defaultReturnReason = this.returnReasons.find(e => e.isDefault === true);
            }
        } catch (e) {
            this.alert = {
                title: '',
                code: e.code,
                message: e.message,
                visible: true,
                type: e.type ?? 'error',
            };
        } finally {
            this.loadingFilters = false;
        }
    }

    async saveCheckExceptions() {
        this.isSavingExceptions = true;
        this.alert = {
            ...this.alert,
            visible: true,
            message: 'Saving decisions...',
            code: '',
            type: 'time-sensitive',
        };
        try {
            const values = this.updatedRecordset.allRecords.map(record => record.values);
            const totalAmount = values.reduce(
                // eslint-disable-next-line no-return-assign
                (total, exception) => (total += exception.paidAmount || 0),
                0
            );

            await CheckExceptionsServices.saveCheckExceptions(values);

            const analyticsService = (await DiContainer.getInstance()).get(AnalyticsService);
            analyticsService.track(AnalyticsEvent.CheckExceptionReview, {
                itemCount: values.length,
                totalAmount,
            });

            this.alert = {
                ...this.alert,
                visible: true,
                message: 'Decisions saved successfully!',
                code: '',
                type: 'success',
            };
        } catch (e) {
            this.alert = {
                ...this.alert,
                visible: true,
                message: e.message,
                code: e.code,
                type: 'error',
            };
        }
        this.isSavingExceptions = false;
        this.updatedRecordset.setData([]);
        this.shadowRoot.querySelector('#check-exception-report').forceFetch();
    }

    async saveAttachmentAndComment(record) {
        try {
            await CheckExceptionsServices.saveAttachmentAndComment(
                new CheckException({ ...record.values }),
                record.values.comment,
                record.values.fileToUpload
            );
            this.alert = {
                ...this.alert,
                visible: true,
                message: 'Comment saved successfully!',
                type: 'success',
            };
            if (record.getField('fileToUpload')) {
                record.setField('attachmentFileName', record.getField('fileToUpload').name);
            }
        } catch (e) {
            console.error(e);
            this.alert = {
                ...this.alert,
                visible: true,
                message: e.message,
                code: e.code,
                type: 'error',
            };
            record.setField('fileToUpload', null);
        }
    }

    async handleDownload(e) {
        this.downloading = true;
        try {
            await PositivePayExceptionRequests.downloadArpExceptionsList(e.detail, 'OpenItems');
        } catch (error) {
            this.alert = { ...this.alert, visible: true, type: 'error' };
        } finally {
            this.downloading = false;
        }
    }

    async getCheckExceptionAttachment(arpExceptionDetailUniqueId) {
        await CheckExceptionsServices.getCheckExceptionAttachment(arpExceptionDetailUniqueId);
    }

    afterCutoff() {
        return (
            this.fiClock.isBefore(CHECK_EXCEPTION_START) ||
            this.fiClock.isAfter(CHECK_EXCEPTION_CUTOFF)
        );
    }

    hasOption(optionName) {
        const foundOption = this.options.find(option => option.name === optionName);
        return foundOption && foundOption.value === '1';
    }

    hasDecisionChanged(recordset) {
        return recordset.allRecords.some(
            record => record.getField('initialDecision') !== record.getField('decisionChoice')
        );
    }

    async processDefaultDecision(record) {
        try {
            this.alert = {
                ...this.alert,
                message: html`<omega-progress card></omega-progress>`,
                actions: nothing,
            };

            await CheckExceptionsServices.saveCheckExceptions([
                { ...record.values, decisionChoice: record.values.defaultDecisionChoice },
            ]);
            this.shadowRoot.querySelector('#check-exception-report').forceFetch();
            this.alert = {
                ...this.alert,
                visible: true,
                message: 'Decision saved successfully!',
                type: 'success',
                actions: html`<div>
                    <omega-button
                        @click=${() => {
                            this.alert = { ...this.alert, visible: false };
                        }}
                        >Close</omega-button
                    >
                </div>`,
            };
        } catch (e) {
            this.alert = {
                ...this.alert,
                visible: true,
                message: e.message,
                type: 'error',
            };
        }
    }

    async filterDecisionedItems() {
        this.isFilteringDecisionedItems = true;
        if (!this.isAfterCutoff && this.exceptionStatus === 'toDecision') {
            this.columns = setColumns(false, this.hasReturnReason, false, true, true);
            this.exceptionStatusFilterFn = record =>
                !record.getField('userDecisionedToday') && record.getField('protected') === 'N';
        } else if (!this.isAfterCutoff && this.exceptionStatus === 'decisionedToday') {
            this.columns = setColumns(false, this.hasReturnReason, false, true);
            this.exceptionStatusFilterFn = record =>
                record.getField('userDecisionedToday') || record.getField('protected') === 'Y';
        } else {
            this.columns = setColumns(false, this.hasReturnReason, false, true);
            this.exceptionStatusFilterFn = record => !!record;
        }
        await delay(10);
        this.isFilteringDecisionedItems = false;
        this.combineFilters();
    }

    combineFilters() {
        setTimeout(() => {
            this.recordset.filter = record =>
                this.typeToFilterFn(record) && this.exceptionStatusFilterFn(record);
        }, 10);
    }

    renderBlockingLoader() {
        return this.loadingImages ? html`<blocking-loader></blocking-loader>` : nothing;
    }

    renderReviewTable() {
        this.updatedRecordset.requestSoftUpdate();
        return html`
            <div style="padding: 0 10px;">
                <omega-table
                    .recordset=${this.updatedRecordset}
                    .columnDefinitions=${setColumns(true, this.hasReturnReason, false, true)}
                ></omega-table>
            </div>
        `;
    }

    renderFileControl(record) {
        if (!this._hasAttachmentEntitlement) return nothing;

        const fileName = record.getField('attachmentFileName');
        return fileName
            ? html`<omega-button
                      type="icon"
                      icon="download"
                      @click=${() =>
                          this.getCheckExceptionAttachment(
                              record.values.arpExceptionDetailUniqueId
                          )}
                  >
                      ${fileName} </omega-button
                  ><omega-file-upload
                      icon="true"
                      buttonText="Replace Attachment"
                      buttonIcon="retweet"
                      .accepted=${ACCEPTED_ATTACHMENT_EXTENSIONS}
                      @filesUploaded=${e => {
                          record.setField('fileToUpload', e.detail.files[0]);
                      }}
                  ></omega-file-upload>`
            : html` <omega-file-upload
                  icon="true"
                  buttonText="Add Attachment"
                  buttonIcon="paperclip"
                  .accepted=${ACCEPTED_ATTACHMENT_EXTENSIONS}
                  @filesUploaded=${e => {
                      record.setField('fileToUpload', e.detail.files[0]);
                  }}
              ></omega-file-upload>`;
    }

    renderCommentDialog(record) {
        record.setField('commentToSave', record.getField('comment'));
        return html`
            <div style="padding: 0 10px;">
                <omega-field field="commentToSave" .record=${record}></omega-field>
                ${this.renderFileControl(record)}
            </div>
        `;
    }

    renderAlert() {
        const { code, time, message, type, title, actions, posture, visible } = this.alert;
        const renderedCode = code ? html`${code}: ` : nothing;
        const renderedTime = time ? html`<br />Time: ${time}` : nothing;

        return html` <div class="alert-wrapper">
            <omega-alert
                type=${type}
                title=${title}
                posture=${posture}
                ?isVisible=${visible}
                @close=${() => {
                    this.alert = { ...this.alert, visible: false };
                }}
            >
                ${renderedCode} ${message} ${renderedTime} ${actions}
            </omega-alert>
        </div>`;
    }

    renderOutsideCutoffTime(message) {
        return html`
            <div class="outside-cutoff-time">
                <div class="header">
                    <div>
                        <h2 class="tm-title">Check Exceptions</h2>
                        <div class="sublinks">
                            <a
                                class="tm-link"
                                href="${`${window.location.origin}/${this.institution}/payables/arp/check-exceptions/decision-activity`}"
                            >
                                Decision Activity
                            </a>
                            <a
                                class="tm-link"
                                href="${`${window.location.origin}/${this.institution}/payables/arp/issued-items-activity`}"
                                >Issued Items Activity</a
                            >
                        </div>
                    </div>
                </div>
                <p class="message">${message}</p>
            </div>
        `;
    }

    renderCutoffAlert() {
        if (this.fiClock.isAfter(CHECK_EXCEPTION_CUTOFF)) {
            return html`
                <omega-alert isVisible type="info">
                    ${`Exception items are disabled because the current time is past the ${this.fiClock.getAlarm(
                        CHECK_EXCEPTION_CUTOFF
                    )} Cut-Off time (${this.fiClock.timeZone})`}
                </omega-alert>
            `;
        }
        return nothing;
    }

    renderReturnReasonInfo() {
        if (this.hasReturnReason) {
            return html`
                <p>
                    If no return reason is selected the default reason of
                    <strong>${this.defaultReturnReason?.description}</strong>
                    will be applied at cutoff.
                </p>
            `;
        }
        return nothing;
    }

    renderTableHeader() {
        if (this.isAfterCutoff) {
            return html`<h4>Decisioned Today</h4>`;
        }

        return html`<omega-radio-group
            name="exception-status"
            id="exception-status-group"
            orientation="horizontal"
            label=""
            .radios=${[
                { label: 'To Decision', value: 'toDecision' },
                { label: 'Decisioned Today', value: 'decisionedToday' },
                { label: 'All Items', value: 'allItems' },
            ]}
            .value=${this.exceptionStatus}
            @change=${async e => {
                this.exceptionStatus = e.detail;
                const filterBar = this.shadowRoot.querySelector('omega-filter-bar');
                filterBar.filter('');
                await this.filterDecisionedItems();
            }}
            style="padding-bottom: 4px;"
        ></omega-radio-group>`;
    }

    render() {
        if (this.fiClock.isBefore(CHECK_EXCEPTION_START)) {
            return this.renderOutsideCutoffTime(
                `Exception items are not available at this time. Please try again after ${this.fiClock.getAlarm(
                    CHECK_EXCEPTION_START
                )} (${this.fiClock.timeZone}).`
            );
        }
        if (!this.recordset || this.loadingFilters || !this.columns || !this.filters)
            return nothing;
        return html`
            ${this.renderBlockingLoader()} ${this.renderAlert()}
            <omega-report
                id="check-exception-report"
                title="Check Exceptions"
                .filters=${this.filters}
                .fields=${this.fields}
                .columns=${this.columns}
                .recordset=${this.recordset}
                .itemLabel=${this.itemLabel}
                .localFilters=${this.localFilters}
                .detailFunction=${createCheckExceptionDetail(this.hasRequestCorrection)}
                .tableLoading=${this.isSavingExceptions || this.isFilteringDecisionedItems}
                .actions=${this.actions}
                .reportLinks=${[
                    {
                        title: 'Check Exceptions - Decision Activity',
                        url: `${window.location.origin}/${this.institution}/payables/arp/check-exceptions/decision-activity`,
                    },
                    {
                        title: 'Issued Items Activity',
                        url: `${window.location.origin}/${this.institution}/payables/arp/issued-items-activity`,
                    },
                ]}
                .reportActions=${this.afterCutoff()
                    ? []
                    : !this.updatedRecordset
                      ? [
                            {
                                type: 'approve',
                                label: 'Review',
                                action: async () => {
                                    const saveResult = await openDialog({
                                        title: 'Review Decisions',
                                        content: this.renderReviewTable(),
                                        actions: resolve => html`
                                            <omega-button
                                                type="approve"
                                                .loading=${this.isSavingExceptions}
                                                @click=${() => resolve('confirm')}
                                            >
                                                Decision (${this.updatedRecordset.totalCount})
                                            </omega-button>
                                            <omega-button @click=${() => resolve('cancel')}>
                                                Cancel
                                            </omega-button>
                                        `,
                                    });
                                    if (saveResult === 'confirm') this.saveCheckExceptions();
                                },
                            },
                        ]
                      : this.exceptionStatus === 'decisionedToday'
                        ? [
                              {
                                  type: 'approve',
                                  label: 'Review',
                                  action: async () => {
                                      const saveResult = await openDialog({
                                          title: 'Review Decisions',
                                          content: this.renderReviewTable(),
                                          actions: resolve => html`
                                              <omega-button
                                                  type="approve"
                                                  .loading=${this.isSavingExceptions}
                                                  @click=${() => resolve('confirm')}
                                              >
                                                  Decision (${this.updatedRecordset.totalCount})
                                              </omega-button>
                                              <omega-button @click=${() => resolve('cancel')}>
                                                  Cancel
                                              </omega-button>
                                          `,
                                      });
                                      if (saveResult === 'confirm') this.saveCheckExceptions();
                                  },
                                  isDisabled: () =>
                                      this.isSavingExceptions ||
                                      (this.updatedRecordset &&
                                          this.updatedRecordset.totalCount === 0),
                              },
                              {
                                  type: 'secondary',
                                  label: 'Reset',
                                  action: () => {
                                      this.recordset.requestHardUpdate();
                                      this.updatedRecordset = new Recordset(this.fields, []);
                                      this.updatedRecordset.requestSoftUpdate();
                                  },
                                  isDisabled: () =>
                                      this.isSavingExceptions ||
                                      !this.updatedRecordset ||
                                      this.updatedRecordset.totalCount === 0,
                              },
                          ]
                        : [
                              {
                                  type: 'approve',
                                  label: 'Review',
                                  action: async () => {
                                      const saveResult = await openDialog({
                                          title: 'Review Decisions',
                                          content: this.renderReviewTable(),
                                          actions: resolve => html`
                                              <omega-button
                                                  type="approve"
                                                  .loading=${this.isSavingExceptions}
                                                  @click=${() => resolve('confirm')}
                                              >
                                                  Decision (${this.updatedRecordset.totalCount})
                                              </omega-button>
                                              <omega-button @click=${() => resolve('cancel')}>
                                                  Cancel
                                              </omega-button>
                                          `,
                                      });
                                      if (saveResult === 'confirm') this.saveCheckExceptions();
                                  },
                                  isDisabled: () =>
                                      this.isSavingExceptions ||
                                      (this.updatedRecordset &&
                                          this.updatedRecordset.totalCount === 0),
                              },
                              {
                                  type: 'primary',
                                  label: 'Review All',
                                  action: async () => {
                                      this.reviewAll = true;
                                      const validRecordsToDecision =
                                          this.recordset.allRecords.filter(
                                              this.exceptionStatusFilterFn
                                          );
                                      this.updatedRecordset.setData(
                                          validRecordsToDecision.map(record => record.values)
                                      );
                                      const saveResult = await openDialog({
                                          title: 'Review Decisions',
                                          content: this.renderReviewTable(),
                                          actions: resolve => html`
                                              <omega-button
                                                  type="approve"
                                                  .loading=${this.isSavingExceptions}
                                                  @click=${() => resolve('confirm')}
                                              >
                                                  Decision (${this.updatedRecordset.totalCount})
                                              </omega-button>
                                              <omega-button @click=${() => resolve('cancel')}>
                                                  Cancel
                                              </omega-button>
                                          `,
                                      });
                                      if (saveResult === 'confirm') this.saveCheckExceptions();
                                      this.reviewAll = false;
                                  },
                                  isDisabled: () =>
                                      this.isSavingExceptions ||
                                      this.recordset.allRecords.length === 0,
                              },
                              {
                                  type: 'secondary',
                                  label: 'Reset',
                                  action: () => {
                                      this.recordset.requestHardUpdate();
                                      this.updatedRecordset = new Recordset(this.fields, []);
                                      this.updatedRecordset.requestSoftUpdate();
                                  },
                                  isDisabled: () =>
                                      this.isSavingExceptions ||
                                      !this.updatedRecordset ||
                                      this.updatedRecordset.totalCount === 0,
                              },
                          ]}
                .options=${['save', 'print', 'download']}
                .downloadOptions=${['CSV', 'PDF']}
                .indicateFiltering=${false}
                @reportDownload=${this.handleDownload}
                @loadingCheckImage=${e => {
                    this.loadingImages = e.detail;
                }}
                flyout
                autostart
            >
                <div style="margin:0px 16px;" slot="above-table">
                    ${this.renderCutoffAlert()} ${this.renderReturnReasonInfo()}
                    <omega-filter-bar
                        .recordset=${this.recordset}
                        .itemLabel=${this.itemLabel}
                        @change=${({ detail }) => {
                            this.typeToFilterFn = this.recordset.filter;
                            this.combineFilters();
                            this.localFilters = detail;
                        }}
                    ></omega-filter-bar>
                    ${this.renderTableHeader()}
                </div>
            </omega-report>
        `;
    }

    static get styles() {
        return [
            css`
                :host {
                    display: inherit;
                }
                .outside-cutoff-time .header {
                    display: flex;
                    border-bottom: 1px solid var(--omega-light-grey);
                    align-items: center;
                    height: 54px;
                    background-color: #fff;
                }
                .outside-cutoff-time-header .sublinks {
                    display: flex;
                }
                .outside-cutoff-time .message {
                    text-align: center;
                    font-size: 15px;
                }
                .tm-title {
                    color: var(--omega-text-header);
                    padding: 0;
                    margin: 0 16px;
                    display: flex;
                    font-size: 18px;
                    font-weight: 500;
                }
                .tm-link {
                    color: var(--omega-primary);
                    text-decoration: none;
                    border-left: 1px solid var(--omega-light-grey);
                    padding-left: 16px;
                    padding-right: 16px;
                    font-size: 13px;
                    white-space: nowrap;
                }
                .tm-link:first-of-type {
                    border: none;
                }
            `,
        ];
    }
}

function isRecordChanged(fieldNames, record) {
    const isPay = record.getField('decisionChoice') === 'Pay';
    return fieldNames.some(key => {
        // Return reason comment changes are handled independently of the decisioning process
        if (key === 'step' || key === 'commentToSave' || key === 'comment') return false;
        const newValue = record.getField(key);
        const oldValue = record.initialValues[key];
        // Prevents 'Pay' decisions being added as updated when the return reason gets updated to 'null'.  Also there's something odd with the dropdown setting 'null' to 'undefined' in certain situations causing it to be marked as changed when it has not been touched
        if (key === 'returnReason' && ((!oldValue && !newValue) || (isPay && !newValue))) {
            return false;
        }
        return !deepEquals(newValue, oldValue);
    });
}

window.customElements.define('check-exceptions-v2', CheckExceptionsV2);
export default CheckExceptionsV2;
