import { DiContainer } from '@jack-henry/frontend-utils/di';
import { deepEquals } from '@jack-henry/frontend-utils/functions';
import { Recordset } from '@treasury/FDL';
import { AlarmClock } from '@treasury/alarm-clock';
import { AnalyticsEvent, AnalyticsService } from '@treasury/core/analytics';
import { CheckException } from '@treasury/domain/arp';
import EntitlementsService from '@treasury/domain/channel/services/entitlements/entitlements-service';
import CheckExceptionsServices from '@treasury/domain/channel/services/positive-pay/check-exceptions-services';
import {
    ACCEPTED_ATTACHMENT_EXTENSIONS,
    CHECK_EXCEPTION_CUTOFF,
    CHECK_EXCEPTION_START,
    RETURN_REASON_OPTION,
} from '@treasury/domain/channel/types/arp/constants.js';
import { ListeningElementMixin } from '@treasury/omega/components';
import { openDialog } from '@treasury/omega/components/dialog';
import '@treasury/omega/components/omega-file-upload.js';
import '@treasury/omega/components/omega-table';
import { delay } from '@treasury/utils';
import { LitElement, css, html, nothing } from 'lit';
import '../../../../components/blocking-loader.js';
import checkExceptionFields from '../../../../positive-pay/check-exceptions/data/check-exception-fields.js';
import { setColumns } from '../../../../positive-pay/check-exceptions/data/table-columns.js';
import { exceptionWidgetStyles } from './styles.js';

class CheckExceptionWidgetV2 extends ListeningElementMixin(LitElement) {
    static get properties() {
        return {
            actions: Object,
            records: Object,
            recordset: Object,
            updatedRecordset: Object,
            isReviewStep: Boolean,
            reviewAll: Boolean,
            isDetailView: Boolean,
            isAfterCutoff: { state: true, type: Boolean },
            alert: Object,
            saving: Boolean,
            returnReasons: Array,
            options: Array,
            loadingImages: Boolean,
            _hasAttachmentEntitlement: { state: true, type: Boolean },
        };
    }

    constructor() {
        super();
        this.actions = {
            openCommentDialog: async record => {
                const saveResult = await openDialog({
                    title: 'Comment',
                    content: this.renderCommentDialog(record),
                    actions: resolve => html`
                        <omega-button
                            type="primary"
                            .loading=${this.isSavingExceptions}
                            @click=${() => resolve('confirm')}
                        >
                            Done
                        </omega-button>
                    `,
                });
                if (saveResult === 'confirm') this.saveAttachmentAndComment(record);
            },
            detailView: record => this.showDetailView(record),
            acceptDefaultDecision: record => {
                this.alert = {
                    ...this.alert,
                    visible: true,
                    title: 'Accept Decision',
                    message: 'Are you sure you want to decision this check exception?',
                    posture: 'assertive',
                    type: 'warning',
                    actions: html`<div>
                        <omega-button
                            primary
                            @click=${() => {
                                this.processDefaultDecision(record);
                            }}
                            >Decision</omega-button
                        ><omega-button
                            @click=${() => {
                                this.alert = { ...this.alert, visible: false };
                            }}
                            >Cancel</omega-button
                        >
                    </div>`,
                    onClose: () => {
                        this.alert = { ...this.alert, visible: false };
                    },
                };
            },
        };
        this.alert = {
            title: '',
            message: '',
            visible: false,
            code: '',
        };
        this.isReviewStep = false;
        this.reviewAll = false;
        this.saving = false;
        this.isAfterCutoff = this.fiClock.isAfter(CHECK_EXCEPTION_CUTOFF);
    }

    get fiClock() {
        return AlarmClock.getInstance();
    }

    get disableReview() {
        if (!this.updatedRecordset) return true;
        return this.updatedRecordset.allRecords.length === 0;
    }

    get disableReviewAll() {
        if (!this.recordset) return true;
        return this.recordset.allRecords.length === 0;
    }

    connectedCallback() {
        super.connectedCallback();
        this.listenTo(this.fiClock, CHECK_EXCEPTION_START, () => this.requestUpdate());
        this.listenTo(this.fiClock, CHECK_EXCEPTION_CUTOFF, async () => {
            this.isAfterCutoff = true;
            this.isReviewStep = false;
            this.dispatchEvent(
                new CustomEvent('recordUpdateRequest', {
                    bubbles: true,
                    detail: true,
                })
            );
            await delay(100);
            this.recordset.allRecords.forEach(record => {
                record.setField('isPastCutOffTime', true);
            });
            this.columns = [
                ...setColumns(true, this.hasReturnReason, false, true),
                {
                    label: 'Actions',
                    type: 'actions',
                    actions: [
                        {
                            label: 'Details',
                            action: 'detailView',
                            visibleWhen: () => true,
                        },
                    ],
                },
            ];
            this.requestUpdate();
        });
    }

    async firstUpdated() {
        this._hasAttachmentEntitlement = await EntitlementsService.instance.hasEntitlement(
            'Feature.PositivePay.CheckException.Attachments'
        );
        this.hasReturnReason =
            this.hasOption(RETURN_REASON_OPTION) &&
            EntitlementsService.instance.hasEntitlement('Feature.PositivePay.ReturnReason');
        this.fields = checkExceptionFields(
            this.returnReasons,
            this.isAfterCutoff,
            this.hasReturnReason
        );
        this.columns = [
            ...setColumns(this.isAfterCutoff, this.hasReturnReason, false, true),
            {
                label: 'Actions',
                type: 'actions',
                actions: [
                    {
                        label: 'Details',
                        action: 'detailView',
                        visibleWhen: () => true,
                    },
                    {
                        label: 'Accept Decision',
                        action: 'acceptDefaultDecision',
                        visibleWhen: record =>
                            record.getField('protected') === 'N' &&
                            record.getField('returnReason') === this.defaultReturnReason,
                    },
                ],
            },
        ];
        this.reviewColumns = setColumns(true, this.hasReturnReason);
        this.recordset = await new Recordset(this.fields, async () => this.records?.data);
        this.updatedRecordset = await new Recordset(this.fields, async () => []);
        const fieldNames = Object.keys(this.fields);

        this.listenTo(this.recordset, 'updated', () => {
            if (this.reviewAll) {
                const validRecordsToDecision = this.recordset.allRecords.filter(
                    record => record.getField('protected') === 'N'
                );
                this.updatedRecordset.setData(validRecordsToDecision.map(record => record.values));

                return;
            }
            const updatedData = [];
            this.recordset.allRecords.forEach(record => {
                if (
                    record.getField('decisionChoice') === 'Pay' &&
                    record.getField('returnReason') !== null
                ) {
                    record.setField('returnReason', null);
                }
                if (
                    fieldNames.some(key => {
                        const value = record.getField(key);
                        const initialValue = record.initialValues[key];
                        if (key !== 'step') {
                            return !deepEquals(value, initialValue);
                        }
                        return false;
                    })
                ) {
                    updatedData.push(record.values);
                }
            });
            this.updatedRecordset.setData(updatedData);
            this.recordset.requestUpdate();
            this.requestUpdate();
        });
        await this.recordset.requestUpdate();
        this.requestUpdate();
    }

    updated(changedProperties) {
        if (
            (changedProperties.has('records') || changedProperties.has('returnReasons')) &&
            this.returnReasons &&
            this.returnReasons.length
        ) {
            this.defaultReturnReason = this.returnReasons.find(
                e => e.isDefault === true
            ).returnReasonUniqueId;
        }

        if (changedProperties.has('records')) {
            this.recordset = new Recordset(this.fields, () => this.records?.data);
        }

        if (changedProperties.has('isReviewStep') && this.isReviewStep) {
            this.reviewAll = false;
        }
    }

    async saveAttachmentAndComment(record) {
        try {
            await CheckExceptionsServices.saveAttachmentAndComment(
                new CheckException(record.values),
                record.getField('comment'),
                record.getField('fileToUpload')
            );
            this.alert = {
                ...this.alert,
                visible: true,
                message: 'Comment saved successfully!',
                type: 'success',
            };
            if (record.getField('fileToUpload')) {
                record.setField('attachmentFileName', record.getField('fileToUpload').name);
            }
        } catch (e) {
            console.error(e);
            this.alert = {
                ...this.alert,
                visible: true,
                message: e.message,
                code: e.code,
                type: 'error',
            };
        }
    }

    async getCheckExceptionAttachment(arpExceptionDetailUniqueId) {
        await CheckExceptionsServices.getCheckExceptionAttachment(arpExceptionDetailUniqueId);
    }

    async processDefaultDecision(record) {
        try {
            this.alert = {
                ...this.alert,
                message: html`<omega-progress card></omega-progress>`,
                actions: nothing,
            };

            await CheckExceptionsServices.saveCheckExceptions([
                { ...record.values, decisionChoice: record.values.defaultDecisionChoice },
            ]);

            this.alert = {
                ...this.alert,
                visible: true,
                message: 'Decision saved successfully!',
                type: 'success',
                actions: html`<div>
                    <omega-button
                        @click=${() => {
                            this.alert = { ...this.alert, visible: false };
                            this.recordset.requestUpdate();
                        }}
                        >Close</omega-button
                    >
                </div>`,
            };
        } catch (e) {
            this.alert = {
                ...this.alert,
                visible: true,
                message: e.message,
                type: 'error',
            };
        } finally {
            this.dispatchEvent(
                new CustomEvent('recordUpdateRequest', {
                    bubbles: true,
                    detail: true,
                })
            );
        }
    }

    async save() {
        this.saving = true;
        this.alert = {
            ...this.alert,
            visible: true,
            message: 'Saving decisions...',
            type: 'time-sensitive',
        };
        try {
            const values = this.updatedRecordset.allRecords.map(record => record.values);
            const totalAmount = values.reduce(
                // eslint-disable-next-line no-return-assign
                (total, exception) => (total += exception.paidAmount || 0),
                0
            );

            await CheckExceptionsServices.saveCheckExceptions(values);

            const analytics = (await DiContainer.getInstance()).get(AnalyticsService);
            analytics.track(AnalyticsEvent.CheckExceptionReview, {
                itemCount: values.length,
                totalAmount,
            });
        } catch (e) {
            this.alert = {
                ...this.alert,
                visible: true,
                message: e.message,
                type: 'error',
            };
        }
        this.saving = false;
        this.dispatchEvent(
            new CustomEvent('recordUpdateRequest', {
                bubbles: true,
                detail: true,
            })
        );
        this.dispatchEvent(
            new CustomEvent('showSuccessMessage', {
                bubbles: true,
                detail: true,
            })
        );
        this.toggleReviewStep();
    }

    hasOption(optionName) {
        const foundOption = this.options.find(option => option.name === optionName);
        return foundOption && foundOption.value === '1';
    }

    handleActions({ action, record, rowIndex }) {
        this.actions[action](record, rowIndex);
    }

    toggleReviewStep() {
        this.isReviewStep = !this.isReviewStep;
        const stepCount = this.isReviewStep ? 1 : 0;
        this.recordset.allRecords.forEach(record => record.setField('step', stepCount));

        this.dispatchEvent(
            new CustomEvent('setReviewStep', {
                bubbles: true,
                detail: this.isReviewStep,
            })
        );
    }

    showDetailView(record) {
        this.isDetailView = true;
        this.detailRecord = record;
    }

    closeDetailView() {
        this.isDetailView = false;
    }

    renderBlockingLoader() {
        return this.loadingImages ? html`<blocking-loader></blocking-loader>` : nothing;
    }

    renderFileControl(record) {
        if (!this._hasAttachmentEntitlement) return nothing;

        const fileName = record.getField('attachmentFileName');
        return fileName
            ? html`<omega-button
                  type="icon"
                  icon="download"
                  @click=${() =>
                      this.getCheckExceptionAttachment(record.values.arpExceptionDetailUniqueId)}
              >
                  ${fileName}
              </omega-button>`
            : html` <omega-file-upload
                  icon="true"
                  buttonText="Add Attachment"
                  buttonIcon="paperclip"
                  .accepted=${ACCEPTED_ATTACHMENT_EXTENSIONS}
                  @filesUploaded=${e => {
                      record.setField('fileToUpload', e.detail.files[0]);
                  }}
              ></omega-file-upload>`;
    }

    renderCommentDialog(record) {
        return html`
            <div style="padding: 0 10px;">
                <omega-field field="comment" label="Comment" .record=${record}></omega-field>
                ${this.renderFileControl(record)}
            </div>
        `;
    }

    renderOutsideCutoffTime(message) {
        return html`
            <div class="outside-cutoff-time">
                <p class="message">${message}</p>
            </div>
        `;
    }

    renderAlert() {
        if (!this.alert.visible) return nothing;
        const { code, time, message, type, title, actions, posture, visible } = this.alert;
        const renderedCode = code ? html`${code}: ` : nothing;
        const renderedTime = time ? html`<br />Time: ${time}` : nothing;

        return html`
            <div class="alert-wrapper">
                <omega-alert
                    type=${type}
                    title=${title}
                    posture=${posture}
                    ?isVisible=${visible}
                    @close=${() => {
                        this.alert = { ...this.alert, visible: false };
                    }}
                >
                    ${renderedCode} ${message} ${renderedTime} ${actions}
                </omega-alert>
            </div>
        `;
    }

    renderDetailView() {
        return html`
            <div class="details-header">
                <span class="title">
                    <h3>Details ${this.detailRecord.getField('accountNumber')}</h3>
                </span>
                <omega-button
                    type="icon"
                    icon="close"
                    hideLabel="always"
                    @click=${this.closeDetailView}
                ></omega-button>
            </div>
            <div class="details-body omega-flex-form">
                <div class="form-column">
                    <omega-field field="accountNumber" .record=${this.detailRecord}></omega-field>
                    <omega-field field="checkNumber" .record=${this.detailRecord}></omega-field>
                    <omega-field field="paidAmount" .record=${this.detailRecord}></omega-field>
                    <omega-field field="issuedAmount" .record=${this.detailRecord}></omega-field>
                    <omega-field field="postedDate" .record=${this.detailRecord}></omega-field>
                    <omega-field field="issuedDate" .record=${this.detailRecord}></omega-field>
                    <omega-field field="issuedPayee" .record=${this.detailRecord}></omega-field>
                </div>
                <div class="form-column">
                    <omega-field
                        field="returnReasonDescription"
                        .record=${this.detailRecord}
                    ></omega-field>
                    <omega-field field="exceptionReason" .record=${this.detailRecord}></omega-field>
                    <omega-field
                        field="lastDecisionTakenBy"
                        .record=${this.detailRecord}
                    ></omega-field>
                    <omega-field field="decisionStatus" .record=${this.detailRecord}></omega-field>
                    <omega-field field="sourceOfEntry" .record=${this.detailRecord}></omega-field>
                    <omega-field field="protected" .record=${this.detailRecord}></omega-field>
                    <omega-field field="ddaBatchNumber" .record=${this.detailRecord}></omega-field>
                    <omega-field
                        field="ddaSequenceNumber"
                        .record=${this.detailRecord}
                    ></omega-field>
                </div>
                <div class="form-column">
                    <omega-field field="audits" .record=${this.detailRecord}></omega-field>
                </div>
            </div>
        `;
    }

    renderReviewTable() {
        return html`
            <omega-table
                .columnDefinitions=${this.reviewColumns}
                .recordset=${this.updatedRecordset}
                @action=${e => this.handleActions(e.detail)}
            ></omega-table>
            <omega-button-bar alignment="left" position="relative">
                <omega-button type="primary" @click=${this.save} ?disabled=${this.saving}>
                    Decision (${this.updatedRecordset.totalCount})
                </omega-button>
                <omega-button
                    type="secondary"
                    @click=${this.toggleReviewStep}
                    ?disabled=${this.saving}
                >
                    Back
                </omega-button>
            </omega-button-bar>
        `;
    }

    renderTable() {
        if (!this.recordset || !this.columns) return nothing;
        return html`
            <omega-table
                .columnDefinitions=${this.columns}
                .recordset=${this.recordset}
                @action=${e => this.handleActions(e.detail)}
                @loadingCheckImage=${e => {
                    this.loadingImages = e.detail;
                }}
            ></omega-table>
            ${this.renderReviewButton()}
        `;
    }

    renderReviewButton() {
        if (this.isAfterCutoff) return nothing;
        return html`<omega-button-bar alignment="left" position="relative">
            <omega-button
                type="approve"
                @click=${this.toggleReviewStep}
                .disabled=${this.disableReview}
            >
                Review
            </omega-button>
            <omega-button
                type="primary"
                .disabled=${this.disableReviewAll}
                @click=${async () => {
                    this.reviewAll = true;
                    this.toggleReviewStep();
                }}
                >Review All</omega-button
            >
            <omega-button
                type="secondary"
                .disabled=${this.isSavingExceptions ||
                !this.updatedRecordset ||
                this.updatedRecordset.totalCount === 0}
                @click=${() => {
                    this.updatedRecordset = new Recordset(this.fields, []);
                    this.updatedRecordset.requestSoftUpdate();
                    this.dispatchEvent(new CustomEvent('recordUpdateRequest'));
                }}
                >Reset</omega-button
            >
        </omega-button-bar>`;
    }

    renderBody() {
        if (this.isReviewStep) return this.renderReviewTable();
        if (this.isDetailView) return this.renderDetailView();
        return this.renderTable();
    }

    render() {
        if (this.fiClock.isBefore(CHECK_EXCEPTION_START)) {
            return this.renderOutsideCutoffTime(
                `Check exception items are not available at this time. Please try again after ${this.fiClock.getAlarm(
                    CHECK_EXCEPTION_START
                )} (${this.fiClock.timeZone}).`
            );
        }
        if (this.fiClock.isAfter(CHECK_EXCEPTION_CUTOFF)) {
            return html`${this.renderOutsideCutoffTime(
                html`Check exception items are disabled because the current time is past the
                ${this.fiClock.getAlarm(CHECK_EXCEPTION_CUTOFF)} Cut-Off time
                (${this.fiClock.timeZone})`
            )}${this.renderBody()}`;
        }
        if (!this.recordset) return nothing;
        return html` ${this.renderBlockingLoader()} ${this.renderAlert()} ${this.renderBody()}`;
    }

    // eslint-disable-next-line @treasury/style-includes-host-display
    static get styles() {
        return [
            exceptionWidgetStyles,
            css`
                .details-header {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    border-bottom: 1px solid var(--omega-secondary-lighten-300);
                    padding: 0px 15px;
                    height: 45px;
                }
                .details-header h3 {
                    margin: 0;
                    padding: 0;
                }
                .details-body {
                    padding: 0px 15px;
                    overflow: auto;
                }
                .omega-flex-form {
                    display: flex;
                }
                .form-column {
                    flex: 1 1 0;
                    margin: 10px;
                }
                .form-column:not(:last-of-type) {
                    padding-right: 10px;
                    border-right: 1px solid #d3d6d9;
                }
                .omega-flex-form omega-field {
                    --omega-field-control-width: 120px;
                    margin: 5px;
                    min-height: 32px;
                }
            `,
        ];
    }
}
window.customElements.define('check-exception-widget-v2', CheckExceptionWidgetV2);
export default CheckExceptionWidgetV2;
