import {
    CompanyAccountModelDto,
    WireCompanyModelDto,
    WireTemplateModelDto,
} from '@treasury/api/channel';
import { Nullable } from '@treasury/utils/types';
import { nullWireCompany } from '.';
import { nullBeneficiary, nullDebitAccount } from '../channel/types';
import { isEdited } from '../users/helpers/is-edited';

export type WireTemplateEntryType = 'Domestic' | 'International';

export type WireTemplateStatus = 'Pending Approval' | 'Approval Rejected' | 'Ready';

export const nullWireTemplate: WireTemplateModelDto = {
    wireCompany: nullWireCompany,
    beneficiary: nullBeneficiary,
    status: '',
    permissions: [],
    debitAccount: nullDebitAccount,
    name: '',
    isInternational: false,
    isPendingEdit: false,
    isEdited: false,
    initiatedRecurringWire: false,
    id: 0,
    createdBy: 0,
    updatedDate: '',
    numberOfApprovalsNeeded: 0,
    completedApprovalCount: 0,
    successMessage: '',
};

export interface WireTemplateQueryDtoBase {
    /**
     * Does this really need to be the full object or can it just be IDs?
     */
    templateName?: string;
    beneficiaryName?: string;
    debitAccounts: CompanyAccountModelDto[];
    wireCompanies: WireCompanyModelDto[];
    statuses: Array<{
        name: string;
        isChecked?: boolean;
    }>;
    wireType: string;
    createdDate?: string | null;
    createdDateStart?: string | null;
    createdDateEnd?: string | null;
    createdDateType: string;
}

/**
 * Search parameters the API expects when querying for templates.
 */
export type WireTemplateQueryDto = Nullable<WireTemplateQueryDtoBase>;
