import { DiContainer } from '@jack-henry/frontend-utils/di';
import { deepEquals, exists } from '@jack-henry/frontend-utils/functions';
import { AnalyticsEvent, AnalyticsService } from '@treasury/core/analytics';

DayTransactionsController.$inject = ['$scope', 'entitlementsService', '$filter', 'TmDi'];

/**
 * @typedef { import('@treasury/api/channel').ReportResultBaseModelDto  & { clientId: string }} ReportResult
 */

/**
 * @param { DiContainer } TmDi
 */
export default function DayTransactionsController($scope, entitlementsService, $filter, TmDi) {
    const analyticsService = TmDi.get(AnalyticsService);
    $scope.displayTransactionCodeDescriptionInUI = entitlementsService.hasEntitlement(
        'Feature.Report.DisplayTransactionCodeDescriptionInUI'
    );
    $scope.filterObject = {};
    $scope.transactionDayReport = {};
    $scope.hasReportBeenGenerated = false;
    $scope.filterOptions = {
        orderByField: '',
        reverseSort: false,
    };
    $scope.calculateTotals = calculateTotals;
    $scope.isWidthSmall = isWidthSmall;
    $scope.parse = parse;
    $scope.showCheckImageLink = showCheckImageLink;

    function calculateTotals(data, columnName) {
        let result = 0;
        angular.forEach(data, row => {
            if (row[columnName] !== null && row[columnName] !== undefined) {
                result += row[columnName];
            }
        });
        return result;
    }

    function isWidthSmall(widthValue) {
        return widthValue;
    }

    function parse(value) {
        return parseFloat(value);
    }

    function showCheckImageLink(item) {
        return (item.checkImageNumber && item.checkNumber) || item.checkImageNumber;
    }

    function processData() {
        angular.forEach($scope.transactionDayReport.data, item => {
            if (item.debitAmount === null || item.debitAmount === undefined) {
                item.debitAmount = 0;
            }
            if (item.creditAmount === null || item.creditAmount === undefined) {
                item.creditAmount = 0;
            }
        });

        $scope.transactionDayReport.data = $filter('orderByNumeric')(
            $scope.transactionDayReport.data,
            'accountNumber'
        );
    }

    $scope.$on('reportUpdated', (event, response) => {
        $scope.filterOptions.orderByField = angular.copy(response.sortBy);
        if (
            $scope.filterOptions.orderByField !== null &&
            $scope.filterOptions.orderByField !== undefined
        ) {
            if ($scope.filterOptions.orderByField === 'Debits') {
                $scope.filterOptions.orderByField = 'debitAmount';
            } else if ($scope.filterOptions.orderByField === 'Credits') {
                $scope.filterOptions.orderByField = 'creditAmount';
            } else {
                $scope.filterOptions.orderByField =
                    $scope.filterOptions.orderByField.substring(0, 1).toLowerCase() +
                    $scope.filterOptions.orderByField.substring(
                        1,
                        $scope.filterOptions.orderByField.length
                    );
            }
        }
        if (response.isAscending) {
            $scope.filterOptions.reverseSort = false;
        } else {
            $scope.filterOptions.reverseSort = true;
        }
    });

    $scope.$watch(
        'transactionDayReport',
        /**
         * @param { ReportResult | undefined} newValue
         * @param { ReportResult | undefined} oldValue
         */
        (newValue, oldValue) => {
            if (
                exists(newValue) &&
                exists(newValue.summary) &&
                oldValue.clientId !== newValue.clientId
            ) {
                $scope.transactionDayReport = newValue;

                if (angular.isDefined(newValue.data)) {
                    if (oldValue === null) {
                        $scope.sidebarContainerController.setCollapsed(false);
                    } else if (newValue.data.length === 0) {
                        $scope.sidebarContainerController.setCollapsed(false);
                    } else {
                        $scope.sidebarContainerController.setCollapsed(true);
                    }
                    $scope.hasReportBeenGenerated = true;
                    processData();
                }
            }
        },
        true
    );
}
