CreatePaymentMultipleBatchUploadController.$inject = [
    '$scope',
    '$state',
    '$timeout',
    '$modal',
    'toaster',
    'modalService',
    'achCompaniesService',
    'companyAccountsService',
    'achSettingsService',
    'achBatchService',
    'achPaymentsService',
    'entitlementsService',
    'holidaysService',
    'securityService',
    'utilityService',
    'downloadPageId',
    'dateConstants',
    'searchFilterService',
    '$filter',
    '$sce',
    '$rootScope',
    'achConstants',
    'timeZones'
];

export default function CreatePaymentMultipleBatchUploadController(
    $scope,
    $state,
    $timeout,
    $modal,
    toaster,
    modalService,
    achCompaniesService,
    companyAccountsService,
    achSettingsService,
    achBatchService,
    achPaymentsService,
    entitlementsService,
    holidaysService,
    securityService,
    utilityService,
    downloadPageId,
    dateConstants,
    searchFilterService,
    $filter,
    $sce,
    $rootScope,
    achConstants,
    timeZones
) {
    $scope.form = {};
    $scope.globalFrequency = {
        show: false,
        summary: '',
    };
    $scope.isReviewing = false;
    $scope.loadRecipients = loadRecipients;
    $scope.restrict = entitlementsService.hasEntitlement('Restricted Batch');
    $scope.achFull = entitlementsService.hasEntitlement('ACH, Payment, Full Edit');
    $scope.achPartial = entitlementsService.hasEntitlement('ACH, Payment, Partial Edit');
    $scope.achFileUploadEdit = entitlementsService.hasEntitlement('File Upload Edit');
    $scope.isAchPrefundingEntitled = entitlementsService.hasEntitlement(
        'Feature.ACH.AllowUnbalancedPayments'
    );

    $scope.totals = {
        credit: { amount: 0, transactions: 0 },
        debit: { amount: 0, transactions: 0 },
    };

    $scope.allItemsAreSelected = {};
    $scope.selectionList = [];
    $scope.searchObj = { text: null };
    $scope.filteredPayments = {};
    $scope.filterSearch = filterSearch;
    $scope.disableFilter = disableFilter;
    $scope.filterData = filterData;
    $scope.createAnotherPayment = createAnotherPayment;

    $scope.selectOrDeselectAllPayments = selectOrDeselectAllPayments;
    $scope.updateSelectionList = updateSelectionList;
    $scope.fileBatchCount = 0;
    $scope.selectedBatchCount = 0;
    $scope.unselectedChecked = false;

    $scope.uneditableChecked = false;
    $scope.errorsChecked = false;
    $scope.unselectedBatchCount = 0;
    $scope.unauthorizedBatchCount = 0;
    $scope.errorBatchCount = 0;
    $scope.fileSize = 0;
    $scope.fileName = null;
    $scope.fileUploadText = 'File Upload Summary';
    $scope.totalBalancedBatches = 0;
    $scope.totalBalancedDebitAmount = 0;
    $scope.totalBalancedCreditAmount = 0;
    $scope.totalUnbalancedBatches = 0;
    $scope.totalUnbalancedDebitAmount = 0;
    $scope.totalUnbalancedCreditAmount = 0;
    $scope.effectiveDateBatchApply = {
        isChecked: false,
    };
    $scope.isEffectiveDateSuccessMessageShown = isEffectiveDateSuccessMessageShown;
    $scope.setEffectiveDateSuccessMessage = false;
    $scope.toggleEffectiveDateOverrideOption = toggleEffectiveDateOverrideOption;
    $scope.isEffectiveDateOverrideDatePickerShown = isEffectiveDateOverrideDatePickerShown;
    $scope.isValidationMessageVisible = isValidationMessageVisible;
    $scope.setEffectivePaymentDateOverride = setEffectivePaymentDateOverride;
    $scope.clearEffectivePaymentMessage = clearEffectivePaymentMessage;
    $scope.currentDate = moment().format('MM/DD/YYYY');
    $scope.numbers = dateConstants.daysInMonth;
    $scope.showEffectiveDateModal = showEffectiveDateModal;
    $scope.shouldShowOffsetLookup = shouldShowOffsetLookup;
    $scope.shouldHideOffsetColumn = shouldHideOffsetColumn;
    $scope.shouldShowOffsetAccountReadOnly = shouldShowOffsetAccountReadOnly;
    $scope.getPaymentErrors = getPaymentErrors;

    $scope.dpOptions = {
        disableDates(date) {
            if (
                $scope.disableToday &&
                moment(date).format('L') ===
                    moment($scope.processingCutoff.currentFiTime).format('L')
            ) {
                return true;
            }
            if (date) {
                return holidaysService.compareDates(date, $scope.holidayDates);
            }
            return false;
        },
    };

    function showEffectiveDateModal() {
        return $scope.isReviewing === false;
    }
    function clearEffectivePaymentMessage() {
        $scope.setEffectiveDateSuccessMessage = false;
    }
    function isEffectiveDateSuccessMessageShown() {
        return $scope.setEffectiveDateSuccessMessage === true;
    }
    function setEffectivePaymentDateOverride(effectivePaymentDateOverride) {
        $scope.effectiveDateBackUp = effectivePaymentDateOverride;
        $scope.payment.achBatchSummaries.forEach(payment => {
            payment.frequency.effectiveDate = effectivePaymentDateOverride;
        });
        $scope.setEffectiveDateSuccessMessage = true;
    }

    function getAchSettings() {
        achSettingsService.get().then(response => {
            $scope.achSettings = response;
        });
    }

    function getAchSettingsAllowUnbalancedPayments() {
        return $scope.achSettings?.allowUnbalancedPayments ?? true;
    }

    function isValidationMessageVisible() {
        return (
            (!$scope.effectiveDateBatchApply.isChecked && $scope.wizardStep === 2) ||
            (!$scope.effectiveDateBatchApply.isChecked && $scope.wizardStep === 3)
        );
    }

    function toggleEffectiveDateOverrideOption() {
        $scope.effectiveDateBatchApply.isChecked === !$scope.effectiveDateBatchApply.isChecked;
        if (!$scope.effectiveDateBatchApply.isChecked) {
            $scope.effectivePaymentDateOverride = '';
            $scope.effectiveDateBackUp = '';
        }
        clearEffectivePaymentMessage();
    }
    function isEffectiveDateOverrideDatePickerShown() {
        return $scope.effectiveDateBatchApply.isChecked === true;
    }

    $scope.isFromUpload = $state.params.upload;
    $scope.findAccount = findAccount;
    $scope.printErrors = printErrors;
    $scope.editBatch = editBatch;
    $scope.review = review;
    $scope.goBack = goBack;
    $scope.goBackUpload = goBackUpload;
    $scope.createPayment = createPayment;
    $scope.cancel = cancel;
    $scope.goToPaymentsList = goToPaymentsList;
    $scope.goToAchFileActivityList = goToAchFileActivityList;
    $scope.inspectBatches = inspectBatches;
    $scope.isPaymentSuccessful = isPaymentSuccessful;
    $scope.errorMessages = [];
    $scope.processingCutoff = {};
    $scope.disableToday = false;
    $scope.offsetAccounts = [];
    $scope.downloadPageId = downloadPageId.AchPaymentUploadConfirmation;
    $scope.filterObject = {};
    $scope.wizardStep = 2;

    function formatDate(dt) {
        return moment(new Date(dt)).format('MM/DD/YYYY');
    }

    function createAnotherPayment() {
        $state.go('payables.ach.payments.create');
    }

    function loadCutoffTimes() {
        companyAccountsService.getCutoffTimesByProductType('SameDayAch').then(response => {
            $scope.processingCutoff = response;
            const currentFiTime = moment(response.currentFiTime).format('L');
            const fiCutoffTime = `${currentFiTime} ${response.processingCutoff.cutoffTime}`;
            const fiCutoffTimeDiff = moment(fiCutoffTime).diff(response.currentFiTime);
            $scope.disableToday = response.processingCutoff.allowSameDay === false;
            const timeout = $scope.disableToday ? 0 : fiCutoffTimeDiff;
            $scope.cutoffPassed = fiCutoffTimeDiff <= 0;

            checkTimeout(timeout);
            getAchNextEffectiveDate();
        });
    }

    function checkTimeout(timeout) {
        $timeout(() => {
            if (timeout > 0) {
                $scope.cutoffPassed = true;
                toaster.alert('Cutoff Time Passed', 'Cannot create ACH for today.');
            }

            utilityService
                .getAchNextBusinessDay(
                    $scope.holidayDates,
                    $scope.disableToday,
                    $scope.cutoffPassed
                )
                .then(response => {
                    for (let i = 0; i < $scope.payment.achBatchSummaries.length; i++) {
                        if (
                            moment(
                                $scope.payment.achBatchSummaries[i].frequency.effectiveDate
                            ).format('L') ===
                            moment($scope.processingCutoff.currentFiTime).format('L')
                        ) {
                            $scope.payment.achBatchSummaries[i].frequency.effectiveDate =
                                formatDate(response);
                        }
                    }
                    if (
                        moment($scope.payment.frequency.effectiveDate).format('L') ===
                        moment($scope.processingCutoff.currentFiTime).format('L')
                    ) {
                        $scope.payment.frequency.effectiveDate = formatDate(response);
                    }
                });
        }, timeout);
    }

    function loadRecipients(batch) {
        const paymentDetailLookup = [];
        paymentDetailLookup.push({ key: batch.achPaymentDraftId, value: batch.name });
        achPaymentsService.getPaymentDraftDetail(paymentDetailLookup).then(response => {
            $modal.open({
                template: require('../views/createPaymentDetail.html'),
                windowClass: 'payment-detail-modal-window',
                controller: 'CreatePaymentDetailController',
                backdrop: 'static',
                resolve: {
                    payment() {
                        response.achBatchSummaries[0].frequency = batch.frequency;
                        return response.achBatchSummaries[0];
                    },
                },
            });
        });
    }

    function filterSearch(searchText) {
        $scope.searchObj.text = searchText || '';
        $scope.filteredPayments = $filter('filter')($scope.payment.achBatchSummaries, filterRows);
    }

    function filterRows(row) {
        if (
            !$scope.searchObj.text ||
            $scope.searchObj.text.trim() === '' ||
            !$scope.payment ||
            !$scope.payment.achBatchSummaries ||
            $scope.payment.achBatchSummaries.length <= 0
        ) {
            return true;
        }
        return (
            (!!row.name &&
                row.name.toLowerCase().indexOf($scope.searchObj.text.toLowerCase()) !== -1) ||
            (!!row.achCompanyName &&
                row.achCompanyName.toLowerCase().indexOf($scope.searchObj.text.toLowerCase()) !==
                    -1) ||
            (!!row.secCode &&
                row.secCode.toLowerCase().indexOf($scope.searchObj.text.toLowerCase()) !== -1) ||
            (!!row.debitAmount &&
                $filter('currency')(row.debitAmount, '$').indexOf($scope.searchObj.text) !== -1) ||
            (!!row.creditAmount &&
                $filter('currency')(row.creditAmount, '$').indexOf($scope.searchObj.text) !== -1) ||
            (!!row.frequency.effectiveDate &&
                $filter('date')(row.frequency.effectiveDate, 'MM/dd/yyyy').indexOf(
                    $scope.searchObj.text
                ) !== -1) ||
            checkOffset(row)
        );
        function checkOffset(row) {
            if (row.offsetAccount)
                return (
                    row.offsetAccount.accountDisplayLabel
                        .toLowerCase()
                        .indexOf($scope.searchObj.text.toLowerCase()) !== -1
                );
            return (
                isBalancedTransaction(row) &&
                'balanced'.indexOf($scope.searchObj.text.toLowerCase()) !== -1
            );
        }
    }

    function filterData() {
        $scope.filteredPayments = $filter('filter')(
            $scope.payment.achBatchSummaries,
            filterDataRows
        );
    }

    function filterDataRows(row) {
        if ($scope.unselectedChecked) {
            if (row.isSelected) {
                return false;
            }
        }
        if ($scope.uneditableChecked) {
            if (row.hasAccess) {
                return false;
            }
        }
        if ($scope.errorsChecked) {
            if (!row.errorSummary) {
                return false;
            }
        }
        return true;
    }

    function disableFilter() {
        return $scope.payment.achBatchSummaries
            ? $scope.payment.achBatchSummaries.length <= 0
            : true;
    }

    function selectOrDeselectAllPayments() {
        $scope.selectedBatchCount = 0;
        $scope.unselectedBatchCount = 0;
        angular.forEach($scope.payment.achBatchSummaries, payment => {
            if (payment.hasAccess && !payment.errorSummary) {
                payment.isSelected = $scope.allItemsAreSelected.value;
                if ($scope.allItemsAreSelected.value === true) {
                    $scope.selectionList.push(payment);
                } else {
                    $scope.selectionList = [];
                }
            }
            if (payment.isSelected) {
                $scope.selectedBatchCount += 1;
            } else {
                $scope.unselectedBatchCount++;
            }
        });
    }

    function updateSelectionList(payment) {
        let allSelected = true;

        if (payment.isSelected === true) {
            $scope.unselectedBatchCount--;
            $scope.selectionList.push(payment);
        } else if (payment.isSelected === false) {
            $scope.unselectedBatchCount++;
            $scope.selectionList.splice($scope.selectionList.indexOf(payment), 1);
        }
        $scope.selectedBatchCount = 0;
        angular.forEach($scope.payment.achBatchSummaries, payment => {
            if (!payment.isSelected && payment.hasAccess && !payment.errorSummary) {
                allSelected = false;
            }
            if (payment.isSelected) {
                $scope.selectedBatchCount += 1;
            }
        });

        if (allSelected) {
            $scope.allItemsAreSelected.value = true;
        } else {
            $scope.allItemsAreSelected.value = false;
        }
    }

    function editBatch(batch) {
        $scope.setUploadedBatch(null);
        $state.go('payables.ach.batch-detail', {
            id: batch.id,
            type: 'edit',
            payment: true,
        });
    }

    function getHolidays() {
        holidaysService.getAll().then(response => {
            $scope.holidayDates = response.map(item => item.date);

            getAchNextEffectiveDate();
        });
    }

    function getAchNextEffectiveDate() {
        if (
            $scope.disableToday == null ||
            $scope.cutoffPassed == null ||
            $scope.holidayDates == null
        ) {
            return;
        }

        utilityService
            .getAchNextEffectiveDate(timeZones[$scope.processingCutoff.fiTimeZone], $scope.holidayDates, $scope.disableToday, $scope.cutoffPassed)
            .then(nextAchEffectiveDate => {
                $scope.payment.frequency.effectiveDate = formatDate(nextAchEffectiveDate);

                const allowSameDayPayments = $scope.processingCutoff.processingCutoff;
                const holidays = $scope.holidayDates;
                const sameDayAchSettings = $scope.achSettings;
                const cutOffTimes = $scope.processingCutoff;

                angular.forEach($scope.payment.achBatchSummaries, async batch => {
                    const batchDate = new Date(batch.frequency.effectiveDate);
                    const isIncomingEffectiveDateValid =
                        await achPaymentsService.isIncomingDateValid(
                            batchDate,
                            allowSameDayPayments,
                            holidays,
                            sameDayAchSettings,
                            cutOffTimes
                        );

                    batch.frequency.effectiveDate = isIncomingEffectiveDateValid
                        ? formatDate(batch.frequency.effectiveDate)
                        : formatDate(nextAchEffectiveDate);
                });
            });
    }

    async function populateAchCompanies(batchSummaries) {
        const achCompanies = await achCompaniesService.getAll();

        let foundCompanies = 0;
        angular.forEach(batchSummaries, summary => {
            const achCompany = achCompanies.find(company => company.id === summary.achCompany.id);
            if (achCompany) {
                foundCompanies++;
                summary.achCompany.prefundingDays = achCompany.prefundingDays;
                summary.achCompany.offsetAccountNumber = achCompany.offsetAccountNumber;
                summary.achCompany.allowUnbalancedPayments = achCompany.allowUnbalancedPayments;
            }
        });
        if (foundCompanies < batchSummaries.length) {
            toaster.alert(
                'Problem with Upload',
                `One or more companies from this batch were not found for this user.`
            );
        }
    }

    async function getOffsetAccounts(batchSummaries) {
        const achCompanyIds = batchSummaries.map(summary => summary.achCompany.id);

        const uniqueAchCompanyIds = achCompanyIds.filter(
            (value, index, self) => self.indexOf(value) === index
        );

        await populateAchCompanies(batchSummaries);

        angular.forEach(uniqueAchCompanyIds, id => {
            achCompaniesService.getOffsetAccounts(id).then(response => {
                angular.forEach(batchSummaries, summary => {
                    if (summary.achCompany.id === id) {
                        summary.offsetAccounts = response;
                        $scope.offsetAccounts.push(summary.offsetAccounts);
                        if (summary.offsetAccount) {
                            if (summary.offsetAccount.id === 0) {
                                summary.offsetAccount = null;
                            } else {
                                for (let i = 0; i < summary.offsetAccounts.length; i++) {
                                    if (summary.offsetAccounts[i].id === summary.offsetAccount.id) {
                                        summary.offsetAccount = summary.offsetAccounts[i];
                                        summary.offsetAccount.accountDisplayLabel = 'number';
                                        break;
                                    }
                                }
                            }
                        }
                    }
                });
            });
        });
    }

    function review() {
        $scope.isReviewing = true;
        $scope.isConfirmation = false;
        $scope.fileUploadText = 'File Upload Review';
        $timeout(() => {
            $('#overflowed-panel')[0].scrollTop = 0;
        }, 250);
        setBatchSummary();
        $scope.wizardStep = 3;
    }

    function setBatchSummary() {
        $scope.totalBalancedBatches = 0;
        $scope.totalBalancedDebitAmount = 0;
        $scope.totalBalancedCreditAmount = 0;
        $scope.totalUnbalancedBatches = 0;
        $scope.totalUnbalancedDebitAmount = 0;
        $scope.totalUnbalancedCreditAmount = 0;

        angular.forEach($scope.payment.achBatchSummaries, batch => {
            if (batch.isSelected) {
                if (batch.creditAmount === batch.debitAmount) {
                    $scope.totalBalancedBatches++;
                    $scope.totalBalancedDebitAmount += batch.debitAmount;
                    $scope.totalBalancedCreditAmount += batch.creditAmount;
                } else {
                    $scope.totalUnbalancedBatches++;
                    $scope.totalUnbalancedDebitAmount += batch.debitAmount;
                    $scope.totalUnbalancedCreditAmount += batch.creditAmount;
                }
            }
        });
    }

    function goBack() {
        if ($scope.isReviewing) {
            $scope.isReviewing = false;
        }
        if ($scope.effectiveDateBatchApply.isChecked) {
            $scope.effectivePaymentDateOverride = $scope.effectiveDateBackUp;
        }
        $scope.fileUploadText = 'File Upload Summary';
        $scope.wizardStep = 2;
    }

    function goBackUpload() {
        const modalOptions = {
            closeButtonText: 'Continue Editing',
            actionButtonText: 'OK',
            headerText: 'Cancel',
            bodyText:
                'Clicking OK will void the file.  You will have to upload the file again.  Are you sure you want to cancel these changes?',
            submit() {
                $modalInstance.close();
                if ($rootScope.previousState.name === 'payables.ach.payments.uploadPayment') {
                    $state.go('payables.ach.payments.uploadPayment');
                } else if ($rootScope.previousState.name === 'payables.ach.fileactivity-list') {
                    goToAchFileActivityList();
                }
            },
        };
        var $modalInstance = modalService.showModal({}, modalOptions);
    }

    function cancel() {
        const modalOptions = {
            closeButtonText: 'Continue Editing',
            actionButtonText: 'OK',
            headerText: 'Cancel',
            bodyText:
                'Clicking OK will void the file.  You will have to upload the file again.  Are you sure you want to cancel these changes?',
            submit() {
                $modalInstance.close();
                if ($rootScope.previousState.name === 'payables.ach.fileactivity-list') {
                    goToAchFileActivityList();
                } else {
                    goToPaymentsList();
                }
            },
        };
        var $modalInstance = modalService.showModal({}, modalOptions);
    }

    function goToPaymentsList() {
        $state.go('payables.ach.payment-list');
    }

    function goToAchFileActivityList() {
        $state.go('payables.ach.fileactivity-list');
    }

    function inspectBatches() {
        const paymentDetailLookup = [];
        angular.forEach($scope.selectionList, batch => {
            paymentDetailLookup.push({ key: batch.achPaymentDraftId, value: batch.name });
        });
        achPaymentsService.getPaymentDraftDetail(paymentDetailLookup).then(response => {
            $modal.open({
                template: require('../views/inspectSelectedBatchesView.html'),
                windowClass: 'inspect-batches-modal-window',
                controller: 'InspectSelectedBatchesController',
                backdrop: 'static',
                resolve: {
                    payment() {
                        angular.forEach(response.achBatchSummaries, toInspect => {
                            angular.forEach($scope.selectionList, batch => {
                                if (toInspect.achPaymentDraftId == batch.achPaymentDraftId) {
                                    toInspect.frequency = batch.frequency;
                                }
                            });
                        });

                        return response.achBatchSummaries;
                    },
                },
            });
        });
    }

    function printErrors() {
        let errors = '<html><body><h3>Nacha File Validation Errors</h3><hr><table>';
        angular.forEach($scope.filteredPayments, batch => {
            if (batch.errorSummary) {
                const errorList = batch.errorSummary.summaryMessageList;
                errors += `<tr><td><strong>Batch Name : </strong>${batch.name}</td></tr><tr><tr><td><ul>`;
                angular.forEach(errorList, error => {
                    if (error.length > 0) errors += `<li>${error}</li>`;
                });
            }
            errors += '</ul></td></tr>';
        });

        errors += '</table></body></html>';

        const printWin = window.open('', '', 'width=1024,height=768');
        printWin.document.write(errors);
        printWin.document.close();
        printWin.focus();
        printWin.print();
        printWin.close();
    }

    function findAccount(originalIndex) {
        $scope.form.$setDirty();
        const modalInstance = $modal.open({
            template: require('../views/achAccountModalView.html'),
            size: 'md',
            controller: 'AchAccountsController',
            backdrop: 'static',
            resolve: {
                offsetAccounts() {
                    return $scope.payment.achBatchSummaries.find(
                        item => item.originalIndex === originalIndex
                    ).offsetAccounts;
                },
            },
        });
        modalInstance.result.then(selectedAccount => {
            $scope.payment.achBatchSummaries.find(
                item => item.originalIndex === originalIndex
            ).offsetAccount = angular.copy(selectedAccount);
        });
    }

    function createPayment() {
        let counter = 0;
        const finalBatchList = [];
        $scope.errorMessages = [];
        angular.forEach($scope.payment.achBatchSummaries, batch => {
            counter++;
            // set batchUniqueId to reload recipients on confirm

            batch.batchUniqueId = counter;
            if (batch.isSelected) {
                finalBatchList[finalBatchList.length] = batch;
            }
        });
        $scope.payment.achBatchSummaries = finalBatchList;
        $scope.fileUploadText = 'File Upload Confirmation';
        securityService
            .verifyUser('Initiate Payment From Batch', $scope.payment, () =>
                achPaymentsService.create($scope.payment)
            )
            .then(response => {
                $scope.isReviewing = false;
                $scope.isConfirmation = true;
                $scope.filterObject.AchPaymentIds = [];
                $timeout(() => {
                    $('#overflowed-panel')[0].scrollTop = 0;
                }, 250);
                angular.forEach(response.payment.achBatchSummaries, payment => {
                    $scope.filterObject.AchPaymentIds.push(payment.id);
                    angular.forEach($scope.payment.achBatchSummaries, batch => {
                        if (
                            payment.batchUniqueId === batch.batchUniqueId &&
                            payment.name === batch.name &&
                            payment.creditAmount === batch.creditAmount &&
                            payment.debitAmount === batch.debitAmount
                        ) {
                            payment.recipients = batch.recipients;
                            payment.frequency = batch.frequency;
                            payment.offsetAccount = batch.offsetAccount;
                            payment.achPaymentDraftId = batch.achPaymentDraftId;
                        }
                    });
                    if (payment.errorMessage != null) {
                        $scope.errorMessages.push(`${payment.name} - ${payment.errorMessage}`);
                    }
                });
                response.payment.fileSize = $scope.fileSize;
                response.payment.fileName = $scope.fileName;
                response.payment.totalBalancedBatches = $scope.totalBalancedBatches;
                response.payment.totalBalancedDebitAmount = $scope.totalBalancedDebitAmount;
                response.payment.totalBalancedCreditAmount = $scope.totalBalancedCreditAmount;
                response.payment.totalUnBalancedBatches = $scope.totalUnbalancedBatches;
                response.payment.totalUnBalancedDebitAmount = $scope.totalUnbalancedDebitAmount;
                response.payment.totalUnBalancedCreditAmount = $scope.totalUnbalancedCreditAmount;

                $scope.payment = response.payment;
                $scope.filterObject = $scope.payment;
                $scope.wizardStep = 4;
                toaster.save('ACH Payment');
            });
    }

    function isDebitTransaction(payment) {
        const hasDebit = payment.recipients.some(
            recipient => recipient.transactionType === 'DR'
        );
        const hasCredit = payment.recipients.some(
            recipient => recipient.transactionType === 'CR'
        );
        return hasDebit && !hasCredit;
    }

    function isCreditTransaction(payment) {
        return payment.debitAmount < payment.creditAmount;
    }

    function isBalancedTransaction(payment) {
        return payment.debitAmount === payment.creditAmount;
    }

    function isPrefundingTransaction(payment) {
        return (
            $scope.isAchPrefundingEntitled &&
            payment.achCompany.prefundingDays > 0 &&
            isCreditTransaction(payment)
        );
    }

    function doesPaymentAllowUnbalancedPayments(payment) {
        return payment.achCompany?.allowUnbalancedPayments ?? false;
    }

    function shouldShowOffsetLookup(payment) {
        if (
            payment.achCompany.allowUnbalancedPayments &&
            getAchSettingsAllowUnbalancedPayments() &&
            $scope.isReviewing
        )
            return false;
        if (
            $scope.isAchPrefundingEntitled &&
            $scope.isReviewing &&
            !$scope.isConfirmation &&
            payment.achCompany.batchBalanceRequirements !== 'Balanced' &&
            (payment.achCompany.prefundingDays <= 0 || isDebitTransaction(payment))) return true;
        if (isBalancedTransaction(payment)) return false;
        if ($scope.isAchPrefundingEntitled && !$scope.isReviewing) return false;
        if (!$scope.isAchPrefundingEntitled && $scope.isReviewing) return false;
        if (isPrefundingTransaction(payment)) return false;
        if ($scope.isConfirmation) return false;
        if (
            doesPaymentAllowUnbalancedPayments(payment) &&
            getAchSettingsAllowUnbalancedPayments() &&
            $scope.isReviewing
        )
            return false;
        return true;
    }

    function shouldHideOffsetColumn() {
        if ($scope.isAchPrefundingEntitled && !$scope.isReviewing && !$scope.isConfirmation)
            return true;
        return false;
    }

    function shouldShowOffsetAccountReadOnly(payment) {
        const isPrefunding = $scope.isAchPrefundingEntitled
            ? payment.achCompany.prefundingDays > 0 && !isDebitTransaction(payment)
            : true;
        if (
            !isPrefunding &&
            (!doesPaymentAllowUnbalancedPayments(payment) ||
                !getAchSettingsAllowUnbalancedPayments())
        )
            return false;
        if (!$scope.isReviewing && !$scope.isConfirmation && payment.hasAccess) return false;
        return true;
    }

    function getPaymentErrors() {
        const errors = [];
        angular.forEach($scope.filteredPayments, payment => {
            const offsetRequired =
                payment.achCompany.prefundingDays <= 0 || isDebitTransaction(payment);
            if (offsetRequired && !payment.offsetAccount) {
                errors.push(achConstants.Errors.offsetAccountRequired);
            }
        });
        return errors;
    }

    function isPaymentSuccessful() {
        return $scope.isConfirmation && $scope.errorMessages.length === 0;
    }

    (async function () {
        // init

        // In the event a user refreshes the page and loses the $scope.payment information,
        // redirect them to the choose create payment method view
        if (!$scope.payment) {
            createAnotherPayment();
        }

        $scope.payment.frequency = {
            effectiveDate: moment(new Date()).format('MM/DD/YYYY'),
            type: 'One Time',
        };

        loadCutoffTimes();
        getAchSettings();
        await getOffsetAccounts($scope.payment.achBatchSummaries);
        $scope.allItemsAreSelected.value = true;
        $scope.filterSearch($scope.searchText);

        selectOrDeselectAllPayments();
        getHolidays();
        $scope.totals.credit.amount = $scope.payment.totalCreditAmount;
        $scope.totals.debit.amount = $scope.payment.totalDebitAmount;
        $scope.totals.credit.transactions = $scope.payment.totalCreditTransactions;
        $scope.totals.debit.transactions = $scope.payment.totalDebitTransactions;

        angular.forEach($scope.payment.achBatchSummaries, batch => {
            $scope.fileBatchCount++;
            // set frequency type if not already set
            if (!batch.frequency) {
                batch.frequency = {};
                batch.frequency.type = $scope.frequencyOptions[0];
                batch.frequency.effectiveDate = moment().format('MM/DD/YYYY');
                batch.frequency.startOn = null;
                batch.frequency.endOn = null;
            }
            if(batch.recipients.length === 0 ) {
                achPaymentsService.getPaymentDraftDetail([{ key: batch.achPaymentDraftId, value: batch.name }]).then(response => {
                    batch.recipients = response.achBatchSummaries[0].recipients;
                });  
            }
            if (!batch.hasAccess) {
                $scope.unauthorizedBatchCount++;
            }
            if (batch.errorSummary) {
                $scope.errorBatchCount++;
            }
        });

        $scope.fileSize = $scope.payment.fileSize;
        $scope.fileName =
            typeof $scope.payment.fileName === 'string' &&
            ($scope.payment.fileName = $scope.payment.fileName.match(/[^\\\/]+$/)) &&
            $scope.payment.fileName[0];
        $scope.totalBalancedBatches = $scope.payment.totalBalancedBatches;
        $scope.totalBalancedDebitAmount = $scope.payment.totalBalancedDebitAmount;
        $scope.totalBalancedCreditAmount = $scope.payment.totalBalancedCreditAmount;
        $scope.totalUnbalancedBatches = $scope.payment.totalUnBalancedBatches;
        $scope.totalUnbalancedDebitAmount = $scope.payment.totalUnBalancedDebitAmount;
        $scope.totalUnbalancedCreditAmount = $scope.payment.totalUnBalancedCreditAmount;
    })();
}
