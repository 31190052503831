/* eslint-disable no-useless-constructor */
import { Injectable } from '@jack-henry/frontend-utils/di';
import {
    CancelWireModelDto,
    CompanyAccountModelDto,
    FrequencyModelDto,
    LookupListModelDto,
    SecurityMessageModelDto,
    WireCompaniesClient,
    WireModelDto,
    WiresClient,
    WireSearchCriteriaModelDto,
} from '@treasury/api/channel';
import { AnalyticsEvent, AnalyticsService } from '@treasury/core/analytics';
import { createUniqueId } from '@treasury/utils';
import {
    Wire,
    WireAccount,
    WireCompany,
    WireSearchCriteria,
    WireTemplate,
    WireTemplateQueryDto,
} from '.';
import { DownloadFormat, DownloadService } from '../channel/services/download';
import { FiDate } from '../dates';
import { getDayForADate, getDayForAnnual, getDaysOfMonth, getStartAndEnd } from './wires.helpers';
import {
    fetchTransferAccounts,
    fetchWireTemplateApprovers,
    searchTemplates,
} from './wires.requests';

@Injectable()
export class WiresService {
    constructor(
        private readonly wiresClient: WiresClient,
        private readonly wireCompaniesClient: WireCompaniesClient,
        private readonly analytics: AnalyticsService
    ) {}

    public async getWirePaymentDetails(id: number) {
        const response = await this.wiresClient.wiresGet(id);
        return new Wire(response.data);
    }

    public createWirePayment(wire: WireModelDto) {
        if (!wire.duplicatePreventionId) {
            wire.duplicatePreventionId = createUniqueId();
        }
        return this.wiresClient.wiresPost(wire);
    }

    public async getWireCompanies(nonDli = false) {
        const response = await this.wireCompaniesClient.wireCompaniesGet(
            { nonDli },
            {
                maxAgeInSeconds: 600,
            }
        );
        return response.data.map(
            wireCompany => new WireCompany(wireCompany) as unknown as WireCompany
        );
    }

    public async getDebitAccounts(id: number, type: string) {
        const response = await this.wireCompaniesClient.wireCompaniesAccounts(
            id,
            { type },
            {
                maxAgeInSeconds: 600,
            }
        );
        return response.data.map(
            wireAccount => new WireAccount(wireAccount) as unknown as WireAccount
        );
    }

    public getWireCurrencies() {
        return [
            { cc: 'USD', symbol: 'US$', name: 'United States dollar' },
            { cc: 'AED', symbol: '\u062f.\u0625;', name: 'UAE dirham' },
            { cc: 'AFN', symbol: 'Afs', name: 'Afghan afghani' },
            { cc: 'ALL', symbol: 'L', name: 'Albanian lek' },
            { cc: 'AMD', symbol: 'AMD', name: 'Armenian dram' },
            { cc: 'ANG', symbol: 'NA\u0192', name: 'Netherlands Antillean gulden' },
            { cc: 'AOA', symbol: 'Kz', name: 'Angolan kwanza' },
            { cc: 'ARS', symbol: '$', name: 'Argentine peso' },
            { cc: 'AUD', symbol: '$', name: 'Australian dollar' },
            { cc: 'AWG', symbol: '\u0192', name: 'Aruban florin' },
            { cc: 'AZN', symbol: 'AZN', name: 'Azerbaijani manat' },
            { cc: 'BAM', symbol: 'KM', name: 'Bosnia and Herzegovina konvertibilna marka' },
            { cc: 'BBD', symbol: 'Bds$', name: 'Barbadian dollar' },
            { cc: 'BDT', symbol: '\u09f3', name: 'Bangladeshi taka' },
            { cc: 'BGN', symbol: 'BGN', name: 'Bulgarian lev' },
            { cc: 'BHD', symbol: '.\u062f.\u0628', name: 'Bahraini dinar' },
            { cc: 'BIF', symbol: 'FBu', name: 'Burundi franc' },
            { cc: 'BMD', symbol: 'BD$', name: 'Bermudian dollar' },
            { cc: 'BND', symbol: 'B$', name: 'Brunei dollar' },
            { cc: 'BOB', symbol: 'Bs.', name: 'Bolivian boliviano' },
            { cc: 'BRL', symbol: 'R$', name: 'Brazilian real' },
            { cc: 'BSD', symbol: 'B$', name: 'Bahamian dollar' },
            { cc: 'BTN', symbol: 'Nu.', name: 'Bhutanese ngultrum' },
            { cc: 'BWP', symbol: 'P', name: 'Botswana pula' },
            { cc: 'BYR', symbol: 'Br', name: 'Belarusian ruble' },
            { cc: 'BZD', symbol: 'BZ$', name: 'Belize dollar' },
            { cc: 'CAD', symbol: '$', name: 'Canadian dollar' },
            { cc: 'CDF', symbol: 'F', name: 'Congolese franc' },
            { cc: 'CHF', symbol: 'Fr.', name: 'Swiss franc' },
            { cc: 'CLP', symbol: '$', name: 'Chilean peso' },
            { cc: 'CNY', symbol: '\u00a5', name: 'Chinese/Yuan renminbi' },
            { cc: 'COP', symbol: 'Col$', name: 'Colombian peso' },
            { cc: 'CRC', symbol: '\u20a1', name: 'Costa Rican colon' },
            { cc: 'CUC', symbol: '$', name: 'Cuban peso' },
            { cc: 'CVE', symbol: 'Esc', name: 'Cape Verdean escudo' },
            { cc: 'CZK', symbol: 'K\u010d', name: 'Czech koruna' },
            { cc: 'DJF', symbol: 'Fdj', name: 'Djiboutian franc' },
            { cc: 'DKK', symbol: 'Kr', name: 'Danish krone' },
            { cc: 'DOP', symbol: 'RD$', name: 'Dominican peso' },
            { cc: 'DZD', symbol: '\u062f.\u062c', name: 'Algerian dinar' },
            { cc: 'EEK', symbol: 'KR', name: 'Estonian kroon' },
            { cc: 'EGP', symbol: '\u00a3', name: 'Egyptian pound' },
            { cc: 'ERN', symbol: 'Nfa', name: 'Eritrean nakfa' },
            { cc: 'ETB', symbol: 'Br', name: 'Ethiopian birr' },
            { cc: 'EUR', symbol: '\u20ac', name: 'European Euro' },
            { cc: 'FJD', symbol: 'FJ$', name: 'Fijian dollar' },
            { cc: 'FKP', symbol: '\u00a3', name: 'Falkland Islands pound' },
            { cc: 'GBP', symbol: '\u00a3', name: 'British pound' },
            { cc: 'GEL', symbol: 'GEL', name: 'Georgian lari' },
            { cc: 'GHS', symbol: 'GH\u20b5', name: 'Ghanaian cedi' },
            { cc: 'GIP', symbol: '\u00a3', name: 'Gibraltar pound' },
            { cc: 'GMD', symbol: 'D', name: 'Gambian dalasi' },
            { cc: 'GNF', symbol: 'FG', name: 'Guinean franc' },
            { cc: 'GQE', symbol: 'CFA', name: 'Central African CFA franc' },
            { cc: 'GTQ', symbol: 'Q', name: 'Guatemalan quetzal' },
            { cc: 'GYD', symbol: 'GY$', name: 'Guyanese dollar' },
            { cc: 'HKD', symbol: 'HK$', name: 'Hong Kong dollar' },
            { cc: 'HNL', symbol: 'L', name: 'Honduran lempira' },
            { cc: 'HRK', symbol: 'kn', name: 'Croatian kuna' },
            { cc: 'HTG', symbol: 'G', name: 'Haitian gourde' },
            { cc: 'HUF', symbol: 'Ft', name: 'Hungarian forint' },
            { cc: 'IDR', symbol: 'Rp', name: 'Indonesian rupiah' },
            { cc: 'ILS', symbol: '\u20aa', name: 'Israeli new sheqel' },
            { cc: 'INR', symbol: '\u20B9', name: 'Indian rupee' },
            { cc: 'IQD', symbol: '\u062f.\u0639', name: 'Iraqi dinar' },
            { cc: 'IRR', symbol: 'IRR', name: 'Iranian rial' },
            { cc: 'ISK', symbol: 'kr', name: 'Icelandic kr\u00f3na' },
            { cc: 'JMD', symbol: 'J$', name: 'Jamaican dollar' },
            { cc: 'JOD', symbol: 'JOD', name: 'Jordanian dinar' },
            { cc: 'JPY', symbol: '\u00a5', name: 'Japanese yen' },
            { cc: 'KES', symbol: 'KSh', name: 'Kenyan shilling' },
            { cc: 'KGS', symbol: '\u0441\u043e\u043c', name: 'Kyrgyzstani som' },
            { cc: 'KHR', symbol: '\u17db', name: 'Cambodian riel' },
            { cc: 'KMF', symbol: 'KMF', name: 'Comorian franc' },
            { cc: 'KPW', symbol: 'W', name: 'North Korean won' },
            { cc: 'KRW', symbol: 'W', name: 'South Korean won' },
            { cc: 'KWD', symbol: 'KWD', name: 'Kuwaiti dinar' },
            { cc: 'KYD', symbol: 'KY$', name: 'Cayman Islands dollar' },
            { cc: 'KZT', symbol: 'T', name: 'Kazakhstani tenge' },
            { cc: 'LAK', symbol: 'KN', name: 'Lao kip' },
            { cc: 'LBP', symbol: '\u00a3', name: 'Lebanese lira' },
            { cc: 'LKR', symbol: 'Rs', name: 'Sri Lankan rupee' },
            { cc: 'LRD', symbol: 'L$', name: 'Liberian dollar' },
            { cc: 'LSL', symbol: 'M', name: 'Lesotho loti' },
            { cc: 'LTL', symbol: 'Lt', name: 'Lithuanian litas' },
            { cc: 'LVL', symbol: 'Ls', name: 'Latvian lats' },
            { cc: 'LYD', symbol: 'LD', name: 'Libyan dinar' },
            { cc: 'MAD', symbol: 'MAD', name: 'Moroccan dirham' },
            { cc: 'MDL', symbol: 'MDL', name: 'Moldovan leu' },
            { cc: 'MGA', symbol: 'FMG', name: 'Malagasy ariary' },
            { cc: 'MKD', symbol: 'MKD', name: 'Macedonian denar' },
            { cc: 'MMK', symbol: 'K', name: 'Myanma kyat' },
            { cc: 'MNT', symbol: '\u20ae', name: 'Mongolian tugrik' },
            { cc: 'MOP', symbol: 'P', name: 'Macanese pataca' },
            { cc: 'MRO', symbol: 'UM', name: 'Mauritanian ouguiya' },
            { cc: 'MUR', symbol: 'Rs', name: 'Mauritian rupee' },
            { cc: 'MVR', symbol: 'Rf', name: 'Maldivian rufiyaa' },
            { cc: 'MWK', symbol: 'MK', name: 'Malawian kwacha' },
            { cc: 'MXN', symbol: '$', name: 'Mexican peso' },
            { cc: 'MYR', symbol: 'RM', name: 'Malaysian ringgit' },
            { cc: 'MZM', symbol: 'MTn', name: 'Mozambican metical' },
            { cc: 'NAD', symbol: 'N$', name: 'Namibian dollar' },
            { cc: 'NGN', symbol: '\u20a6', name: 'Nigerian naira' },
            { cc: 'NIO', symbol: 'C$', name: 'Nicaraguan c\u00f3rdoba' },
            { cc: 'NOK', symbol: 'kr', name: 'Norwegian krone' },
            { cc: 'NPR', symbol: 'NRs', name: 'Nepalese rupee' },
            { cc: 'NZD', symbol: 'NZ$', name: 'New Zealand dollar' },
            { cc: 'OMR', symbol: 'OMR', name: 'Omani rial' },
            { cc: 'PAB', symbol: 'B./', name: 'Panamanian balboa' },
            { cc: 'PEN', symbol: 'S/.', name: 'Peruvian nuevo sol' },
            { cc: 'PGK', symbol: 'K', name: 'Papua New Guinean kina' },
            { cc: 'PHP', symbol: '\u20b1', name: 'Philippine peso' },
            { cc: 'PKR', symbol: 'Rs.', name: 'Pakistani rupee' },
            { cc: 'PLN', symbol: 'z\u0142', name: 'Polish zloty' },
            { cc: 'PYG', symbol: '\u20b2', name: 'Paraguayan guarani' },
            { cc: 'QAR', symbol: 'QR', name: 'Qatari riyal' },
            { cc: 'RON', symbol: 'L', name: 'Romanian leu' },
            { cc: 'RSD', symbol: 'din.', name: 'Serbian dinar' },
            { cc: 'RUB', symbol: 'R', name: 'Russian ruble' },
            { cc: 'SAR', symbol: 'SR', name: 'Saudi riyal' },
            { cc: 'SBD', symbol: 'SI$', name: 'Solomon Islands dollar' },
            { cc: 'SCR', symbol: 'SR', name: 'Seychellois rupee' },
            { cc: 'SDG', symbol: 'SDG', name: 'Sudanese pound' },
            { cc: 'SEK', symbol: 'kr', name: 'Swedish krona' },
            { cc: 'SGD', symbol: 'S$', name: 'Singapore dollar' },
            { cc: 'SHP', symbol: '\u00a3', name: 'Saint Helena pound' },
            { cc: 'SLL', symbol: 'Le', name: 'Sierra Leonean leone' },
            { cc: 'SOS', symbol: 'Sh.', name: 'Somali shilling' },
            { cc: 'SRD', symbol: '$', name: 'Surinamese dollar' },
            { cc: 'SYP', symbol: 'LS', name: 'Syrian pound' },
            { cc: 'SZL', symbol: 'E', name: 'Swazi lilangeni' },
            { cc: 'THB', symbol: '\u0e3f', name: 'Thai baht' },
            { cc: 'TJS', symbol: 'TJS', name: 'Tajikistani somoni' },
            { cc: 'TMT', symbol: 'm', name: 'Turkmen manat' },
            { cc: 'TND', symbol: 'DT', name: 'Tunisian dinar' },
            { cc: 'TRY', symbol: 'TRY', name: 'Turkish new lira' },
            { cc: 'TTD', symbol: 'TT$', name: 'Trinidad and Tobago dollar' },
            { cc: 'TWD', symbol: 'NT$', name: 'New Taiwan dollar' },
            { cc: 'TZS', symbol: 'TZS', name: 'Tanzanian shilling' },
            { cc: 'UAH', symbol: 'UAH', name: 'Ukrainian hryvnia' },
            { cc: 'UGX', symbol: 'USh', name: 'Ugandan shilling' },
            { cc: 'UYU', symbol: '$U', name: 'Uruguayan peso' },
            { cc: 'UZS', symbol: 'UZS', name: 'Uzbekistani som' },
            { cc: 'VEB', symbol: 'Bs', name: 'Venezuelan bolivar' },
            { cc: 'VND', symbol: '\u20ab', name: 'Vietnamese dong' },
            { cc: 'VUV', symbol: 'VT', name: 'Vanuatu vatu' },
            { cc: 'WST', symbol: 'WS$', name: 'Samoan tala' },
            { cc: 'XAF', symbol: 'CFA', name: 'Central African CFA franc' },
            { cc: 'XCD', symbol: 'EC$', name: 'East Caribbean dollar' },
            { cc: 'XDR', symbol: 'SDR', name: 'Special Drawing Rights' },
            { cc: 'XOF', symbol: 'CFA', name: 'West African CFA franc' },
            { cc: 'XPF', symbol: 'F', name: 'CFP franc' },
            { cc: 'YER', symbol: 'YER', name: 'Yemeni rial' },
            { cc: 'ZAR', symbol: 'R', name: 'South African rand' },
            { cc: 'ZMK', symbol: 'ZK', name: 'Zambian kwacha' },
            { cc: 'ZWR', symbol: 'Z$', name: 'Zimbabwean dollar' },
        ];
    }

    public async getWireConfiguration() {
        const response = await this.wireCompaniesClient.wireCompaniesWireConfiguration({
            maxAgeInSeconds: 600,
        });
        return response.data;
    }

    public async getTemplate(id: number) {
        const response = await this.wiresClient.wiresGetTemplate(id);
        return new WireTemplate(response.data);
    }

    public async searchTemplates(query: WireTemplateQueryDto) {
        const dtos = (await searchTemplates(query)) || [];
        const wireTemplate = dtos.map(dto => new WireTemplate(dto));
        return wireTemplate.map(template => {
            template.status = template.isPendingEdit ? 'Pending Edits' : template.status;
            return template;
        });
    }

    public async downloadWires(
        outputType: DownloadFormat,
        page: string,
        filterModel: Omit<WireSearchCriteriaModelDto, 'updatedDate'>
    ) {
        const fileSuffix = new FiDate(new Date()).toIsoDate();
        const downloadFormats: DownloadFormat[] = [
            'Summary - PDF',
            'Detail - PDF',
            'Summary - CSV',
            'Detail - CSV',
        ];
        return DownloadService.download<WireSearchCriteria>(
            fileSuffix,
            outputType,
            page,
            { ...filterModel, page },
            downloadFormats
        );
    }

    // FIXME: This has no corollary in the CompanyAccounts client
    public async fetchTransferAccounts() {
        return (await fetchTransferAccounts()) as CompanyAccountModelDto[];
    }

    public async fetchWireTemplateApprovers(template: WireTemplate) {
        await fetchWireTemplateApprovers(template);
    }

    public async fetchWireActivity(wireSearchCriteria: WireSearchCriteriaModelDto, type: string) {
        const response = await this.wiresClient.wiresSearch({ type }, wireSearchCriteria);
        return response.data;
    }

    public async fetchWireActivityWithTotals(
        wireSearchCriteria: WireSearchCriteriaModelDto,
        query = { type: 'One Time' }
    ) {
        return this.wiresClient.wiresSearchWithTotals(query, wireSearchCriteria);
    }

    public async fetchOneTimeWires(
        wireSearchCriteria: WireSearchCriteriaModelDto,
        query = { type: 'One Time' }
    ) {
        return this.wiresClient.wiresSearch(query, wireSearchCriteria);
    }

    public async fetchRecurringWires(
        wireSearchCriteria: WireSearchCriteriaModelDto,
        query = { type: 'Recurring' }
    ) {
        return this.wiresClient.wiresSearch(query, wireSearchCriteria);
    }

    public async search(criteria: WireSearchCriteria, type: 'One Time' | 'Recurring') {
        const response = await this.wiresClient.wiresSearch(
            { type },
            criteria as WireSearchCriteriaModelDto
        );
        return response.data.map(wireDto => new Wire(wireDto));
    }

    public async searchWithTotals(criteria: WireSearchCriteria, type: 'One Time' | 'Recurring') {
        const response = await this.wiresClient.wiresSearchWithTotals(
            { type },
            criteria as WireSearchCriteriaModelDto
        );
        const data = response.data.searchResults?.map(wireDto => new Wire(wireDto));
        const summary = response.data.totals?.map(summaryDto => ({
            title: summaryDto.status ?? '',
            count: summaryDto.count,
            totalAmount: summaryDto.totalAmount,
        }));
        return { data: data ?? [], summary, totalCount: data?.length ?? 0 };
    }

    public async deleteWirePayment(deleteRequest: CancelWireModelDto) {
        return this.wiresClient.wiresDelete(deleteRequest);
    }

    public async batchApproveOrReject(batch: LookupListModelDto, type: string) {
        const actionType = type === 'approve' ? 'Approve WIRE Payment' : 'Reject WIRE Payment';
        // API Expects first letter of type to be capitalized
        const capitalizedType = type.charAt(0).toUpperCase() + type.slice(1);
        const securityMessage: SecurityMessageModelDto = {
            actionType,
            hasAlternate: false,
            challengeMethodTypeId: 0,
        };

        const p = this.wiresClient.wiresBatch(
            0,
            { action: capitalizedType },
            { ...batch, securityMessage }
        );

        if (type === 'approve') {
            p.then(() => {
                const { lookups: wires } = batch;
                wires?.forEach(({ id }) => {
                    this.analytics.track(AnalyticsEvent.WireApproved, {
                        id: id.toString(),
                    });
                });
            });
        }

        return p;
    }

    public static frequencyTypeToFriendly(frequencyType: string | null) {
        if (frequencyType == null) {
            return 'Undefined';
        }

        switch (frequencyType) {
            case 'EveryTwoWeeks':
                return 'Every Two Weeks';
            case 'TwiceAMonth':
                return 'Twice a Month';
            case 'EverySixMonths':
                return 'Every Six Months';
            case 'OneTime':
                return 'One Time';
            default:
                return frequencyType;
        }
    }

    /** FIXME:
     * There's an extra space in all but the OneTime case
     * The possibility of startOn defaulting to '' is just throwing an error, should there be a guard
     * The default case is just returning a '.' should it instead return undefined or ''
     */
    public static summarizeFrequency(frequency: FrequencyModelDto) {
        switch (this.frequencyTypeToFriendly(frequency.type)) {
            case 'One Time':
                return 'One Time.';
            case 'Weekly':
                return `Occurs  every ${frequency.repeatOn} ${getStartAndEnd(frequency)}.`;
            case 'Every Two Weeks':
                return `Occurs  on ${frequency.repeatOn} every two weeks ${getStartAndEnd(frequency)}.`;
            case 'Twice a Month':
                return `Occurs  on ${getDaysOfMonth(frequency, true)} every month ${getStartAndEnd(frequency)}.`;
            case 'Monthly':
                return `Occurs  on ${getDaysOfMonth(frequency)} of every month ${getStartAndEnd(frequency)}.`;
            case 'Quarterly':
                return `Occurs  on the ${getDayForADate(frequency.startOn || '')} every three months ${getStartAndEnd(frequency)}.`;
            case 'Every Six Months':
                return `Occurs  on the ${getDayForADate(frequency.startOn || '')} every six months ${getStartAndEnd(frequency)}.`;
            case 'Yearly':
                return `Occurs  on ${getDayForAnnual(frequency.startOn || '')} every year ${getStartAndEnd(frequency)}.`;
            default:
                return '.';
        }
    }
}
