import { filterGrid } from "../../../filters/gridFilterHelper.js";

ResearchTransactionController.$inject = [
    '$scope',
    '$location',
    'companyAccountsService',
    'researchTransactionsService',
    'downloadPageId',
    '$anchorScroll',
    'tranGroupCodeService',
    'DatePeriod',
    'moment',
    'dateFilterService',
    '$q',
    '$filter',
    'entitlementsService',
];

export default function ResearchTransactionController(
    $scope,
    $location,
    companyAccountsService,
    researchTransactionsService,
    downloadPageId,
    $anchorScroll,
    tranGroupCodeService,
    DatePeriod,
    moment,
    dateFilterService,
    $q,
    $filter,
    entitlementsService
) {
    const maxRecordCount = 500;
    $scope.displayTransactionCodeDescriptionInUI = entitlementsService.hasEntitlement(
        'Feature.Report.DisplayTransactionCodeDescriptionInUI'
    );
    $scope.downloadPageId = downloadPageId.ResearchTransactionsList;
    $scope.accountList = [];
    $scope.amountTypeList = [];
    $scope.dateTypes = [];
    $scope.filterObject = [];
    $scope.isCollapsed = false;
    $scope.isFilterFormValid = false;
    $scope.message = '';
    $scope.orderByField = null;
    $scope.paymentTypeList = [];
    $scope.referenceNumberTypeList = [];
    $scope.reverseSort = false;
    $scope.transactionList = [];
    $scope.isLoaded = false;
    $scope.exportList = ['CSV', 'PDF', 'TIFF', 'Word'];
    $scope.closeDetails = closeDetails;
    $scope.isWidthSmall = isWidthSmall;
    $scope.openDetails = openDetails;
    $scope.reset = reset;
    $scope.search = search;
    $scope.setForm = setForm;
    $scope.sort = sort;
    $scope.isNegative = isNegative;
    $scope.$watch('sidebarContainerController', checkExpandFilter);
    $scope.accountTypeList = [];
    $scope.loanSelected = false;
    $scope.isDateRangeSelected = isDateRangeSelected;
    $scope.isSpecificDateSelected = isSpecificDateSelected;
    $scope.disableDownload = true;
    $scope.filterSearch = filterSearch;
    $scope.filterText = '';
    $scope.filteredList = [];

    init();

    function isDateRangeSelected() {
        return getTransactionDateType() === DatePeriod.DATE_RANGE;
    }

    function isSpecificDateSelected() {
        return getTransactionDateType() === DatePeriod.SPECIFIC_DATE;
    }

    function getTransactionDateType() {
        if ($scope.filterObject != null) {
            return $scope.filterObject.transactionDateType;
        }

        return null;
    }

    function isNegative(value) {
        if (value !== null && value !== undefined && (value[0] === '-' || value < 0)) {
            return true;
        }
        return false;
    }
    function checkExpandFilter() {
        if ($scope.sidebarContainerController) {
            $scope.sidebarContainerController.setCollapsed(false);
        }
    }

    $scope.hasInputValue = function () {
        const dateType = getTransactionDateType();

        if (dateType !== DatePeriod.DATE_RANGE && dateType !== DatePeriod.SPECIFIC_DATE) {
            return true;
        }

        return (
            $scope.filterObject.transactionDateFrom != null ||
            $scope.filterObject.amountFrom != null ||
            $scope.filterObject.referenceNumberFrom != null ||
            ($scope.filterObject.paymentTypesDisplay &&
                $scope.filterObject.paymentTypesDisplay.length > 0)
        );
    };

    $scope.scrollLocation = function (element) {
        $location.hash(element);
        $anchorScroll();
    };

    $scope.formatDate = function (column) {
        if (
            $scope.filterObject[column] !== null &&
            $scope.filterObject[column] !== undefined &&
            $scope.filterObject[column].length > 0
        ) {
            $scope.filterObject[column] = formatDate($scope.filterObject[column]);
        }
    };

    function formatDate(dt) {
        return moment(new Date(dt)).format('MM/DD/YYYY');
    }

    $scope.resetValues = function (type) {
        if (type === 'transactionDate') {
            $scope.filterObject.transactionDateFrom = null;
            $scope.filterObject.transactionDateTo = null;
        }
        if (type === 'check') {
            $scope.filterObject.referenceNumberFrom = null;
            $scope.filterObject.referenceNumberTo = null;
        }
        if (type === 'amount') {
            $scope.filterObject.amountFrom = null;
            $scope.filterObject.amountTo = null;
        }
    };

    function setDefaultSort() {
        $scope.orderByField = 'date';
        $scope.reverseSort = true;
    }

    function loadAccountTypeList() {
        $scope.accountTypeList = [
            {
                name: 'Checking',
                isChecked: true,
            },
            {
                name: 'Savings',
                isChecked: true,
            },
            {
                name: 'Loan',
                isChecked: false,
            },
        ];
    }

    function init() {
        initFilterObject();
        initDateTypes();
        initAmountTypeList();
        initReferenceNumberTypeList();
        resetFilterToDefaults();
        setDefaultSort();
        $scope.orderByField = 'date';
        $scope.reverseSort = true;
        $scope.currentDate = moment().format('MM/DD/YYYY');
        $scope.message =
            'Find specific transactions or groups of transactions by entering your search criteria in the filters, then click run report.';

        $scope.filterObject.transactionDateType = dateFilterService.getDefaultDateType();

        $q.all([initPaymentTypeList(), loadAccountsList()]).then(() => {
            $scope.filterObject.accountTypes = $filter('filter')($scope.accountTypeList, {
                isChecked: true,
            });
            $scope.filterObject.accounts = $filter('filter')($scope.accountList, {
                type: '!Loan',
            });
            search();
        });
    }

    $scope.isLoanSelected = function () {
        $scope.loanSelected =
            $scope.filterObject.accountTypes &&
            $scope.filterObject.accountTypes.reduce(
                (acc, val) => (val.name === 'Loan' && val.isChecked) || acc,
                false
            );
        return $scope.loanSelected;
    };

    $scope.$watch(
        'filterObject.accountTypes',
        newValue => {
            if (
                newValue !== null &&
                newValue !== undefined &&
                $scope.filterObject.accountTypes instanceof Array === true
            ) {
                $scope.$broadcast('accountTypes', newValue);
            }
        },
        true
    );

    function initFilterObject() {
        $scope.filterObject = {
            transactionDateOperator: null,
            transactionDateFrom: null,
            transactionDateTo: null,
            transactionId: null,
            referenceNumberOperator: null,
            referenceNumberFrom: null,
            referenceNumberTo: null,
            amountOperator: null,
            amountFrom: null,
            amountTo: null,
            paymentDirection: null,
            paymentTypes: null,
            accounts: null,
            accountTypes: null,
            maxRecordCount: maxRecordCount + 1, // adding one so we know if the results are equal to or greater than the limit
        };
    }

    function initDateTypes() {
        $scope.dateTypes = dateFilterService.getDefaultDateTypes();
    }

    function initReferenceNumberTypeList() {
        $scope.referenceNumberTypeList = [
            {
                name: 'Specific #',
            },
            {
                name: 'Range',
            },
        ];
    }

    function initAmountTypeList() {
        $scope.amountTypeList = [
            {
                name: 'Specific Amount',
            },
            {
                name: 'Range',
            },
        ];
    }

    function initPaymentTypeList() {
        return tranGroupCodeService.get().then(response => {
            $scope.paymentTypeList = response;
            angular.forEach($scope.paymentTypeList, paymentType => {
                paymentType.isChecked = false;
            });
        });
    }

    function loadAccountsList() {
        return companyAccountsService
            .getCompanyAccountsWithProductFeature(null, 'All')
            .then(response => {
                if (
                    response.length > 0 &&
                    response[0] !== null &&
                    typeof response[0] !== 'undefined'
                ) {
                    $scope.accountDisplayField = response[0].showAccountNickName
                        ? 'name'
                        : 'number';
                    $scope.transactionAccountDisplayField = response[0].showAccountNickName
                        ? 'accountName'
                        : 'accountNumber';
                }
                $scope.accountList = response;
                resetFilterAccountsToDefaults();
                loadAccountTypeList();
            });
    }

    function loadTransactionsList() {
        if ($scope.isLoaded === false) {
            $scope.isLoaded = true;
        }

        $scope.filterObject.paymentTypes = [];
        if (!$scope.loanSelected) {
            angular.forEach($scope.filterObject.paymentTypesDisplay, paymentType => {
                if (paymentType.isChecked)
                    $scope.filterObject.paymentTypes.push({ type: paymentType.name });
            });
        }

        const period = dateFilterService.getPeriodDates(
            $scope.filterObject.transactionDateType,
            $scope.filterObject.transactionDateFrom,
            $scope.filterObject.transactionDateTo
        );

        $scope.filterObject.transactionDateOperator = $scope.filterObject.transactionDateType;
        $scope.filterObject.transactionDateFrom = period.formattedFromDate;
        $scope.filterObject.transactionDateTo = period.formattedToDate;

        return researchTransactionsService
            .getResearchTransactionList($scope.filterObject)
            .then(response => {
                $scope.transactionList = response;
                $scope.filteredList = $scope.transactionList;
                // Combine the reference and check number for sorting.
                angular.forEach($scope.transactionList, transaction => {
                    if (
                        transaction.checkImageNumber !== null &&
                        transaction.checkImageNumber !== undefined
                    ) {
                        transaction.checkImageNumber = parseFloat(transaction.checkImageNumber);
                    }
                    if (transaction.checkNumber !== null && transaction.checkNumber !== undefined) {
                        transaction.checkNumber = parseFloat(transaction.checkNumber);
                    }
                    transaction.referenceOrCheckNumber =
                        transaction.checkImageNumber || transaction.checkNumber;
                    transaction.account =
                        $scope.transactionAccountDisplayField == 'accountName'
                            ? transaction.accountName
                            : transaction.accountNumber;
                });

                if ($scope.transactionList.length === 0) {
                    $scope.disableDownload = true;
                } else {
                    $scope.disableDownload = false;
                }

                checkForTransactionListIssues();
            });
    }

    function resetFilterToDefaults() {
        $scope.filterObject.transactionDateOperator = dateFilterService.getDefaultDateType();
        $scope.filterObject.transactionDateFrom = null;
        $scope.filterObject.transactionDateTo = null;

        // Accounts
        resetFilterAccountsToDefaults(); // Separated so that it can be called whenever accounts are loaded.

        // Transaction ID
        $scope.filterObject.transactionId = null;

        // Reference number
        $scope.filterObject.referenceNumberOperator = $scope.referenceNumberTypeList[0].name;
        $scope.filterObject.referenceNumberFrom = null;
        $scope.filterObject.referenceNumberTo = null;

        // Amount
        $scope.filterObject.amountOperator = $scope.amountTypeList[0].name;
        $scope.filterObject.amountFrom = null;
        $scope.filterObject.amountTo = null;

        // Payment direction
        $scope.filterObject.paymentDirection = 'Both';
    }

    function resetFilterAccountsToDefaults() {
        angular.forEach($scope.accountList, companyAccount => {
            companyAccount.isChecked = true;
        });
    }

    function checkForTransactionListIssues() {
        $scope.message = '';
        $scope.dataWarning = '';
        if ($scope.transactionList.length > maxRecordCount) {
            $scope.transactionList = $scope.transactionList.slice(0, maxRecordCount);
            $scope.dataWarning = `More than ${maxRecordCount} records were found. To narrow down the results, use the filters to refine your search criteria.`;
        } else if (
            $scope.transactionList === null ||
            $scope.transactionList === undefined ||
            $scope.transactionList.length === 0
        ) {
            $scope.message = 'No Records Found.';
            $scope.sidebarContainerController.setCollapsed(false);
        } else {
            $scope.sidebarContainerController.setCollapsed(true);
        }
    }

    function closeDetails(transaction) {
        transaction.isShowingDetails = false;
    }

    function isWidthSmall(widthValue) {
        return widthValue;
    }

    function openDetails(transaction) {
        transaction.isShowingDetails = true;
    }

    function reset() {
        resetFilterToDefaults();
        loadAccountsList();
    }

    function search() {
        loadTransactionsList();
        setDefaultSort();
    }

    // Used to set the form instance.
    function setForm(form) {
        $scope.form = form;
    }

    function sort(orderByField) {
        if (orderByField === $scope.orderByField) {
            $scope.reverseSort = !$scope.reverseSort;
        } else {
            $scope.reverseSort = false;
        }
        $scope.orderByField = orderByField;
    }

    function filterSearch(filterText) {
        const transactionList = $scope.transactionList;
        $scope.filterText = filterText;
        filterText = filterText || '';
        $scope.filteredList = $filter('filter')(
            transactionList,
            filterGrid(filterText, [
                'date',
                'accountName',
                'checkNumber',
                'tranCodeDescription',
                'description',
                'amount',
            ])
        );
    }
}
