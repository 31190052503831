import paymentCanBeCancelled from '@treasury/domain/shared/utilities/payment-can-be-cancelled.js';
import userHasEntitlement from '@treasury/domain/shared/utilities/user-has-entitlement.js';
import {
    amountRange,
    boolean,
    date,
    datePicker,
    list,
    money,
    number,
    string,
} from '@treasury/policy/primitives';
import { html } from 'lit';
import '../../../components/channel-status-badge.js';
import '../../../components/field-with-tooltip-icon.js';
import '../../../components/frequency-detail.js';

const canCancelAchPayment = (record, entitlements) => {
    const status = record.getField('status');
    const isInternational = record.getField('transactionId')?.slice(-2) === '-I';
    if (!paymentCanBeCancelled(status)) return false;
    return isInternational
        ? userHasEntitlement(entitlements, 'Delete International ACH Payment')
        : userHasEntitlement(entitlements, 'Delete Ach Payment');
};

const canUninitiateAchPayment = record => {
    const status = record.getField('status');
    const userCanDelete = record
        .getField('permissions')
        .some(e => e.permission === 'DeletePayment');

    return status === 'Initiated' && userCanDelete;
};

const isPaymentProcessPending = record => {
    const status = record.getField('status');
    return status === 'Pending Process';
};

export const printColumns = [
    {
        label: 'Transaction ID',
        field: 'transactionId',
        type: 'command',
        action: 'clickTransactionId',
    },
    {
        label: 'Batch Name',
        field: 'name',
    },
    {
        label: 'File Name',
        field: 'fileName',
        type: 'command',
        action: 'clickFileName',
    },
    {
        label: 'ACH Company Name',
        field: 'achCompanyName',
    },
    {
        label: 'SEC Code',
        field: 'secCode',
    },
    {
        label: 'Initiated Date',
        field: 'initiatedDate',
    },
    {
        label: 'Effective Date',
        field: 'effectiveDate',
    },
    {
        label: 'Debit Amount',
        field: 'debitAmount',
        summary: true,
    },
    {
        label: 'Credit Amount',
        field: 'creditAmount',
        summary: true,
    },
    {
        field: 'status',
        label: 'Status',
    },
];

export const columns = entitlements => [
    {
        type: 'checkbox',
        field: 'selected',
    },
    {
        label: 'Transaction ID',
        field: 'transactionId',
        type: 'command',
        action: 'clickTransactionId',
    },
    {
        label: 'Batch Name',
        field: 'name',
    },
    {
        label: 'File Name',
        field: 'fileName',
        type: 'command',
        action: 'clickFileName',
    },
    {
        label: 'ACH Company Name',
        field: 'achCompanyName',
    },
    {
        label: 'SEC Code',
        field: 'secCode',
    },
    {
        label: 'Initiated Date',
        field: 'initiatedDate',
    },
    {
        label: 'Effective Date',
        field: 'effectiveDate',
    },
    {
        label: 'Debit Amount',
        field: 'debitAmount',
        summary: true,
    },
    {
        label: 'Credit Amount',
        field: 'creditAmount',
        summary: true,
    },
    {
        field: 'status',
        label: 'Status',
    },
    {
        type: 'actions',
        actions: [
            {
                label: 'Cancel',
                action: 'cancel',
                visibleWhen: record =>
                    canCancelAchPayment(record, entitlements) && !isPaymentProcessPending(record),
            },
            {
                label: 'Uninitiate',
                action: 'uninitiate',
                visibleWhen: record =>
                    canUninitiateAchPayment(record) && !isPaymentProcessPending(record),
            },
        ],
        label: 'Actions',
    },
];

export const fields = client => ({
    selected: boolean.thatIs
        .disabledWhen(
            record =>
                record.getField('status') !== 'Pending Approval' ||
                !record.getField('permissions').some(e => e.permission === 'Approve')
        )
        .as.tag('omega-checkbox')
        .thatHas.maxColumnWidth(50),
    transactionId: string.thatIs
        .readOnly()
        .with.label('Transaction ID')
        .with.template((transactionId, record) => {
            const component = html`<field-with-tooltip-icon
                .displayString=${transactionId}
                .messageType=${record.values.messageType}
                .message=${record.values.message}
                .isEdited=${record.values.isEdited}
                recordType="payment"
                style="white-space: nowrap"
            ></field-with-tooltip-icon>`;
            component.toString = () => transactionId;
            return component;
        })
        .thatHas.maxColumnWidth(100),
    name: string.thatIs.readOnly(),
    fileName: string.thatIs.readOnly(),
    achCompanyName: string.thatIs.readOnly(),
    secCode: string.thatIs.readOnly().thatHas.maxColumnWidth(60),
    initiatedDate: date.thatIs.readOnly(),
    effectiveDate: date.thatIs.readOnly().with.template((effectiveDate, record) => {
        const component = html`<frequency-detail
            .displayDate=${effectiveDate}
            .record=${record}
        ></frequency-detail>`;
        component.toString = () => effectiveDate;
        return component;
    }),
    debitAmount: money.with
        .reducer('sum')
        .thatIs.readOnly()
        .and.maxColumnWidth(null)
        .and.targetColumnWidth(null),
    creditAmount: money.with
        .reducer('sum')
        .thatIs.readOnly()
        .and.maxColumnWidth(null)
        .and.targetColumnWidth(null),
    status: string.thatIs.readOnly().with.template((status, record) => {
        const component = html`<channel-status-badge
            .fetchApproversFunction=${async () =>
                client.fetchApprovers({
                    approvalEntity: 'achPayment',
                    productId: record.getField('id'),
                    amount: record.getField('amount'),
                    updatedBy: record.getField('updatedBy'),
                })}
            .status=${status}
            .approvalsNeeded=${record.getField('numberOfApprovalsNeeded')}
            .approvalsCount=${record.getField('completedApprovalCount')}
        ></channel-status-badge>`;
        component.toString = () => status;
        return component;
    }),
    id: number.thatIs.readOnly(),
    permissions: list.with.defaultValue([]).thatIs.readOnly(),
    parentFrequencyType: string.thatIs.readOnly().and.visibleWhen(() => false),
});

export const filters = client => [
    {
        field: 'achType',
        fieldType: string.with
            .options({
                data: [
                    { text: 'All', value: 'All' },
                    { text: 'Reversal', value: 'Reversal' },
                    { text: 'Tax Payment', value: 'TaxPayment' },
                    { text: 'Child Support', value: 'ChildSupportPayment' },
                    { text: 'International ACH Payment', value: 'InternationalPayment' },
                ],
            })
            .with.label('ACH Type:')
            .as.tag('omega-select'),
        value: 'All',
    },
    {
        field: 'status',
        fieldType: string.with
            .options({
                data: [
                    { text: 'All', value: 'All' },
                    { text: 'Pending Approval', value: 'Pending Approval' },
                    { text: 'Pending Process', value: 'Pending Process' },
                    { text: 'Approval Rejected', value: 'Approval Rejected' },
                    { text: 'Initiated', value: 'Initiated' },
                    { text: 'UnInitiated', value: 'UnInitiated' },
                    { text: 'Cancelled', value: 'Cancelled' },
                    { text: 'Failed', value: 'Failed' },
                    { text: 'Expired', value: 'Expired' },
                    { text: 'Scheduled', value: 'Scheduled' },
                ],
            })
            .with.label('Status:')
            .as.tag('omega-select'),
        value: 'All',
    },
    {
        field: 'achCompanyList',
        fieldType: string.with
            .label('ACH Company Name:')
            .and.multipleValues()
            .with.hashFunction(company => company.id)
            .and.options({
                fetch: client.getAchCompanies,
                text: 'companyName',
                value: record => record,
            })
            .and.filtering()
            .as.tag('omega-select'),
    },
    {
        field: 'batchName',
        fieldType: string.with.label('Batch Name:').as.tag('omega-input'),
        value: '',
    },
    {
        field: 'transactionId',
        fieldType: string.with.label('Transaction ID:').as.tag('omega-input'),
        value: '',
    },
    {
        field: 'secCode',
        fieldType: string.with
            .label('SEC Code:')
            .with.options({
                fetch: client.getSecCodes,
                text: record => record,
                value: record => record,
            })
            .as.tag('omega-select'),
        value: 'All',
    },
    {
        field: 'debitAmount',
        fieldType: amountRange.with.label('Debit Amount:').as.tag('omega-range'),
        value: [],
    },
    {
        field: 'creditAmount',
        fieldType: amountRange.with.label('Credit Amount:').as.tag('omega-range'),
        value: [],
    },
    {
        field: 'initiatedDate',
        fieldType: datePicker.with
            .range()
            .and.label('Initiated Date:')
            .and.defaultValue('')
            .as.tag('omega-datepicker'),
        value: '',
    },
    {
        field: 'effectiveDate',
        fieldType: datePicker.with.range().and.label('Effective Date:').as.tag('omega-datepicker'),
        value: {
            id: 'range',
            isRange: true,
            dates: [
                new Date(new Date().setDate(new Date().getDate() - 7)),
                new Date(new Date().setDate(new Date().getDate() + 7)),
            ],
        },
    },
];
