/* eslint-disable no-use-before-define */
// https://www.w3.org/TR/wai-aria-practices-1.1/examples/combobox/aria1.1pattern/listbox-combo.html
// https://www.w3.org/TR/wai-aria-practices-1.1/examples/listbox/listbox-collapsible.html

import { deepEquals, exists } from '@jack-henry/frontend-utils/functions';
import { FieldType, Recordset } from '@treasury/FDL';
import { LitElement, css, html, nothing } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { v4 as uuid } from 'uuid';
import { fontAwesome } from '../css/icons/font-awesome.js';
import './omega-button.js';
import './progress/omega-progress.js';
import calculateStartIndex from './virtual-scroll/calculate-start-index.js';
let hash;
import('object-hash').then(module => {
    hash = module.default;
});

const PRINTABLE_CHAR_REGEX = /^[A-Za-z0-9]$/;
class OmegaSelect extends LitElement {
    static get properties() {
        return {
            hashFunction: { reflect: false },
            searchConfig: Object,
            items: Array,
            filterText: String,
            hasFilter: Boolean,
            hideSelectAll: Boolean,
            value: Object,
            isExpanded: Boolean,
            itemHighlighted: Boolean,
            activeDescendantIndex: Number,
            typeToSearchBuffer: String,
            placeholder: String,
            disabled: { type: Boolean, reflect: true },
            required: { type: Boolean, reflect: true },
            multiple: { type: Boolean, reflect: true },
            valid: { type: Boolean, reflect: true },
            dirty: Boolean,
            showSearchDialog: Boolean,
            searchItemsRecordset: Object,
            hasSearch: Boolean,
            loading: Boolean,
            maxValueCount: {
                type: Number,
            },
            virtualScrollStartIndex: Number,
            virtualScrollMaxRenderedItemCount: Number,
            id: String,
            _listboxDirection: { state: true },
        };
    }

    constructor() {
        super();
        /** @type {string | undefined} */
        this._value = undefined;
        /** @type {Array} */
        this.items = [];
        this.filterText = '';
        this.activeDescendantIndex = 0;
        this.isExpanded = false;
        this.itemHighlighted = false;
        this.valid = true;
        this.dirty = false;
        this.typeToSearchBuffer = '';
        this.multiple = false;
        this.showSearchDialog = false;
        this.loading = false;
        this.hideSelectAll = false;
        this.virtualScrollStartIndex = 0;
        this.virtualScrollMaxRenderedItemCount = 200;
        this.itemHeight = 34.57;
        this.countOfItemsInViewport = 6;
        /** @type {Record<string, boolean>} */
        this.selectedItems = {};
        this.id = uuid();
        this.placeholder = 'Select';
        this.isMeasuring = false;
        this._listboxDirection = 'down';
        this.hashFunction = this.defaultHashFunction;
        /**
         * Cache for object hashes.
         *
         * Generating a hash takes a non-negligible amount of time and
         * should be  cached since they are used quite frequently for update logic.
         *
         * @type {WeakMap<<object, string>}
         * */
        this._hashMap = new WeakMap();
    }

    static get meta() {
        return {
            docUrl: 'https://banno.github.io/treasury-management/?path=/docs/components-select--single-select',
        };
    }

    connectedCallback() {
        super.connectedCallback();
        window.addEventListener('click', this.handlePossibleExternalClick.bind(this));
    }

    disconnectedCallback() {
        super.disconnectedCallback();
        window.removeEventListener('click', this.handlePossibleExternalClick.bind(this));
    }

    get value() {
        return this._value;
    }

    set value(value) {
        // prevent infinite loops
        if (this._value === value) {
            return;
        }

        const oldValue = this.value;
        this._value = value;
        this._setSelectedItems();
        this.requestUpdate('value', oldValue);
    }

    get visibleItems() {
        const items = this.items || [];
        return this.filterText
            ? items.filter(item => item.text.toLowerCase().includes(this.filterText.toLowerCase()))
            : items;
    }

    get isEveryVisibleItemSelected() {
        if (!this.visibleItems.length) return false;
        return this.visibleItems.every(this.isItemChecked.bind(this));
    }

    get invalid() {
        const valueIsFalsy = this.value === undefined || this.value?.length === 0;
        return this.required && valueIsFalsy && !this.isExpanded;
    }

    get ariaInvalid() {
        const validatable = this.dirty || this.disabled;
        return !this.valid && !this.loading && validatable;
    }

    get focusable() {
        return this.shadowRoot.querySelector('.focusable');
    }

    get listBoxElement() {
        return this.shadowRoot.querySelector('#listbox');
    }

    get showPlaceholder() {
        if (this.multiple) {
            return Array.isArray(this.value) && !this.value.length && this.placeholders;
        }
        return this.placeholder && !this.value;
    }

    /**
     * Create the map of selected items using by using their hashed ID as the key.
     * @private
     */
    _setSelectedItems() {
        if (!Array.isArray(this.value)) {
            return;
        }

        this.selectedItems = this.value.reduce((dict, v) => {
            const itemHash = this.hashFunction(v);
            dict[itemHash] = true;

            return dict;
        }, {});
    }

    /**
     * Creates a hash for a given item in the select list
     * for the purposes of determining its selected state.
     *
     * @param {object | string | number} item
     * @returns {string}
     */
    defaultHashFunction(item) {
        if (!item) {
            return 'undefined';
        }

        if (typeof item !== 'object') {
            return item.toString();
        }

        if (!this._hashMap.has(item)) {
            this._hashMap.set(item, hash(item));
        }

        return this._hashMap.get(item);
    }

    isItemChecked(item) {
        if (this.multiple && Array.isArray(this.value)) {
            return this.selectedItems[this.hashFunction(item.value)] === true;
        }
        return this.hashFunction(item.value) === this.hashFunction(this.value);
    }

    // ISSUE
    toggleItem(item) {
        if (this.multiple) {
            if (this.isItemDisabled(item)) {
                return;
            }
            const otherValues = this.value.filter(
                value => this.hashFunction(value) !== this.hashFunction(item.value)
            );
            const isChecked = this.isItemChecked(item);
            this.value = this.isItemChecked(item) ? otherValues : [...otherValues, item.value];
            this.selectedItems[this.hashFunction(item.value)] = !isChecked;
        } else {
            this.value = item.value;
            this.collapse();
        }
        this.dispatchEvent(new Event('change'));
    }

    toggleActiveItem() {
        if (this.activeDescendantIndex === -1) {
            this.toggleSelectAll();
        } else {
            this.toggleItem(this.visibleItems[this.activeDescendantIndex]);
        }
    }

    toggleSelectAll() {
        if (this.isEveryVisibleItemSelected) {
            this.selectNone();
        } else {
            this.selectAll();
        }
    }

    selectAll() {
        this.value = this.visibleItems.map(item => item.value);
        this.value.forEach(v => {
            this.selectedItems[this.hashFunction(v)] = true;
        });
        this.dispatchEvent(new Event('change'));
    }

    selectNone() {
        this.value = [];
        this.selectedItems = {};
        this.dispatchEvent(new Event('change'));
    }

    hasSelectAll() {
        if (this.hideSelectAll) return false;
        return !!this.multiple && this.visibleItems.length > 1;
    }

    onFilterTextChange(event) {
        this.filterText = event.target.value;
    }

    getHandler(event) {
        const { key, code } = event;
        const handlers = {
            ArrowDown: this.activateNextItem,
            ArrowUp: this.activatePreviousItem,
            Escape: this.collapse,
            Enter: this.toggleActiveItem,
            Tab: this.collapse,
            Home: this.activateFirstItem, // TODO
            End: this.activateLastItem, // TODO
        };
        return handlers[key] ?? handlers[code];
    }

    handleFilterKeyDown(event) {
        const { code } = event;
        if (!this.isExpanded) {
            return;
        }
        const handler = this.getHandler(event);
        if (handler) {
            if (code !== 'Tab' && code !== 'Space') event.preventDefault();
            handler.call(this);
        }
    }

    handleKeyDown(event) {
        const { key, code } = event;
        const handler = this.getHandler(event);
        if (handler) {
            if (code !== 'Tab') event.preventDefault();
            event.stopPropagation();
            handler.call(this);
        }
        if (PRINTABLE_CHAR_REGEX.test(key)) {
            event.preventDefault();
            this.typeToSearch(key);
        }
    }

    typeToSearch(char) {
        clearTimeout(this.typeToSearchResetTimeout);
        this.typeToSearchBuffer += char;

        const indexOfFirstMatch = this.visibleItems.findIndex(item =>
            item.text?.toLowerCase().startsWith(this.typeToSearchBuffer.toLowerCase())
        );
        const indexOfNextMatch = this.visibleItems.findIndex((item, index) => {
            if (indexOfFirstMatch === 0 && !this.isExpanded && !this.itemHighlighted) {
                return indexOfFirstMatch;
            }
            return (
                index > this.activeDescendantIndex &&
                item.text?.toLowerCase().startsWith(this.typeToSearchBuffer.toLowerCase())
            );
        });
        if (indexOfNextMatch >= 0) {
            this.activeDescendantIndex = indexOfNextMatch;
        } else if (indexOfFirstMatch >= 0) {
            this.activeDescendantIndex = indexOfFirstMatch;
            this.itemHighlighted = true;
        }
        if (this.isExpanded) {
            this.scrollItemIntoView(this.activeDescendantIndex);
        } else if (indexOfFirstMatch >= 0) {
            this.toggleActiveItem();
        }

        this.typeToSearchResetTimeout = setTimeout(() => {
            this.typeToSearchBuffer = '';
        }, 500);
    }

    scrollItemIntoView(index) {
        if (this.isExpanded) {
            const searchElement = this.shadowRoot.getElementById(`option-${index}`);
            if (searchElement?.scrollIntoView) {
                searchElement.scrollIntoView({ block: 'nearest' });
            }
        }
    }

    resetFilterText() {
        this.filterText = '';
    }

    activateNextItem() {
        const firstItemIndex = this.hasSelectAll() ? -1 : 0;

        this.activeDescendantIndex += 1;
        if (this.activeDescendantIndex >= this.visibleItems.length) {
            this.activeDescendantIndex = firstItemIndex;
        }
        if (!this.multiple) {
            this.value = [this.visibleItems[this.activeDescendantIndex].value];
        }
        this.scrollItemIntoView(this.activeDescendantIndex);
    }

    activatePreviousItem() {
        const firstItemIndex = this.hasSelectAll() ? -1 : 0;

        this.activeDescendantIndex -= 1;

        if (this.activeDescendantIndex < firstItemIndex) {
            this.activeDescendantIndex = this.visibleItems.length - 1;
        }
        if (!this.multiple) {
            this.value = [this.visibleItems[this.activeDescendantIndex].value];
        }
        this.scrollItemIntoView(this.activeDescendantIndex);
    }

    toggle() {
        if (this.disabled) {
            return;
        }
        if (this.isExpanded) {
            this.collapse();
        } else {
            this.expand();
        }
    }

    expand() {
        this.isMeasuring = true;
        this.isExpanded = true;
    }

    collapse() {
        this.applyDirt();
        this.resetFilterText();
        this.isExpanded = false;
        this.dispatchEvent(new Event('blur'));
    }

    applyDirt() {
        if (!this.dirty) {
            this.dirty = true;
        }
    }

    handlePossibleExternalClick(event) {
        if (
            event &&
            !event.composedPath().find(element => element.id === this.id) &&
            this.isExpanded
        ) {
            this.resetFilterText();
            if (this.listBoxElement && this.listBoxElement.scrollTo) {
                this.listBoxElement.scrollTo(0, 0);
            }
            this.collapse();
        }
    }

    handleSmartPositioning() {
        const listboxWrapper = this.shadowRoot.querySelector('.listbox-wrapper');
        const { left, top, bottom } = listboxWrapper.getBoundingClientRect();
        const topmostElement =
            this.shadowRoot.elementFromPoint?.(
                left + 1,
                this._listboxDirection === 'down' ? bottom - 1 : top + 1
            ) ?? this;
        const listbox = this.shadowRoot.getElementById('listbox');
        const isObscured = topmostElement?.parentElement !== listbox;

        if (isObscured) {
            // down didn't work, so try up and measure again on next tick
            if (this._listboxDirection === 'down') {
                this._listboxDirection = 'up';
            }
            // if neither works, render down to avoid potentially rendering outside the document
            else {
                this._listboxDirection = 'down';
                this.isMeasuring = false;
            }
        }
        // found enough room to render either down or up
        else {
            this.isMeasuring = false;
        }
    }

    highlightSearchedText(text) {
        const matchIndex = text.toLowerCase().indexOf(this.filterText.toLowerCase());
        if (this.filterText && matchIndex >= 0) {
            const start = text.substring(0, matchIndex);
            const middle = text.substr(matchIndex, this.filterText.length);
            const end = text.substring(matchIndex + this.filterText.length);
            return html`${start}<b>${middle}</b>${end}`;
        }
        return text;
    }

    isMultipleSelectWithAllSelected() {
        return this.isEveryVisibleItemSelected && this.multiple && !this.hideSelectAll;
    }

    isMultipleSelectWithNoSelection() {
        if (!this.value) return true;
        return this.multiple && this.value.length === 0;
    }

    itemIsObjectWithId(item) {
        return typeof item.value === 'object' && item.value.id;
    }

    isItemDisabled(item) {
        // if number of selected values is greater than max and
        // item is not selected, return true.
        if (this.multiple) {
            const count = this.visibleItems?.filter(this.isItemChecked.bind(this)).length;
            return !this.isItemChecked(item) && count >= this.maxValueCount;
        }
        return false;
    }

    onScroll(e) {
        const nextStartIndex = calculateStartIndex({
            viewportSize: this.itemHeight * 6,
            itemSize: this.itemHeight,
            scrollSize: e.target.scrollTop,
            maxItemsToRender: this.virtualScrollMaxRenderedItemCount,
            allItemsCount: this.visibleItems.length,
        });

        this.virtualScrollStartIndex = nextStartIndex;
    }

    /**
     * @param {Map<string, any>} changedProps
     */
    updated(changedProps) {
        const oldValue = this.value;
        const hasItems = this.items && this.items.length > 0;
        const hasValue = exists(this.value);
        const valueChanged = changedProps.has('value') && changedProps.get('value') !== this.value;
        const itemsChanged = changedProps.has('items') && changedProps.get('items') !== this.items;
        const hashFnChanged = testHashFn(changedProps, this);

        if (this.isMeasuring) {
            this.handleSmartPositioning();
        }

        // a new value should be calculated if either:
        // * the list of all items changes, or;
        // * the user-supplied value does
        // eslint-disable-next-line @treasury/no-mixed-boolean-operators
        if ((valueChanged && hasItems) || (itemsChanged && hasValue)) {
            const newValue = this.determineValue();

            if (!deepEquals(newValue, oldValue)) {
                this.value = newValue;
            }
        }

        // re-calculate hashes in selected items dict when the hash function changes
        if (hashFnChanged && hasValue) {
            this._setSelectedItems();
        }

        if (!hashFnChanged && this.hashFunction(oldValue) !== this.hashFunction(this.value)) {
            this.dispatchEvent(new Event('change'));
        }

        this.focusable?.focus();
    }

    determineValue() {
        const { items, value, multiple } = this;

        if (value === 'all' && items.length > 0) {
            this.dispatchEvent(new Event('change'));
            return items.map(item => item.value);
        }

        // if a value is set, but items have changed
        // such that the value is no longer valid, prune the invalid values
        if (multiple && Array.isArray(value)) {
            // produce a list of values that only exist in the list of possible items
            return this.value.filter(v => this.hasItem(v));
        }

        if (!multiple) {
            if (items.length === 1) {
                return items[0].value;
            }
            // passed value does not exist in list of possible items
            // should this be an error case?
            if (items.length > 0 && !this.hasItem(this.value)) {
                return undefined;
            }
        }

        return this.value;
    }

    /**
     * @returns `true` if the provided value or equivalent exists within the `items` collection.
     */
    hasItem(v) {
        if (this.items.length < 1) {
            return false;
        }

        const isEqual = (a, b) => this.hashFunction(a) === this.hashFunction(b);

        return this.items.some(i => isEqual(i.value, v));
    }

    getFirstSelectedOptionText() {
        return (
            this.visibleItems.find(
                item => this.hashFunction(item.value) === this.hashFunction(this.value[0])
            )?.text ?? this.placeholder
        );
    }

    getSelectedOptionText() {
        return (
            this.visibleItems.find(
                item => this.hashFunction(item.value) === this.hashFunction(this.value)
            )?.text ?? this.placeholder
        );
    }

    renderLoader(cls) {
        if (this.loading !== true) return nothing;
        return html` <div class="select-item-loader-wrapper">
            <omega-progress class="select-item-loader ${cls}"></omega-progress>
        </div>`;
    }

    renderLabelLoader() {
        return html`<div class="label-loader"><span>Loading...</span> ${this.renderLoader()}</div>`;
    }

    // eslint-disable-next-line class-methods-use-this
    renderListItem(item, index) {
        const id = `option-${index}`;
        const isActive = this.activeDescendantIndex === index;
        // eslint-disable-next-line lit-a11y/role-has-required-aria-attrs
        return html`<li
            id=${id}
            role="option"
            .title=${item.text}
            aria-selected=${this.isItemChecked(item)}
            disabled=${this.isItemDisabled(item)}
            @click=${() => this.toggleItem(item)}
            @keyup=${event => this.onkeyup(event)}
            class=${classMap({ active: isActive })}
        >
            ${this.highlightSearchedText(item.text)}
        </li>`;
    }

    renderListItems() {
        if (!this.isExpanded) {
            return nothing;
        }
        return this.visibleItems
            .slice(
                this.virtualScrollStartIndex,
                this.virtualScrollStartIndex + this.virtualScrollMaxRenderedItemCount
            )
            .map(this.renderListItem.bind(this));
    }

    renderFilter() {
        if (!this.isExpanded) {
            return nothing;
        }
        const placeholder = this.hasSearch ? 'Search...' : `🔍  Search...`; // we already use the 'search' icon on the right when we have the search dialog
        return html`<div>
            <input
                type="text"
                placeholder=${placeholder}
                aria-label="type to filter options"
                class=${classMap({ filter: true, visible: this.hasFilter, focusable: true })}
                .value=${this.filterText}
                @keydown=${this.handleFilterKeyDown}
                @keyup=${this.onFilterTextChange}
                @blur=${e => {
                    e.stopPropagation();
                }}
                @focus=${e => {
                    e.stopPropagation();
                }}
            />
        </div>`;
    }

    renderSelectAll() {
        if (!this.hasSelectAll()) {
            return nothing;
        }

        const isActive = this.activeDescendantIndex === -1;

        return html`<li
            id="select-all"
            role="option"
            aria-selected=${this.isEveryVisibleItemSelected}
            @click=${() => this.toggleSelectAll()}
            @keyup=${event => this.keyup(event)}
            class=${classMap({ active: isActive })}
        >
            Select All
        </li>`;
    }

    renderSearchDialog() {
        if (!this.hasSearch || !this.showSearchDialog || !this.items) return nothing;
        const item = this.items[0] ?? [];
        const keys = Object.keys(item).filter(k => k !== 'text' && k !== 'value');
        const { title, columns, filters, actions } = this.searchConfig;
        const actionButtons = actions?.map(
            action =>
                html`<omega-button
                    .type=${action.type}
                    @click=${() =>
                        this.dispatchEvent(new CustomEvent('action', { detail: action }))}
                >
                    >${action.label}</omega-button
                >`
        );

        this.searchItemsRecordset = new Recordset(
            keys.reduce((acc, curr) => {
                const columnFieldType = columns.find(col => col.field === curr)?.fieldType;
                acc[curr] = columnFieldType ?? new FieldType().thatIs.readOnly();
                return acc;
            }, {}),
            () => this.items
        );
        this.searchItemsRecordset.requestUpdate();

        const loader = this.renderLoader('large');
        const dialogContent = html`<omega-filter-bar
                .recordset=${this.searchItemsRecordset}
                .filters=${filters}
                @change=${({ detail }) => {
                    this.searchFilters = detail;
                }}
            >
                <div slot="actions">${actionButtons}</div>
            </omega-filter-bar>
            <omega-table
                .recordset=${this.searchItemsRecordset}
                .filters=${this.searchFilters}
                .columnDefinitions=${[
                    ...columns,
                    {
                        type: 'actions',
                        label: '',
                        actions: [{ label: 'Select', action: 'Select', visibleWhen: () => true }],
                    },
                ]}
                @action=${e => {
                    this.toggleItem(e.detail.record.values);
                    this.showSearchDialog = false;
                    this.searchFilters = [];
                }}
            >
            </omega-table>`;

        return html`<omega-dialog
            open
            .dialogTitle=${title}
            @close=${() => {
                this.showSearchDialog = false;
            }}
        >
            <div slot="content" style="padding: 10px;">
                ${this.loading === true ? loader : dialogContent}
            </div>
        </omega-dialog>`;
    }

    renderToggleButton() {
        const selectedItemLabel = () => {
            if (this.loading === true) return this.renderLabelLoader();
            if (this.isMultipleSelectWithAllSelected()) return 'All';
            if (this.isMultipleSelectWithNoSelection()) return this.placeholder;
            if (this.multiple && this.value?.length) return this.getFirstSelectedOptionText();
            return this.getSelectedOptionText();
        };

        const renderSelectedItemLabel = () => html`
            <div
                class=${classMap({
                    'selected-item-label': true,
                    placeholder: this.showPlaceholder,
                })}
            >
                <span>${selectedItemLabel()}</span>
            </div>
        `;

        const renderSearchButton = () => {
            if (!this.hasSearch) return nothing;
            return html`<button
                ?disabled=${this.disabled}
                class="search-button"
                @click=${e => {
                    this.dirty = true;
                    this.showSearchDialog = true;
                    this.collapse();
                    e.stopPropagation();
                }}
            >
                <i class="fa fa-search"></i>
            </button>`;
        };

        const renderMoreItemsTag = () => {
            if (!this.multiple || !this.value?.length) return nothing;
            const additionalItemsCount = this.value.length - 1;
            if (additionalItemsCount < 1) return nothing;
            if (this.isMultipleSelectWithAllSelected()) return nothing;
            return html`<span class="more-items-tag">+${additionalItemsCount}</span>`;
        };

        const activeDescendantId = `option-${this.activeDescendantIndex}`;

        // Ignore the error on has-popup: https://github.com/runem/lit-analyzer/issues/142
        return html`<button
            class=${classMap({
                'toggle-button': true,
                'is-expanded': this.isExpanded,
                invalid: this.invalid,
            })}
            aria-haspopup="true"
            aria-activedescendant=${activeDescendantId}
            aria-expanded=${this.isExpanded}
            aria-invalid=${this.ariaInvalid}
            ?disabled=${this.disabled}
            @keydown=${this.handleKeyDown}
            @click=${() => this.toggle()}
            @blur=${this.applyDirt}
        >
            ${renderSelectedItemLabel()}${renderMoreItemsTag()}${renderSearchButton()}
        </button>`;
    }

    render() {
        const paddingTop = this.virtualScrollStartIndex * this.itemHeight;
        const paddingBottom =
            (this.visibleItems.length -
                this.virtualScrollMaxRenderedItemCount -
                this.virtualScrollStartIndex) *
            this.itemHeight;

        const cappedVisibleItemsCount = Math.min(
            this.visibleItems.length,
            this.countOfItemsInViewport
        );
        const LISTBOX_BORDERS_HEIGHT = 1 * 2;
        const listboxHeight = cappedVisibleItemsCount * this.itemHeight + LISTBOX_BORDERS_HEIGHT;

        const listboxTop =
            this._listboxDirection === 'down'
                ? -28
                : -28 - listboxHeight - this.getBoundingClientRect().height;

        return html`
            ${this.renderToggleButton()}${this.renderFilter()}
            <div
                @scroll=${this.onScroll}
                style="height: ${listboxHeight}px; top: ${listboxTop}px;"
                class=${classMap({
                    'listbox-wrapper': true,
                    expanded: this.isExpanded,
                    measuring: this.isMeasuring,
                })}
            >
                <ol
                    id="listbox"
                    role="listbox"
                    style=" padding-top: ${paddingTop}px; padding-bottom: ${paddingBottom}px"
                >
                    ${this.renderSelectAll()} ${this.renderListItems()}
                </ol>
            </div>
            ${this.renderSearchDialog()}
        `;
    }

    static get styles() {
        return [
            fontAwesome,
            css`
                :host {
                    display: block;
                    width: 100%;
                    min-width: 100px;
                    height: 32px;
                }

                :host([disabled]) .toggle-button {
                    border-color: var(--omega-input-disabled-border);
                    background-color: var(--omega-input-disabled-background);
                    color: var(--omega-text-tertiary);
                }

                .toggle-button {
                    font-family: var(--omega-font);
                    width: 100%;
                    border-radius: var(--omega-input-border-radius);
                    background: white;
                    border: 1px solid var(--omega-secondary-lighten-300);
                    height: 32px;
                    padding: 5px 10px;
                    text-align: left;
                    position: relative;
                    color: var(--omega-text-default);
                    box-shadow: 0 2px 4px 0 rgba(45, 45, 45, 0.05);
                }

                .toggle-button:hover {
                    box-shadow: 0 2px 4px 0 rgba(45, 45, 45, 0.1);
                    border-color: var(--omega-input-hover-border);
                }

                .toggle-button.is-expanded {
                    border-color: var(--omega-input-active-border);
                }

                .toggle-button.invalid {
                    border-color: var(--omega-input-error-border);
                }

                :host([disabled]) .toggle-button[aria-invalid='true'] {
                    outline: 1px solid var(--omega-input-error-border);
                }

                .toggle-button[aria-invalid='true'] {
                    /* outline: 1px solid var(--omega-input-error-border); */
                    border: 2px solid var(--omega-input-error-border);
                }

                .toggle-button .more-items-tag {
                    background: var(--omega-page-background);
                    border: 1px solid var(--omega-secondary-lighten-300);
                    height: 28px;
                    width: 30px;
                    line-height: 28px;
                    vertical-align: middle;
                    position: absolute;
                    right: 33px;
                    top: 0px;
                    padding: 0;
                    text-align: center;
                    border-radius: 2px;
                    font-weight: 500;
                }

                .toggle-button .search-button {
                    position: absolute;
                    cursor: pointer;
                    right: 0;
                    top: 0;
                    background: var(--omega-page-background);
                    border-left: 1px solid var(--omega-secondary-lighten-300);
                    border-top: none;
                    border-right: none;
                    border-bottom: none;
                    line-height: 27px;
                    vertical-align: middle;
                    width: 40px;
                    z-index: 1;
                }

                .toggle-button::after {
                    position: absolute;
                    top: 50%;
                    right: 11px;
                    width: 0;
                    height: 0;
                    margin-top: -2px;
                    border-width: 6px 6px 0;
                    border-style: solid;
                    border-color: var(--omega-dark-grey) transparent;
                    content: '';
                }

                .toggle-button.is-expanded::after {
                    border-width: 0 6px 6px 6px;
                }

                .listbox-wrapper:not(.expanded) {
                    display: none;
                }

                .filter {
                    position: relative;
                    top: -30px;
                    right: -6px;
                    height: 26px;
                    width: calc(100% - 42px);
                    border: none;
                }

                .filter:focus {
                    outline: none;
                }

                .filter:not(.visible) {
                    visibility: hidden;
                    width: 1px;
                }

                .listbox-wrapper {
                    position: relative;
                    overflow-y: auto;
                    overflow-y: overlay;
                    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
                    z-index: 9999;
                }

                [role='listbox'] {
                    box-sizing: border-box;
                    position: absolute;
                    z-index: 3;
                    left: 0;
                    width: 100%;
                    padding: 0;
                    margin: 0;
                    border: 1px solid #d7e6ec;
                    background-color: white;
                    list-style: none;
                    opacity: 0.99;
                }

                li {
                    padding: 8px 32px;
                    position: relative;
                    cursor: default;
                    font-family: var(--omega-font);
                    font-size: 13px;
                    --checkbox-color: white;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                li[disabled='true'] {
                    opacity: 0.4;
                }

                [role='listbox']:not(:hover) li.active,
                li:not([disabled='true']):hover {
                    background: var(--omega-primary);
                    color: white;
                    text-shadow: 1px 0 0 currentColor;
                    --checkbox-color: var(--omega-primary);
                }

                :host([multiple]) li::after {
                    position: absolute;
                    top: 15px;
                    left: 10px;
                    width: 2px;
                    height: 2px;
                    background: var(--checkbox-color);
                    box-shadow:
                        2px 0 0 var(--checkbox-color),
                        4px 0 0 var(--checkbox-color),
                        4px -2px 0 var(--checkbox-color),
                        4px -4px 0 var(--checkbox-color),
                        4px -6px 0 var(--checkbox-color),
                        4px -8px 0 var(--checkbox-color);
                    content: '';
                    transform: rotate(45deg) translate(0, 0);
                }

                :host([multiple]) li::before {
                    content: '';
                    position: absolute;
                    top: 7px;
                    left: 6px;
                    width: 16px;
                    height: 16px;
                    border: 1px solid var(--omega-divider-color);
                    box-shadow: 0 2px 4px 0 #2d2d2d11;
                    border-radius: 4px;
                }

                :host([multiple]) li[aria-selected='true'] {
                    --checkbox-color: var(--omega-primary);
                }

                :host([multiple]) li[aria-selected='true']::before {
                    display: none;
                }

                :host([multiple]) [role='listbox']:not(:hover) li[aria-selected='true'].active,
                :host([multiple]) li[aria-selected='true']:hover {
                    --checkbox-color: white;
                }

                .selected-item-label {
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    display: flex;
                    justify-content: space-between;
                    width: calc(100% - 35px);
                }

                .label-loader {
                    display: flex;
                    align-items: center;
                }

                .label-loader span {
                    margin-right: 10px;
                }

                .select-item-loader-wrapper {
                    text-align: center;
                }

                .select-item-loader {
                    height: 14px;
                    width: 14px;
                    border-color: var(--omega-primary, var(--omega-primary));
                }

                .select-item-loader.large {
                    height: 40px;
                    width: 40px;
                }

                .placeholder {
                    color: var(--omega-info);
                }
            `,
        ];
    }
}

window.customElements.define('omega-select', OmegaSelect);
export default OmegaSelect;

/**
 * @param {Map<string, any>} changedProps
 * @param {OmegaSelect} select
 * @return {boolean}
 */
function testHashFn(changedProps, select) {
    return (
        changedProps.has('hashFunction') &&
        typeof select.hashFunction !== 'undefined' &&
        select.hashFunction !== select.defaultHashFunction &&
        select.hashFunction !== changedProps.get('hashFunction')
    );
}
