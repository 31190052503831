import {
    acceptedAdditionalInfoChars,
    additionalLocationInfoFields,
} from '@treasury/domain/wires';

const AdditionalLocationInfo = {
    bindings: {
        locationInfo: '=',
        isOpen: '=',
    },
    template: require('./additionalLocationInformation.html'),
    controller: AdditionalLocationInformationController,
};

AdditionalLocationInformationController.$inject = ['$scope'];

function AdditionalLocationInformationController($scope) {
    var ctrl = this;
    ctrl.locationInfo = [];
    ctrl.isOpen = false;
    ctrl.additionalInfoTypes = additionalLocationInfoFields;
    ctrl.availableLocationTypes = ctrl.additionalInfoTypes.slice();

    function allLocationTypesAreSelected() {
        return ctrl.availableLocationTypes.length <= 0;
    }

    function shouldShowDropdownList() {
        return ctrl.availableLocationTypes.length > 0;
    }

    function isAddLocationFieldDisabled() {
        return (
            (ctrl.isOpen &&
                ctrl.locationInfo.some(info => !info.value?.length || info.type.id === 'blank')) ||
            ctrl.allLocationTypesAreSelected()
        );
    }

    function removeAdditionalLocation(index) {
        if (ctrl.locationInfo.length <= 1) {
            ctrl.isOpen = false;
        }
        const item = ctrl.locationInfo.splice(index, 1);
        ctrl.availableLocationTypes[item.index] = item;
        ctrl.updateAvailableAdditionalLocationTypes();
    }

    function addAdditionalLocation() {
        if (ctrl.locationInfo.length >= 6) return;
        if (!ctrl.isOpen) {
            ctrl.isOpen = true;
        }
        ctrl.locationInfo.push({
            value: '',
            type: {
                name: 'Select type',
                id: 'blank',
                charLimit: 70,
                regex: acceptedAdditionalInfoChars,
            },
        });
    }

    function setAdditionalInfoType(index, type) {
        ctrl.locationInfo[index].type = type;
        updateAvailableAdditionalLocationTypes();
    }

    function setAdditionalInfoOpenClosed() {
        if (ctrl.locationInfo.length > 1) {
            ctrl.isOpen = true;
        } else if (ctrl.locationInfo.length === 1 && ctrl.locationInfo[0].type !== 'blank') {
            ctrl.isOpen = true;
        } else {
            ctrl.isOpen = false;
        }
    }

    function updateAvailableAdditionalLocationTypes() {
        ctrl.availableLocationTypes = ctrl.additionalInfoTypes.slice().filter(infoType => {
            const result = !ctrl.locationInfo.some(info => info.type.id === infoType.id);
            return result;
        });
        ctrl.setAdditionalInfoOpenClosed();
    }

    function addAdditionalLocation() {
        if (ctrl.locationInfo.length >= 8) return;
        if (!ctrl.isOpen) {
            ctrl.isOpen = true;
        }
        ctrl.locationInfo.push({
            value: '',
            type: {
                name: 'Select type',
                id: 'blank',
                charLimit: 70,
                regex: acceptedAdditionalInfoChars,
            },
        });
    }

    ctrl.setAdditionalInfoType = setAdditionalInfoType;
    ctrl.updateAvailableAdditionalLocationTypes = updateAvailableAdditionalLocationTypes;
    ctrl.removeAdditionalLocation = removeAdditionalLocation;
    ctrl.isAddLocationFieldDisabled = isAddLocationFieldDisabled;
    ctrl.allLocationTypesAreSelected = allLocationTypesAreSelected;
    ctrl.shouldShowDropdownList = shouldShowDropdownList;
    ctrl.setAdditionalInfoOpenClosed = setAdditionalInfoOpenClosed;
    ctrl.addAdditionalLocation = addAdditionalLocation;

    $scope.$watch(
        '$ctrl.locationInfo',
        function () {
            ctrl.setAdditionalInfoOpenClosed();
        },
        true
    );
}

export default AdditionalLocationInfo;
