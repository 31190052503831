import { FeatureFlagService } from '@treasury/domain/services/feature-flags';

BeneficiaryDetailDialogController.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    '$modalInstance',
    '$modal',
    'beneficiariesService',
    'statesService',
    'countriesService',
    'securityService',
    'beneficiary',
    'showCurrencyType',
    'wireIsoService'
];

export default function BeneficiaryDetailDialogController(
    $scope,
    $state,
    $stateParams,
    $modalInstance,
    $modal,
    beneficiariesService,
    statesService,
    countriesService,
    securityService,
    beneficiary,
    showCurrencyType,
    wireIsoService
) {
    $scope.beneficiary = beneficiary;
    $scope.showCurrencyType = showCurrencyType;

    $scope.canEdit = canEdit;
    $scope.close = close;
    $scope.edit = edit;
    $scope.getCountry = getCountry;
    $scope.getStateAndPostalCode = getStateAndPostalCode;
    $scope.showOrHide = showOrHide;
    $scope.approveOrReject = approveOrReject;
    $scope.isApprovedOrRejected = isApprovedOrRejected;
    $scope.useThreeLineAddress = beneficiariesService.canUseThreeLineAddress(beneficiary);
    $scope.showEditButton = showEditButton;
    $scope.wireIsoLabels = wireIsoService.legacyLabels;
    wireIsoService.getLabels().then(labels => {
        $scope.wireIsoLabels = labels;
    })
    $scope.isoFfIsOn = false;
    wireIsoService.getEntitlementWireISO20022().then(isoFfIsOn => {
        $scope.isoFfIsOn = isoFfIsOn;
    });

    function isApprovedOrRejected() {
        if ($scope.beneficiary) {
            if ($scope.beneficiary.status === 'Ready' || $scope.beneficiary.status === 'Rejected') {
                return true;
            }
            return false;
        }
    }

    function showOrHide(permissions, type) {
        let result = false;
        angular.forEach(permissions, permission => {
            if (permission.permission === type) {
                result = true;
            }
        });
        return result;
    }

    function approveOrReject(type, beneficiary) {
        const modalInstance = $modal.open({
            template: require('../views/approveOrRejectBeneficiary.html'),
            size: 'md',
            controller: 'ApproveOrRejectBeneficiaryController',
            backdrop: 'static',
            resolve: {
                type() {
                    return type;
                },
            },
        });
        modalInstance.result.then(comments => {
            const approveBeneficiaryModel = {
                beneficiaryId: beneficiary.id,
                comments,
            };
            let actionType;
            if (beneficiary.isForeignCurrency == true) {
                actionType = 'ApproveFxBeneficiary_ByName';
            } else {
                actionType = 'ApproveBeneficiary_ByName';
            }
            securityService
                .verifyUser(actionType, approveBeneficiaryModel, () =>
                    beneficiariesService.approveOrReject(type, approveBeneficiaryModel)
                )
                .then(response => {
                    $modalInstance.close(response);
                });
        });
    }

    function edit() {
        let actionType;
        if ($scope.beneficiary.isForeignCurrency == true) {
            actionType = 'EditFxBeneficiary_ByName';
        } else {
            actionType = 'EditBeneficiary_ByName';
        }
        securityService.verifyUser(actionType).then(() => {
            $state.go($scope.isoFfIsOn ? 'payables.wire.edit-creditor' : 'payables.wire.edit-beneficiary', {
                id: $scope.beneficiary.id,
                beneficiary: $scope.beneficiary,
            });
            close();
        });
    }

    function close() {
        $modalInstance.dismiss();
    }

    function getCountry(countryCode) {
        let result = null;
        window.angular.forEach($scope.countries, country => {
            if (country.countryCode === countryCode) {
                result = country.countryName;
            }
        });
        return result;
    }

    function getStateAndPostalCode(state, postalCode, postalCodeExtension) {
        let result = '';
        if (state) {
            result += state;
        }
        if (postalCode) {
            result += ` ${postalCode}`;
            if (postalCodeExtension) {
                result += `-${postalCodeExtension}`;
            }
        }
        return result;
    }

    function canEdit() {
        let result = false;
        if ($scope.beneficiary.status !== 'Pending Approval') {
            angular.forEach($scope.beneficiary.permissions, permission => {
                if (permission.permission === 'Edit') result = true;
            });
        }
        return result;
    }

    function showEditButton() {
        return $scope.hasEnabledCorpayWire ? !$scope.beneficiary.isForeignCurrency : true;
    }

    (async function () {
        if ($scope.beneficiary.intermediaryBanks) {
            $scope.domesticIntermediary = $scope.beneficiary.intermediaryBanks.filter(
                bank => !bank.isInternational
            )[0];
            $scope.internationalIntermediary = $scope.beneficiary.intermediaryBanks.filter(
                bank => bank.isInternational
            )[0];
        }

        statesService.getAll().then(response => {
            $scope.states = response;
        });

        countriesService.getAll().then(response => {
            $scope.countries = response;
        });

        $scope.hasEnabledCorpayWire = await FeatureFlagService.isEnabled(
            'Feature.Product.FxWire.EnableCorpayWire'
        );
    })();
}
