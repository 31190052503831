import { Feature, FeatureFlagService } from '@treasury/domain/services/feature-flags';
import { getCreditorIsoAddress } from '@treasury/domain/wires';
import { showCreditorIsoModalOnce } from '../../components/showCreditorIsoTransitionModal.js';

WireDetailsController.$inject = [
    '$scope',
    '$stateParams',
    '$timeout',
    'toaster',
    'wireService',
    '$state',
    '$modal',
    'modalService',
    'utilityService',
    'wireCompaniesService',
    'companyAccountsService',
    'entitlementsService',
    'holidaysService',
    'currenciesService',
    '$location',
    'securityService',
    'activityService',
    'resourceType',
    'auditCode',
    'downloadPageId',
    'dateConstants',
    'accountService',
    'beneficiariesService',
    'regexConstants',
    'countriesService',
    'wireIsoService',
];

export default function WireDetailsController(
    $scope,
    $stateParams,
    $timeout,
    toaster,
    wireService,
    $state,
    $modal,
    modalService,
    utilityService,
    wireCompaniesService,
    companyAccountsService,
    entitlementsService,
    holidaysService,
    currenciesService,
    $location,
    securityService,
    activityService,
    resourceType,
    auditCode,
    downloadPageId,
    dateConstants,
    accountService,
    beneficiariesService,
    regexConstants,
    countriesService,
    wireIsoService
) {
    $scope.id = $stateParams.id;
    $scope.approveOrReject = approveOrReject;
    $scope.isApprovedOrRejected = isApprovedOrRejected;
    $scope.isEditing = isEditing;
    $scope.findAccount = findAccount;
    $scope.checkRepeatOnDays = checkRepeatOnDays;
    $scope.setForm = setForm;
    $scope.confirm = confirm;
    $scope.isObject = isObject;
    $scope.close = close;
    $scope.edit = edit;
    $scope.review = review;
    $scope.print = print;
    $scope.goToPreviousScreen = goToPreviousScreen;
    $scope.goToWireFile = goToWireFile;
    $scope.getFrequencySummary = getFrequencySummary;
    $scope.lastBusinessDayChanged = lastBusinessDayChanged;
    $scope.deleteAdditionalInformation = deleteAdditionalInformation;
    $scope.addAdditionalInformation = addAdditionalInformation;
    $scope.getPlaceHolderText = getPlaceHolderText;
    $scope.hasAccessPermissions = hasAccessPermissions;
    $scope.isWireSftpEnabled = isWireSftpEnabled;
    $scope.checkStatus = checkStatus;
    $scope.cancel = cancel;
    $scope.selectWireCompany = selectWireCompany;
    $scope.processingCutoff = {};
    $scope.disableToday = false;
    $scope.requirePurpose = true;
    $scope.downloadPageId = downloadPageId.WirePaymentDetail;
    $scope.numbers = dateConstants.daysInMonth;
    $scope.weekDays = dateConstants.weekDays;
    $scope.useThreeLineAddress = useThreeLineAddress;
    $scope.beneficiaryReferenceRegex = regexConstants.BeneficiaryReferenceRegex;
    $scope.wireAdditionalInformationRegex = regexConstants.WireAlphaNumericPlusSpecialCharsRegex;
    $scope.getCountry = getCountry;
    $scope.showRateQuoteDetails = showRateQuoteDetails;
    $scope.getCityStateAndPostalCode = getCityStateAndPostalCode;
    $scope.allowEditCancelledPendingWires = entitlementsService.hasEntitlement(
        'Feature.Wire.EnablePendingApprovalWireEdit.TM25750'
    );
    $scope.beneficiaryAddress = [];
    $scope.wireIsoLabels = wireIsoService.legacyLabels;
    $scope.wireIsoFf = false;

    init();

    function getCityStateAndPostalCode(bank) {
        return beneficiariesService.getCityStateAndPostalCode(bank);
    }

    function useThreeLineAddress(beneficiary) {
        return beneficiariesService.canUseThreeLineAddress(beneficiary);
    }

    function showRateQuoteDetails() {
        return $scope.wire && $scope.wire.destinationCurrency !== 'USD' && $scope.wire.rateQuote;
    }

    function formatDate(dt) {
        return moment(new Date(dt)).format('MM/DD/YYYY');
    }

    function hasEntitlement(entitlement) {
        return entitlementsService.hasEntitlement(entitlement);
    }

    function isWireSftpEnabled() {
        return hasEntitlement('Feature.Wire.SFTP.DataTransmissions');
    }

    function loadCutoffTimes() {
        companyAccountsService.getCutoffTimesByProductType('WireTransfer').then(response => {
            $scope.processingCutoff = response;
            const timeout = moment(
                `${moment(response.currentFiTime).format('L')} ${
                    response.processingCutoff.cutoffTime
                }`
            ).diff(response.currentFiTime);
            $timeout(() => {
                $scope.disableToday = true;

                if ($scope.wire && $scope.wire.frequency) {
                    if (
                        moment($scope.wire.frequency.valueDate).format('L') ===
                        moment($scope.processingCutoff.currentFiTime).format('L')
                    ) {
                        utilityService
                            .getNextBusinessDay($scope.holidayDates, $scope.disableToday)
                            .then(response => {
                                $scope.wire.frequency.valueDate = formatDate(response);
                            });
                    }
                    if (
                        moment($scope.wire.frequency.startOn).format('L') ===
                        moment($scope.processingCutoff.currentFiTime).format('L')
                    ) {
                        utilityService
                            .getNextBusinessDay($scope.holidayDates, $scope.disableToday)
                            .then(response => {
                                $scope.wire.frequency.startOn = formatDate(response);
                            });
                    }
                    if (
                        moment($scope.wire.frequency.endOn).format('L') ===
                        moment($scope.processingCutoff.currentFiTime).format('L')
                    ) {
                        utilityService
                            .getNextBusinessDay($scope.holidayDates, $scope.disableToday)
                            .then(response => {
                                $scope.wire.frequency.endOn = formatDate(response);
                            });
                    }
                }

                if (timeout > 0) {
                    toaster.alert('Cutoff Time Passed', 'Cannot create wire for today.');
                }
            }, timeout);
        });
    }

    function getWire(id) {
        wireService.getWire(id).then(response => {
            $scope.wire = response;
            $scope.wireFileName = $scope.wireFileName ?? response.fileName;
            $scope.wireFileId = response.wireFileId;
            $scope.wire.frequency.type = wireService.frequencyTypeToFriendly(
                $scope.wire.frequency.type
            );
            if (!$scope.wire.isDli) {
                // can't edit DLI, we dont need to get company, account, and bene lists
                getWireCompanies();
            }
            getHolidays();
            $scope.beneficiaryAddress = getCreditorIsoAddress(
                $scope.wire.beneficiary,
                $scope.countries
            );
            $scope.beneficiaryBankAddress = getCreditorIsoAddress(
                $scope.wire.beneficiary.bank,
                $scope.countries
            );
        });
    }

    function init() {
        $scope._type = $location.search().type;
        if ($scope._type === 'recurringWireList') {
            showCreditorIsoModalOnce(wireIsoService, modalService);
        }

        wireIsoService.getLabels().then(labels => {
            $scope.wireIsoLabels = labels;
        });
        wireIsoService.getEntitlementWireISO20022().then(ff => {
            $scope.wireIsoFf = ff;
        });
        loadFrequencyOptions();
        loadCutoffTimes();
        loadCurrencies();
        loadAccountDisplayField();
        getWireConfiguration();
        getWire($scope.id);
        if ($state.params.wireFileId) {
            $scope.wireFileId = $state.params.wireFileId;
            $scope.wireFileName = $state.params.fileName;
        }
        $scope.currentDate = moment().format('MM/DD/YYYY');
        $scope.endOnDate = moment().format('MM/DD/YYYY');
    }

    function print() {
        window.print();
    }

    function goToWireFile() {
        $state.go('payables.wire.wire-file-activity', { wireFileName: $scope.wireFileName });
    }

    async function goToPreviousScreen() {
        if ($scope._type === 'wireList' && !$scope._isEditing) {
            const wireTotalsEnabled = await FeatureFlagService.isEnabled(
                Feature.WirePaymentActivityTotals
            );
            if (wireTotalsEnabled) {
                $state.go('payables.wire.wire-list', {
                    wireFileId: $scope.wireFileId,
                    fileName: $scope.wireFileName,
                });
            } else {
                if ($scope.wireFileId) {
                    window.history.pushState(
                        { fileName: $scope.wireFileName, fileId: $scope.wireFileId },
                        null,
                        'payables/wire/wire-list'
                    );
                }
                $state.go('payables.wire.wire-list');
            }
        }
        clearWireErrors();
        $scope._isReviewing = false;
        $scope.back = true;
        $scope.form.$valid = true;
        $scope.form.$dirty = true;
    }

    function setForm(form) {
        if (!$scope.form) {
            $scope.form = form;
        }
    }

    function review() {
        $scope._isReviewing = true;
    }

    function checkStatus(status) {
        switch (status) {
            case 'Pending Approval':
                return $scope.allowEditCancelledPendingWires;
            case 'Expired':
                if ($scope.wire.IsInternational) {
                    return false;
                }
                if (!$scope.wire.isInternational) {
                    return true;
                }
                break;
            case 'Scheduled':
                return true;
            case 'Rejected':
                return true;
            case 'Approval Rejected':
                return true;
            case 'Transmitted':
                return false;
            case 'Failed':
                return true;
            case 'Cancelled':
                return $scope.allowEditCancelledPendingWires;
            case 'Posted':
                return false;
        }
    }

    function findAccount(id) {
        getAccounts(id, () => {
            const modalInstance = $modal.open({
                template: require('../views/accountModalView.html'),
                size: 'md',
                controller: 'AccountsController',
                backdrop: 'static',
                resolve: {
                    wireId() {
                        return id;
                    },
                    companyAccounts() {
                        return $scope.companyAccounts;
                    },
                },
            });
            modalInstance.result.then(selectedAccount => {
                $scope.wire.debitAccount = angular.copy(selectedAccount);
                $scope.form.$dirty = true;
            });
        });
    }

    function getPlaceHolderText(index) {
        return `Sender to Receiver Info. Line ${index + 1}`;
    }

    function isObject(value) {
        let result = false;
        if (value === null || value === undefined) {
            result = false;
        } else if (typeof value === 'object') {
            result = true;
        }
        return result;
    }

    function addAdditionalInformation() {
        if ($scope.wire.additionalInformation.length === 4) {
            return;
        }
        $scope.wire.additionalInformation.push({ name: '' });
    }

    $scope.$watch('wire.frequency', newValue => {
        if (newValue !== null && newValue !== undefined) {
            if (
                newValue.repeatOnDay1 !== undefined &&
                newValue.repeatOnDay1 !== null &&
                newValue.repeatOnDay2 !== undefined &&
                newValue.repeatOnDay2 !== null &&
                newValue.repeatOnDay1 < newValue.repeatOnDay2
            ) {
                $scope.wire.frequency.isValid = true;
            }
        }
    });

    function lastBusinessDayChanged(type) {
        if (type === 'Monthly') {
            $scope.wire.frequency.repeatOnDay1 = null;
        }
        if (type === 'Twice a Month') {
            $scope.wire.frequency.repeatOnDay2 = null;
            $scope.form.repeatOnDay1.$setValidity('doesNotMatch', true);
            $scope.form.repeatOnDay2.$setValidity('doesNotMatch', true);
        }
    }

    function checkRepeatOnDays() {
        if (!$scope.wire.frequency.repeatOnLastBusinessDay) {
            const isValid1 =
                $scope.wire.frequency.repeatOnDay1 < $scope.wire.frequency.repeatOnDay2 ||
                $scope.wire.frequency.repeatOnDay2 === '';
            const isValid2 =
                $scope.wire.frequency.repeatOnDay2 > $scope.wire.frequency.repeatOnDay1 ||
                $scope.wire.frequency.repeatOnDay1 === '';

            if (
                $scope.wire.frequency.repeatOnDay1 !== undefined &&
                $scope.wire.frequency.repeatOnDay2 !== undefined &&
                $scope.wire.frequency.repeatOnDay2 !== ''
            ) {
                $scope.form.repeatOnDay1.$setValidity('doesNotMatch', isValid1);
                $scope.form.repeatOnDay2.$setValidity('doesNotMatch', isValid2);
            }
        }
    }

    function deleteAdditionalInformation(index) {
        $scope.wire.additionalInformation.splice(index, 1);
    }

    function selectWireCompany(obj) {
        getAccounts(obj.id);
        getBeneficiaries(obj.id);
    }

    function loadAccountDisplayField() {
        accountService.getSettings().then(settings => {
            $scope.accountDisplayField = settings.accountDisplayField;
        });
    }

    function getAccounts(id, callback) {
        // if this is firing on a display only view and the account is already populated
        // just return to the caller.
        if (!$scope._isEditing && $scope.wire.debitAccount) {
            return;
        }

        let edit;
        if ($scope.isInternationalWire === true) {
            edit = 'editIntl';
        } else {
            edit = 'editDomestic';
        }
        wireCompaniesService.getDebitAccounts(id, edit).then(response => {
            $scope.companyAccounts = response;
            if ($scope.wire.debitAccount) {
                const account = $scope.companyAccounts.reduce(
                    (acc, val) => (val.id === $scope.wire.debitAccount.id ? val : acc),
                    null
                );
                if (account) {
                    $scope.wire.debitAccount = account;
                } else {
                    $scope.wire.debitAccount = null;
                }
            }
            if (typeof callback === 'function') {
                callback();
            }
        });
    }

    function getBeneficiaries(id) {
        wireCompaniesService.getBeneficiaries(id).then(response => {
            $scope.beneficiaries = response;
        });
    }

    function getFrequencySummary(wire) {
        if (wire !== null && wire !== undefined) {
            return wireService.summarizeFrequency(wire.frequency);
        }
    }

    function confirm(wire) {
        if (wire !== null) {
            securityService
                .verifyUser('Edit WIRE Payment', wire, () => wireService.update(wire))
                .then(response => {
                    const hasError = parseIndividualErrors(response);
                    if (hasError === false) {
                        $scope._isReviewing = false;
                        response.isInternational = wire.isInternational;
                        $scope.wire = response;
                        $scope._isEditing = false;
                        $scope.isConfirmation = true;
                    }
                });
        }
    }

    function getWireCompanies() {
        wireCompaniesService.getAll(true).then(response => {
            $scope.wireCompanies = response;

            if ($scope.wire.wireCompany) {
                const company = $scope.wireCompanies.reduce(
                    (acc, val) => (val.id === $scope.wire.wireCompany.id ? val : acc),
                    null
                );
                if (company) {
                    $scope.wire.wireCompany = company;
                }
                getAccounts($scope.wire.wireCompany.id);
                getBeneficiaries($scope.wire.wireCompany.id);
            }
        });
    }

    $scope.onChange = function (e) {
        if (!isNaN(Date.parse($scope.wire.frequency.startOn))) {
            const dt = new Date($scope.wire.frequency.startOn);

            if ($scope.wire.frequency.type == 'Weekly') {
                $scope.endOnDate = moment(dt).add(1, 'week').format('MM/DD/YYYY');
            } else if ($scope.wire.frequency.type == 'Every Two Weeks') {
                $scope.endOnDate = moment(dt).add(2, 'weeks').format('MM/DD/YYYY');
            } else if (
                $scope.wire.frequency.type == 'Twice a Month' ||
                $scope.wire.frequency.type == 'Monthly'
            ) {
                $scope.endOnDate = moment(dt).add(1, 'month').format('MM/DD/YYYY');
            } else if ($scope.wire.frequency.type == 'Quarterly') {
                $scope.endOnDate = moment(dt).add(3, 'months').format('MM/DD/YYYY');
            } else if ($scope.wire.frequency.type == 'Every Six Months') {
                $scope.endOnDate = moment(dt).add(6, 'months').format('MM/DD/YYYY');
            } else if ($scope.wire.frequency.type == 'Yearly') {
                $scope.endOnDate = moment(dt).add(1, 'year').format('MM/DD/YYYY');
            }
        }
    };

    $scope.toOptions = {
        disableDates(date) {
            if (
                $scope.disableToday &&
                moment(date).format('L') ===
                    moment($scope.processingCutoff.currentFiTime).format('L')
            ) {
                return true;
            }
            if (date) {
                return holidaysService.compareDates(date, $scope.holidayDates);
            }
            return false;
        },
    };

    function getHolidays() {
        if (!$scope.wire) {
            throw new Error('Wire is not yet loaded');
        }
        holidaysService.getAll().then(response => {
            $scope.holidayDates = response.map(item => item.date);
            if ($scope.wire && $scope.wire.frequency) {
                utilityService
                    .getNextBusinessDay(
                        $scope.holidayDates,
                        $scope.disableToday,
                        $scope.wire.frequency.valueDate
                    )
                    .then(response => {
                        $scope.wire.frequency.valueDate = formatDate(response);
                    });
            }
        });
    }

    function edit() {
        $scope._isEditing = true;
    }

    function loadFrequencyOptions() {
        if ($scope._type === 'wireList') {
            $scope.wireFrequencies = [
                {
                    name: 'One Time',
                    key: 'One Time',
                },
                {
                    name: 'Weekly',
                    key: 'Weekly',
                },
                {
                    name: 'Every Two Weeks',
                    key: 'Every Two Weeks',
                },
                {
                    name: 'Twice a Month',
                    key: 'Twice a Month',
                },
                {
                    name: 'Monthly',
                    key: 'Monthly',
                },
                {
                    name: 'Quarterly',
                    key: 'Quarterly',
                },
                {
                    name: 'Every Six Months',
                    key: 'Every Six Months',
                },
                {
                    name: 'Yearly',
                    key: 'Yearly',
                },
            ];
        } else {
            $scope.wireFrequencies = [
                {
                    name: 'Weekly',
                    key: 'Weekly',
                },
                {
                    name: 'Every Two Weeks',
                    key: 'Every Two Weeks',
                },
                {
                    name: 'Twice a Month',
                    key: 'Twice a Month',
                },
                {
                    name: 'Monthly',
                    key: 'Monthly',
                },
                {
                    name: 'Quarterly',
                    key: 'Quarterly',
                },
                {
                    name: 'Yearly',
                    key: 'Yearly',
                },
                {
                    name: 'Every Six Months',
                    key: 'Every Six Months',
                },
            ];
        }
    }

    function isEditing() {
        return $scope._isEditing;
    }

    async function close() {
        if ($scope._type === 'wireList') {
            const wireTotalsEnabled = await FeatureFlagService.isEnabled(
                Feature.WirePaymentActivityTotals
            );
            if (wireTotalsEnabled && $scope.wireFileId) {
                $state.go('payables.wire.wire-list', {
                    wireFileId: $scope.wireFileId,
                    fileName: $scope.wireFileName,
                });
            } else {
                window.history.back();
            }
        } else if ($scope._type === 'recurringWireList') {
            $state.go('payables.wire.recurring-wire-list');
        }
    }

    function approveOrReject(type, wire) {
        let modalInstance = $modal.open({
            template: require('../views/approveOrRejectWire.html'),
            size: 'md',
            controller: 'ApproveOrRejectWireController',
            backdrop: 'static',
            resolve: {
                type() {
                    return type;
                },
                listType() {
                    return $scope._type;
                },
                isDli() {
                    return wire.isDli;
                },
            },
        });
        modalInstance.result.then(comments => {
            wire.comments = comments;
            let actionType;
            if (type === 'Approve') {
                actionType = 'Approve WIRE Payment';
            } else {
                actionType = 'Reject WIRE Payment';
            }

            const message = { value: comments, key: 'test' };
            securityService
                .verifyUser(actionType, message, () =>
                    wireService.approveOrReject(type, wire.id, message)
                )
                .then(response => {
                    const hasError = parseIndividualErrors(response);
                    if (!hasError) {
                        if (type === 'Reject' && wire.isDli) {
                            modalInstance = $modal.open({
                                template: require('../views/rejectDliWire.html'),
                                controller: 'RejectDliController',
                                backdrop: 'static',
                            });
                            modalInstance.result.then(() => {
                                $state.go('payables.wire.wire-list');
                            });
                        } else if ($scope._type === 'wireList') {
                            $state.go('payables.wire.wire-list');
                        } else {
                            $state.go('payables.wire.recurring-wire-list');
                        }
                    }
                });
        });
    }

    function hasAccessPermissions(wire, permissionType) {
        if (wire !== null && wire !== undefined) {
            let result = false;
            angular.forEach(wire.permissions, permission => {
                if (permission.permission === permissionType) {
                    result = true;
                }
            });
            return result;
        }
    }

    function isApprovedOrRejected() {
        if ($scope.wire !== null && $scope.wire !== undefined) {
            if ($scope.wire.status === 'Approved' || $scope.wire.status === 'Approval Rejected') {
                return true;
            }
            return false;
        }
    }

    function loadCurrencies() {
        currenciesService.getAll().then(response => {
            $scope.currencies = response;
        });
    }

    function isRejected() {
        if (
            $scope.wire !== null &&
            $scope.wire !== undefined &&
            $scope.wire.status === 'Rejected'
        ) {
            return true;
        }
    }

    function parseIndividualErrors(transaction) {
        let hasErrors = false;
        if (!!transaction.errorSummary && !!transaction.errorSummary.summaryMessage) {
            $scope.errorDetails = [];
            hasErrors = true;
            $scope.errorMessage = transaction.errorSummary.summaryMessage;
            angular.forEach(transaction.errorSummary.details, item => {
                if (!!item.message && !!item.messageList && item.messageList.length > 0) {
                    angular.forEach(item.messageList, message => {
                        $scope.errorDetails.push(message.value);
                    });
                } else if (item.message) {
                    $scope.errorDetails.push(item.message);
                }
            });
        } else {
            clearWireErrors();
        }
        return hasErrors;
    }

    function clearWireErrors() {
        // transaction.errorMessage = '';
        // transaction.balanceErrorMessage = '';
        // transaction.fromAccountErrorMessage = '';
        $scope.errorMessage = null;
        $scope.errorDetails = null;
    }

    function cancel() {
        if ($scope.form.$dirty) {
            const modalOptions = {
                closeButtonText: 'Close',
                actionButtonText: 'Cancel Edits',
                headerText: 'Confirm Cancellation',
                bodyText: 'Are you sure you want to cancel the changes you have made?',
                submit(result) {
                    if ($scope._type === 'wireList') {
                        activityService.userInteractionAudit(
                            resourceType.WireResources,
                            auditCode.CancelEditOneTimeWire
                        );
                    } else {
                        activityService.userInteractionAudit(
                            resourceType.WireResources,
                            auditCode.CancelEditRecurringWire
                        );
                    }
                    getWire($scope.id);
                    $scope._isEditing = false;
                    $scope._isReviewing = false;
                    $modalInstance.close();
                },
            };
            var $modalInstance = modalService.showModal({}, modalOptions);
        } else if ($scope._type === 'wireList') {
            $state.go('payables.wire.wire-list');
        } else {
            $state.go('payables.wire.recurring-wire-list');
        }
    }

    function getWireConfiguration() {
        wireCompaniesService.getWireConfiguration().then(response => {
            $scope.requirePurpose = response.requirePurpose;
            $scope.maximumFutureDays = response.maximumFutureDays;
            if ($scope.maximumFutureDays) {
                $scope.futureDatedLimit = formatDate(
                    moment().add({ days: $scope.maximumFutureDays })
                );
            }
        });
    }

    function getCountry(countryCode) {
        let result = null;
        window.angular.forEach($scope.countries, country => {
            if (country.countryCode === countryCode) {
                result = country.countryName;
            }
        });
        return result;
    }

    (function () {
        countriesService.getAll().then(response => {
            $scope.countries = response;
        });
    })();
}
